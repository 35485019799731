import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@mui/material"
import useDetection from "../../../hooks/useDetection"
import { DETECTION_TRANSFORMATIONS } from "../../../utilities/detectionTransformations"
import Radio from "@mui/material/Radio"
import { useSelector } from "react-redux"
import { selectReviewStatus } from "../../../store"

const DetectionActions = (props) => {
  const { detection: detectionId, size = "small" } = props

  const {
    transformation,
    selectedIndex,
    optionsVisible,
    apply,
    updateSelectedIndex,
    toggleOptionsVisible,
    dismiss,
    restore,
  } = useDetection(detectionId)

  const reviewed = useSelector((state) =>
    selectReviewStatus(state, detectionId)
  )

  return (
    <>
      <Box flexGrow={1} data-cy="detection-actions">
        {!reviewed &&
          transformation &&
          transformation.options &&
          transformation.options.length > 1 && (
            <Box>
              <Box display="flex" justifyContent="center" marginBottom={2}>
                <Button
                  data-cy="show-detection-options"
                  onClick={() => toggleOptionsVisible(!optionsVisible)}
                  size={size}
                  variant="text"
                >
                  {optionsVisible ? "Less Options" : "More Options"}
                </Button>
              </Box>
              <Collapse in={optionsVisible}>
                <Typography variant="subtitle2">Transform to:</Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="transform-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedIndex}
                    onChange={(e) => updateSelectedIndex(e.target.value)}
                  >
                    {transformation.options.map((option, index) => {
                      // Depending on the type of transformation, get the key for the options's text content
                      const key = DETECTION_TRANSFORMATIONS[transformation.type]
                      return (
                        <FormControlLabel
                          key={index}
                          sx={{ marginBottom: 1 }}
                          value={index}
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="body2">
                              {option[key]}
                            </Typography>
                          }
                        />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
              </Collapse>
            </Box>
          )}
        <Box display="flex" justifyContent="space-between">
          {!reviewed &&
            transformation &&
            transformation.options &&
            transformation.options.length > 0 && (
              <Button
                data-cy="transform-detection"
                key="transform"
                onClick={(e) => {
                  e.stopPropagation()
                  if (transformation.options) {
                    apply(
                      transformation?.options[selectedIndex][
                        DETECTION_TRANSFORMATIONS[transformation.type]
                      ]
                    )
                  }
                }}
                size={size}
                variant="outlined"
              >
                Transform
              </Button>
            )}
          {!reviewed && (
            <Button
              data-cy="review-detection"
              sx={{ marginLeft: "auto" }}
              key="dismiss"
              onClick={dismiss}
              size={size}
              color="inherit"
            >
              Reviewed
            </Button>
          )}
          {reviewed && (
            <Button
              sx={{ marginLeft: "auto" }}
              key={"restore"}
              onClick={restore}
              size={size}
              color="inherit"
            >
              Restore
            </Button>
          )}
        </Box>
      </Box>
    </>
  )
}

export default DetectionActions

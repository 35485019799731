import { useEffect } from "react"
import Froalaeditor from "froala-editor"
import { createInsertVideoDialog } from "../features/videoInsertion/InsertVideoDialog"
import createIFrame from "../features/videoInsertion/utils/createIFrame"
import { selectAfterText } from "../utilities/domUtils"

// Define icon for our custom video insert button
Froalaeditor.DefineIcon("showVideoDialog", {
  SVG_KEY: "insertVideo",
})

/**
 * Hook for registering custom Froala commands and buttons in our editor
 *
 * @param displayReactElement - Function needed for rendering react custom components in editor
 */
const useRegisterFroalaCommands = (displayReactElement: any) => {
  /**
   * Any custom Froala commands we need to register can be placed inside this useEffect
   */
  useEffect(() => {
    // Register custom command to show Video Insert dialog instead of the built-in Froala one.
    Froalaeditor.RegisterCommand("showVideoDialog", {
      title: "Insert Video",
      icon: "showVideoDialog",
      focus: true,
      undo: true,
      callback: function () {
        const selection = this.selection.get() as any
        const anchorElement = selection.anchorNode
        anchorElement.innerHTML = "<br/>"

        displayReactElement((onClose: () => void) =>
          createInsertVideoDialog((url) => {
            onClose()
            if (url) {
              const videoIFrame = createIFrame(url)

              // Reselect the text to keep cursor in place
              selectAfterText(anchorElement, this)
              anchorElement.insertAdjacentHTML("beforebegin", videoIFrame)

              const firstChild = anchorElement.firstChild

              // Remove the initial linebreak once the video is inserted
              if (firstChild && firstChild.tagName === "BR") {
                anchorElement.removeChild(firstChild)
              }
            }
          })
        )
      },
    })
  }, [displayReactElement])
}

export default useRegisterFroalaCommands

import { forwardRef, memo } from "react"
import styled from "@mui/material/styles/styled"
import FroalaEditor from "react-froala-wysiwyg"
import PropType from "prop-types"
import CircularProgress from "@mui/material/CircularProgress"

import defaultUseEditorConfig from "./useEditorConfig"

import "froala-editor/css/froala_style.min.css"
import "froala-editor/css/froala_editor.pkgd.min.css"
import "froala-editor/js/plugins.pkgd.min.js"
import "froala-editor/css/third_party/image_tui.min.css"
import "froala-editor/js/third_party/image_tui.min.js"
import { keyframes } from "@mui/material/styles"
import useEditorDispatch from "../../../store/editor/useEditorDispatch"

/**
 * Array of list option objects for ordered and unordered lists,
 * along with a unicode character to act as the pseudo element icon
 * prefixed to the option
 */
const listOptions = [
  {
    title: "Disc",
    command: "formatUL",
    content: '"\\2022"',
  },
  {
    title: "Circle",
    command: "formatUL",
    content: '"\\25E6"',
  },
  {
    title: "Square",
    command: "formatUL",
    content: '"\\25AA"',
  },
  {
    title: "Default",
    command: "formatUL",
    content: '"\\2022"',
  },
  {
    title: "Default",
    command: "formatOL",
    content: '"1."',
  },
  {
    title: "Lower Alpha",
    command: "formatOL",
    content: '"a."',
  },
  {
    title: "Lower Greek",
    command: "formatOL",
    content: '"α."',
  },
  {
    title: "Lower Roman",
    command: "formatOL",
    content: '"i."',
  },
  {
    title: "Upper Alpha",
    command: "formatOL",
    content: '"A."',
  },
  {
    title: "Upper Roman",
    command: "formatOL",
    content: '"I."',
  },
]

/**
 * Apply CSS styles to each list option, based on the preset listOptions
 * array.
 *
 * @param theme - MUI theme
 */
const getListOptionStyles = (theme) =>
  listOptions.reduce((styles, { title, command, content }) => {
    const selector = `.fr-command[data-cmd='${command}'][title='${title}']`

    return {
      ...styles,
      [selector]: {
        fontSize: "0.625rem",
        // Need to force override the padding due to baked-in froala styles
        padding: `${theme.spacing(2)} !important`,
        "&:before": {
          width: theme.spacing(4),
          fontSize: command === "formatUL" ? "3rem" : "1rem",
          marginRight: theme.spacing(1),
          content,
        },
      },
    }
  }, {})

const pulseKeyframe = (theme) => keyframes`
  0% {
    background: ${theme.palette.grey[50]};
  }
  100% {
    background: ${theme.palette.grey[300]};
  }
`

const pulseText = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    color: opacity: 1;
  }
`
/**
 * Wrapper supplies style overrides to the editor.
 *
 * Unfortunatly, the FroalaEditor component does not offer an interface for
 * injecting styles or classes.
 */
const Wrapper = styled("div", { name: "Editor", slot: "Wrapper" })(
  ({ theme, bordered }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    ...getListOptionStyles(theme),
    ".loading .skeleton": {
      display: "block",
      height: `${theme.spacing(2)}`,
      borderRadius: `${theme.shape.borderRadius}px`,
      marginBottom: `${theme.spacing(1)}`,
      animation: `${pulseKeyframe(theme)} 0.8s linear 0s infinite alternate`,
    },
    ".updating-text:not(.smart-template-control)": {
      animation: `${pulseText} 0.8s linear 0s infinite alternate`,
    },
    ".updating-text .smart-template-concepts": {
      animation: `${pulseText} 0.8s linear 0s infinite alternate`,
    },
    ".updating-text .smart-template-preview-content": {
      animation: `${pulseText} 0.8s linear 0s infinite alternate`,
    },
    ".fr-box": {
      height: "100%",
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      minWidth: "100px",
    },
    ".fr-wrapper": {
      flex: "1 1 auto",
    },

    ".fr-wrapper .fr-element": {
      // Need to ovverride low-level froala spacing styles
      paddingLeft: `${theme.spacing(8)} !important`,
      paddingRight: `${theme.spacing(8)} !important`,
    },
    ".fr-wrapper .fr-element > *": {
      lineHeight: 2,
    },
    // Enable text selection in read-only mode.
    ".fr-element.fr-disabled": {
      userSelect: "initial",
    },
    ".fr-wrapper img": {
      // Override the aspect ratio to allow images to be cropped correctly
      aspectRatio: "auto !important",
    },
    ".fr-box td ": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    ".fr-code": {
      height: "100% !important",
    },
    ".fr-selection span": {
      width: "100px !important",
    },
    ".highlighted": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.shape.borderRadius,
    },
    ".placeholder": {
      color: theme.palette.primary.dark,
    },
    ".hidden-text": {
      display: "none",
    },
    "section[data-template] p.placeholder, section[data-template] p.template-concepts-title, section[data-template] ul.keep-placeholder":
      {
        display: "none",
      },
    "table.centered": {
      /**
       * Table needs to override Froala-calculated margin if it has the ".centered" class
       */
      marginLeft: "auto !important",
      marginRight: "auto !important",
    },
    "table[data-component='borderless-table'] td": {
      border: "1px dashed lightgray",
      width: "50% !important", // Enforces equal column width for element
    },
    "table.no-borders td": {
      border: "1px solid transparent",
    },
    "table.no-borders": {
      "&:hover": {
        td: {
          border: "1px dashed lightgray",
        },
      },
    },
    ".smart-template-control": {
      backgroundColor: theme.palette.grey[200],
      padding: 15,
      borderRadius: 10,
      margin: 0,
      marginTop: 10,
      marginBottom: 15,
      marginRight: 10,
      userSelect: "none",
    },
    ".smart-template-action-text": {
      paddingRight: 25,
    },
    ".show-while-loading, .updating-text .hide-while-loading": {
      display: "none",
    },
    ".updating-text span.hide-while-loading": {
      display: "none",
    },
    ".updating-text .show-while-loading": {
      display: "block",
      animation: `${pulseText} 0.8s linear 0s infinite alternate`,
    },
    ".smart-template-title-text": {
      fontSize: "1.6rem",
    },
    ".smart-template-title": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    ".smart-template-icon": {
      width: "1.6rem",
      height: "1.6rem",
      backgroundSize: "1.6rem 1.6rem",
      marginRight: "1rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundImage: `url(
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath  d='m44.35 19.65-1.15-2.5L40.7 16l2.5-1.15 1.15-2.5 1.15 2.5L48 16l-2.5 1.15ZM38 10.9l-1.75-3.7-3.7-1.75 3.7-1.75L38 0l1.75 3.7 3.7 1.75-3.7 1.75ZM18 44q-1.7 0-2.875-1.175T13.95 39.95h8.1q0 1.7-1.175 2.875T18 44Zm-8.1-7.15v-3h16.2v3Zm.25-6.05q-3.3-2.15-5.225-5.375Q3 22.2 3 18.15q0-6.1 4.45-10.55Q11.9 3.15 18 3.15q6.1 0 10.55 4.45Q33 12.05 33 18.15q0 4.05-1.9 7.275-1.9 3.225-5.25 5.375Z'/%3E%3C/svg%3E"
      )`,
    },
    ".smart-template-content": {
      fontFamily: theme.smartTemplate.fontFamily,
      color: theme.palette.grey[900],
    },
    ".smart-template-action-button": {
      display: "block",
      borderStyle: "solid",
      borderWidth: 1,
      backgroundColor: "white",
      borderColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.main,
      borderRadius: 4,
      padding: "3px 9px 3px 26px",
      marginLeft: 10,
      width: 146,
      textAlign: "center",
      fontFamily: theme.typography.fontFamily,
      cursor: "pointer",
      fontWeight: 700,
      userSelect: "none",
      fontSize: "13px",
      float: "right",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath fill='rgb(156, 61, 225)' d='M7.5 5.6 10 7 8.6 4.5 10 2 7.5 3.4 5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.9959.9959 0 0 0-1.41 0L1.29 18.96c-.39.39-.39 1.02 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05c.39-.39.39-1.02 0-1.41l-2.33-2.35zm-1.03 5.49-2.12-2.12 2.44-2.44 2.12 2.12-2.44 2.44z'%3E%3C/path%3E%3C/svg%3E")`,
      backgroundPosition: "6px 10px",
      backgroundRepeat: "no-repeat",
    },
    ".smart-template-add-question-button": {
      display: "block",
      borderStyle: "solid",
      borderWidth: 1,
      backgroundColor: "white",
      borderColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.main,
      borderRadius: 4,
      padding: "14px 9px 3px 9px",
      marginLeft: 10,
      width: 146,
      textAlign: "center",
      fontFamily: theme.typography.fontFamily,
      cursor: "pointer",
      fontWeight: 700,
      userSelect: "none",
      fontSize: "13px",
      float: "right",
      height: 50,
    },
    ".smart-template-learn-more-button": {
      display: "block",
      width: 120,
      paddingTop: 10,
      marginLeft: 20,
      textAlign: "center",
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.tertiary.main,
      cursor: "pointer",
      fontWeight: 600,
      userSelect: "none",
    },
    ".smart-template-action-button.disabled": {
      cursor: "progress",
      color: theme.palette.grey[400],
      borderColor: theme.palette.grey[400],
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath fill='rgb(189,189,189)' d='M7.5 5.6 10 7 8.6 4.5 10 2 7.5 3.4 5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.9959.9959 0 0 0-1.41 0L1.29 18.96c-.39.39-.39 1.02 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05c.39-.39.39-1.02 0-1.41l-2.33-2.35zm-1.03 5.49-2.12-2.12 2.44-2.44 2.12 2.12-2.44 2.44z'%3E%3C/path%3E%3C/svg%3E")`,
    },
    ".smart-template-action": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: 0,
      margin: 0,
      marginTop: 10,
      borderSpacing: 0,
      width: "100%",
    },
    ".smart-template-action-icon": {
      width: "1.6rem",
      height: "1.6rem",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath fill='rgb(156, 61, 225)' d='M7.5 5.6 10 7 8.6 4.5 10 2 7.5 3.4 5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.9959.9959 0 0 0-1.41 0L1.29 18.96c-.39.39-.39 1.02 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05c.39-.39.39-1.02 0-1.41l-2.33-2.35zm-1.03 5.49-2.12-2.12 2.44-2.44 2.12 2.12-2.44 2.44z'%3E%3C/path%3E%3C/svg%3E")`,
    },
    ".smart-template-action-title": {
      fontWeight: 700,
    },
    ".smart-template-action-description": {
      flex: 1,
      padding: "10px",
      borderRadius: "10px",
      backgroundColor: "white",
    },
    ".smart-template-preview-button": {
      display: "block",
      float: "right",
      marginTop: 10,
      marginLeft: 20,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.tertiary.main,
      cursor: "pointer",
      fontWeight: 600,
      userSelect: "none",
    },
    ".smart-template-preview-title": {
      fontWeight: 700,
      paddingTop: 10,
      paddingBottom: 10,
    },
    ".smart-template-preview-content": {
      clear: "both",
      backgroundColor: "white",
      padding: "10px",
      borderRadius: "10px",
      userSelect: "text",
    },
    ...(!bordered && {
      ".fr-toolbar": {
        border: "0",
      },
      ".fr-box.fr-basic .fr-wrapper": {
        border: "0",
      },
      ".fr-second-toolbar": {
        display: "none",
      },
    }),
  })
)

/**
 * Editor renders the interface for editing course documents.
 */
const Editor = memo(
  forwardRef((props, ref) => {
    const {
      model,
      readOnly,
      onModelChange,
      onInitialize,
      onDestroy,
      useEditorConfig,
      extraConfig,
      updateDocument,
      ...otherProps
    } = props
    const config = useEditorConfig({
      ...extraConfig,
      onInitialize,
      onDestroy,
      readOnly,
      updateDocument,
    })

    const loading = model === null

    const { setRemarksScrollLocked } = useEditorDispatch()

    return (
      <Wrapper {...otherProps} onClick={() => setRemarksScrollLocked(false)}>
        {loading && <CircularProgress />}
        {!loading && config && (
          <FroalaEditor
            id="froala-editor"
            tag="textarea"
            ref={ref}
            config={config}
            model={model}
            onModelChange={onModelChange}
          />
        )}
      </Wrapper>
    )
  })
)

Editor.propTypes = {
  /** The current document model. */
  model: PropType.string,
  /** Called when the editor is ready for the user. */
  onInitialize: PropType.func,
  /** Called when the editor is being destroyed. */
  onDestroy: PropType.func,
  /** Called when the document model changes. */
  onModelChange: PropType.func,
  /** Interface for injecting custom useEditorConfig hook.
   * @ignore
   */
  useEditorConfig: PropType.func.isRequired,
}

Editor.defaultProps = {
  model: null,
  useEditorConfig: defaultUseEditorConfig,
}

export default Editor

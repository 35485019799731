import { Detection, RemarksState } from "../remark.types"
import remarksAdaptor from "../remarksAdaptor"
import selectDetectionId from "./selectDetectionId"

/**
 * Select a detection value by its ID.
 * @param state The current remarks state.
 * @param id The detection identifier.
 */
export const selectDetectionById = (state: RemarksState, id: string) =>
  remarksAdaptor.getSelectors().selectById(state, selectDetectionId(id)) as
    | Detection
    | undefined

export default selectDetectionById

import { createAsyncThunk } from "@reduxjs/toolkit"
import { without } from "lodash/fp"
import remarksAdaptor from "../remarksAdaptor"
import selectCurrentCourse from "../selectors/selectCurrentCourse"
import normalizeDetection from "../utils/normalizeDetection"

/**
 * @typedef {{
 *   extra: {
 *     api: import("../../types").API
 *   }
 *   state: import("../..").AppState
 * }} ThunkApiConfig
 * @typedef {{ id: string }} ThunkArg
 */

/**
 * Refresh detections
 *
 * @type {import("@reduxjs/toolkit").AsyncThunk<Remark[], ThunkArg, ThunkApiConfig>}
 */
export const refreshDetections = createAsyncThunk(
  "detections/refresh",
  async (arg, context) => {
    const {
      getState,
      extra: { api },
    } = context

    const state = getState()
    const courseId = selectCurrentCourse(state.remarks)

    return await api
      .downloadJSON("detections", courseId)
      .then(({ data }) => data)
      .then(normalizeDetection)
  }
)

export default refreshDetections

/**
 * Builds the reducer cases for deleting a comment.
 *
 * @param {import("@reduxjs/toolkit").ActionReducerMapBuilder<import("../remark.types").RemarksState>} builder
 */
export const buildCasesForRefreshDetections = (builder) => {
  builder.addCase(refreshDetections.fulfilled, (state, action) => {
    remarksAdaptor.upsertMany(state, action.payload)

    const oldIds = remarksAdaptor
      .getSelectors()
      .selectIds(state)
      .filter((id) => id.startsWith("detection#"))
    const newIds = action.payload.map(remarksAdaptor.selectId)

    const staleIds = without(newIds, oldIds)

    remarksAdaptor.removeMany(state, staleIds)
  })
}

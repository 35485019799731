import { ExpandLess, ExpandMore } from "@mui/icons-material"
import {
  Stack,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { PreviewHtmlContent } from "../../custom-elements/smart_templates/PreviewHtmlContent"
import CloseIcon from "@mui/icons-material/Close"
import ReplayIcon from "@mui/icons-material/Replay"
import CheckIcon from "@mui/icons-material/Check"
import _ from "lodash"
import { useStableCallback } from "../../hooks/useStableCallback"
import * as api from "../../api"
import {
  RewriteCustomInstructions,
  RewriteGradeLevel,
  RewriteLength,
  RewriteOptions,
  RewriteStructure,
  RewriteTone,
} from "./RewriteOptions"
import { PopoverContainer } from "./PopoverContainer"
import { getRangeHTML, replaceRangeContents } from "./transformUtils"
import { usePersistedState } from "../../hooks/usePersistedState"

/**
 * Renders a popover for rewriting text.
 *
 * @param param0 - The properties passed to the component.
 * @param param0.editor - The reference to the editor.
 * @param param0.editorWrapper - The wrapper for the editor.
 * @param param0.onClose - The function to call when the popover is closed.
 * @param param0.range - The range of the text to rewrite.
 * @returns
 */
export function RewritePopover({
  editor,
  editorWrapper,
  onClose,
  range,
}: {
  editor: any
  editorWrapper: HTMLElement
  onClose: () => void
  range: Range
}) {
  // Rewritten content
  const [rewrittenHtml, setRewrittenHtml] = useState<string>()

  // True if showing more options
  const [moreOptions, setMoreOptions] = useState(false)

  const [rewriteOptions, setRewriteOptions] = usePersistedState<RewriteOptions>(
    "rewrite_content_options_2024_07_19_selection",
    {
      tone: "professional",
      structure: "same",
      length: "same",
      gradeLevel: "8",
      customInstructions: "",
    },
    // If the structure is set to paragraphs, strange things can happen for short bits of text,
    // which could be counterintuitive to the user. So we default to "same" each time
    { structure: "same" }
  )

  // Rewrite options of currently displayed content. Used
  // to determine if currently generating content
  const [displayedRewriteOptions, setDisplayedRewriteOptions] =
    useState<RewriteOptions>()

  // True if first call to generate content and should be immediate
  const firstGenerateContent = useRef(true)

  // Editor container
  const editorContainer = editorWrapper.firstElementChild as HTMLElement

  // Latest rewrite options
  const latestRewriteOptions = useRef(rewriteOptions)
  latestRewriteOptions.current = rewriteOptions

  // Server message
  const [message, setMessage] = useState("")

  /** Generate rewritten content by calling server */
  const generateContent = useStableCallback(async function () {
    try {
      // Extract HTML from range
      const html = getRangeHTML(range)

      const data = await api.rewriteContent(html, {
        tone: rewriteOptions.tone,
        length: rewriteOptions.length,
        structure: rewriteOptions.structure,
        grade_level: rewriteOptions.gradeLevel,
        custom_instructions: rewriteOptions.customInstructions,
      })

      // Check if rewrite options have changed since request was made
      if (latestRewriteOptions.current !== rewriteOptions) {
        return
      }

      if (data.error_message) {
        setDisplayedRewriteOptions(rewriteOptions)
        setMessage(data.error_message)
        return
      }

      // Get new HTML
      const newHtml = data.content
      setRewrittenHtml(newHtml)
      setDisplayedRewriteOptions(rewriteOptions)
      setMessage("")
    } catch (err) {
      // If latest rewrite options have changed, don't show error
      if (latestRewriteOptions.current !== rewriteOptions) {
        return
      }
      setMessage(`Error rewriting content. Please try again.`)
      setDisplayedRewriteOptions(rewriteOptions)
    }
  })

  // Create debounced generate content function
  const debouncedGenerateContent = useMemo(() => {
    return _.debounce(generateContent, 750)
  }, [generateContent])

  // Generate rewritten content on load or if rewrite options change
  useEffect(() => {
    // Run immediately if not already generating content
    if (firstGenerateContent.current) {
      generateContent()
      firstGenerateContent.current = false
    } else {
      debouncedGenerateContent()
    }
  }, [rewriteOptions, debouncedGenerateContent, generateContent])

  const loading = displayedRewriteOptions !== rewriteOptions

  const footer = (
    <>
      <Stack
        direction="row"
        key="main_options"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <FormControl size="small">
            <InputLabel>Tone</InputLabel>
            <RewriteTone
              rewriteOptions={rewriteOptions}
              onRewriteOptionsChange={setRewriteOptions}
              menuContainer={document.body}
            />
          </FormControl>
          <Button
            onClick={() => setMoreOptions(!moreOptions)}
            endIcon={moreOptions ? <ExpandLess /> : <ExpandMore />}
          >
            More Options
          </Button>
        </Box>
        {displayedRewriteOptions !== rewriteOptions ? (
          <Button
            variant="text"
            onClick={() => {
              onClose()
            }}
            startIcon={<CloseIcon fontSize="small" />}
          >
            Cancel
          </Button>
        ) : (
          <Stack spacing={2} direction="row">
            <Button
              key="retry"
              variant="text"
              onClick={() => {
                // Reset displayed rewrite options so that loading spinner is shown
                setDisplayedRewriteOptions(undefined)
                generateContent()
              }}
              startIcon={<ReplayIcon fontSize="small" />}
            >
              Regenerate
            </Button>
            <Button
              key="confirm_apply"
              variant="text"
              onClick={() => {
                replaceRangeContents(range, rewrittenHtml!, editorContainer)
                editor.undo.saveStep()
                onClose()
              }}
              startIcon={<CheckIcon fontSize="small" />}
            >
              Apply
            </Button>
            <Button
              key="cancel"
              variant="text"
              onClick={() => {
                onClose()
              }}
              startIcon={<CloseIcon fontSize="small" />}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
      {moreOptions && (
        <MoreRewriteOptions
          rewriteOptions={rewriteOptions}
          onRewriteOptions={setRewriteOptions}
        />
      )}
    </>
  )

  return (
    <PopoverContainer
      message={loading ? undefined : message}
      editorWrapper={editorWrapper}
      footer={footer}
    >
      <Box
        sx={{
          p: 1,
          overflowY: "auto",
          maxHeight: "40vh",
          // eslint-disable-next-line jsdoc/require-jsdoc
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && rewrittenHtml && (
          <PreviewHtmlContent editor={editor} html={rewrittenHtml} />
        )}
      </Box>
    </PopoverContainer>
  )
}

/** Control to set more rewrite options
 * @param props See below.
 * @param props.rewriteOptions The rewrite options.
 * @param props.onRewriteOptions Callback to set the rewrite options.
 * @param props.menuContainer The menu container.
 */
function MoreRewriteOptions(props: {
  rewriteOptions: RewriteOptions
  onRewriteOptions: (options: RewriteOptions) => void
}) {
  const { rewriteOptions, onRewriteOptions } = props

  return (
    <Box
      key="more_options"
      sx={{
        p: 1,
        display: "flex",
        justifyContent: "stretch",
        gap: 2,
      }}
    >
      <FormControl size="small">
        <InputLabel>Reading Level</InputLabel>
        <RewriteGradeLevel
          rewriteOptions={rewriteOptions}
          onRewriteOptionsChange={onRewriteOptions}
          menuContainer={document.body}
        />
      </FormControl>
      <FormControl size="small">
        <InputLabel>Structure</InputLabel>
        <RewriteStructure
          rewriteOptions={rewriteOptions}
          onRewriteOptionsChange={onRewriteOptions}
          menuContainer={document.body}
          sameEnabled
        />
      </FormControl>
      <FormControl size="small">
        <InputLabel>Length</InputLabel>
        <RewriteLength
          rewriteOptions={rewriteOptions}
          onRewriteOptionsChange={onRewriteOptions}
          menuContainer={document.body}
        />
      </FormControl>
      <RewriteCustomInstructions
        rewriteOptions={rewriteOptions}
        onRewriteOptionsChange={onRewriteOptions}
        menuContainer={document.body}
      />
    </Box>
  )
}

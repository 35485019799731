import { Box, Collapse, Tooltip, Typography } from "@mui/material"
import { useDetectionValue } from "../../../hooks/useDetection"
import getDetectionTitle from "./getDetectionTitle"
import getTransformation from "./getTransformation"
import { InfoOutlined } from "@mui/icons-material"
import DetectionTooltip from "../LearnAdvisor/DetectionTooltip"

/**
 * Displays a detection items with controls for dismissing or applying it.
 */
const Detection = (props) => {
  const { detection: detectionId } = props

  const detection = useDetectionValue(detectionId)
  const transformation = getTransformation(detection)
  const { text, optionsVisible } = detection
  const tooltip = <DetectionTooltip detection={detectionId} />

  const accentColor = (theme) =>
    !detection.reviewed
      ? theme.palette.primary.main
      : theme.palette.text.secondary

  return (
    <Box padding={2} data-cy="detection">
      <Box display="flex" alignItems="center" marginBottom={1}>
        <Typography variant="subtitle1" color={accentColor} flex={1}>
          {getDetectionTitle(detection)}
        </Typography>
        <Tooltip title={tooltip} placement="bottom-start" arrow>
          <InfoOutlined
            data-cy="detection-info"
            sx={{ fontSize: 18 }}
            color="primary"
          />
        </Tooltip>
      </Box>
      {text && (
        <Typography
          data-cy="detection-text"
          variant="body2"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "3",
            overflow: "hidden",
          }}
        >
          "{text}"
        </Typography>
      )}
      {transformation && (
        <Collapse in={!optionsVisible}>
          <Typography variant="subtitle2" mt={1}>
            Transform to:
          </Typography>
          <Typography data-cy="transformation-text" variant="body2">
            {transformation}
          </Typography>
        </Collapse>
      )}
    </Box>
  )
}

export default Detection

import { includes } from "lodash/fp"
import detectionsConfig from "../../../config/detections"
import { DETECTION_TRANSFORMATIONS } from "../../../utilities/detectionTransformations"

/**
 * Get the transformation the will be applied for a given detection.
 *
 * @param {*} detection
 * @returns {?import("react").ReactNode}
 */
const getTransformation = (detection) => {
  const { rule, transformation, selectedIndex } = detection

  if (!transformation || includes(rule, detectionsConfig.transformBlacklist)) {
    return null
  }

  const { options, type } = transformation

  if (!options || !type || options.length === 0) {
    return null
  }

  const transformationContent =
    options[selectedIndex][DETECTION_TRANSFORMATIONS[type]]

  // Wrap in div with special class to disable hyperlinks
  return (
    <span
      className="detection-transformation-html"
      dangerouslySetInnerHTML={{
        __html: transformationContent,
      }}
    />
  )
}

export default getTransformation

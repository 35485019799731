import { Edit, Delete } from "@mui/icons-material"
import { Box, List, IconButton } from "@mui/material"
import { useState } from "react"
import { StyledListData } from "./styledListCustomElement"
import AddStyledListDialog from "./AddStyledListDialog"
import StyledListItem from "./StyledListItem"

interface StyledListProps {
  editor: any
  element: HTMLElement
  data: StyledListData
  onDataChange?: (data: StyledListData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
}

/**
 * Component for styled list in editor
 * @param props - Component props
 */
const StyledList = (props: StyledListProps) => {
  const [editorOpen, setEditorOpen] = useState(false)
  const { withStyles, element, editor, data, readOnly, onDataChange } = props

  const { listItems, listType } = data

  return (
    <>
      {withStyles(
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <List sx={{ flexShrink: 1 }}>
            {listItems.map((listItem, index) => (
              <StyledListItem
                listType={listType}
                index={index}
                listItem={listItem}
              />
            ))}
          </List>
          {!readOnly && (
            <Box
              key="buttons"
              flexGrow={1}
              display="flex"
              justifyContent="flex-end"
            >
              <IconButton onClick={() => setEditorOpen(true)}>
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  element.remove()
                  editor.undo.saveStep()
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        </Box>
      )}

      {editorOpen && (
        <AddStyledListDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

export default StyledList

import { Add, Delete } from "@mui/icons-material"
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { featureDecisions } from "../utilities/features"
import {
  MatchingQuestionData,
  MatchingQuestionPair,
  MultipleChoiceQuestionData,
  QuestionData,
} from "./questionCustomElementPlainStem"
import { useFlag } from "../utilities/feature-management"

/*

**** This is just the old version of Question Dialog before HTML stems. ****

*/

/** Props for QuestionDialog component */
export interface QuestionDialogProps {
  /** Initial data for the question */
  initialData: QuestionData
  /** Callback to cancel editing */
  onCancel: () => void
  /** Callback to save question */
  onSave: (data: QuestionData) => void
}

/** Edit question in a dialog
 * @param props Props for component
 */
export function QuestionDialog(props: QuestionDialogProps) {
  const { initialData, onCancel, onSave } = props

  const [data, setData] = useState<QuestionData>(initialData)

  const matchingQuestionsEnabled =
    useFlag("rollout-matching-questions") ?? false

  // Error message
  const [error, setError] = useState<string | null>(null)

  const scenariosEnabled = useFlag("rollout-scenario-based-questions") ?? false

  /** Save question, validating fiest */
  function handleSave() {
    const errorMessage = validateQuestionData(data)
    if (errorMessage) {
      setError(errorMessage)
      return
    }

    onSave(data)
  }

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Question</DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <QuestionTypeSelector
            data={data}
            onDataChange={setData}
            matchingQuestionsEnabled={matchingQuestionsEnabled}
          />
          <TextField
            label="Question"
            fullWidth
            multiline={scenariosEnabled}
            value={data.stem}
            onChange={(e) => setData({ ...data, stem: e.target.value })}
            helperText={
              data.type === "fill-in-the-blank"
                ? "Use one or more underscores (_) to indicate the blank to be filled"
                : ""
            }
          />
          {(data.type === "multiple-choice-single-answer" ||
            data.type === "multiple-choice-multiple-answer") && (
            <ChoicesList data={data} onDataChange={setData} />
          )}
          {data.type === "true-false" && (
            <FormControl>
              <FormLabel>Answer</FormLabel>
              <RadioGroup
                row
                value={data.answer}
                onChange={(e) =>
                  setData({
                    ...data,
                    answer: e.target.value as "True" | "False",
                  })
                }
              >
                <FormControlLabel
                  value="True"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="False"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          )}
          {data.type === "fill-in-the-blank" && (
            <TextField
              label="Answer"
              fullWidth
              value={data.answer}
              onChange={(e) => setData({ ...data, answer: e.target.value })}
            />
          )}
          {data.type === "matching" && (
            <MatchingQuestionPairs data={data} onDataChange={setData} />
          )}
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/** Allow user to select question type
 * @param props Props for component
 * @param props.data Data for question
 * @param props.onDataChange Callback to update question data
 * @param props.matchingQuestionsEnabled Whether matching questions are enabled
 */
function QuestionTypeSelector(props: {
  data: QuestionData
  onDataChange: (data: QuestionData) => void
  matchingQuestionsEnabled: boolean
}) {
  const { data, onDataChange } = props
  return (
    <Box display="flex" gap={5} alignItems="center" sx={{ mt: 1 }}>
      <FormControl sx={{ minWidth: 240 }}>
        <InputLabel id="question-type-label">Question Type</InputLabel>
        <Select
          labelId="question-type-label"
          label="Question Type"
          value={
            data.type === "multiple-choice-multiple-answer"
              ? "multiple-choice-single-answer"
              : data.type
          }
          onChange={(e) => {
            onDataChange(convertQuestionType(data, e.target.value as any))
          }}
        >
          <MenuItem value="multiple-choice-single-answer">
            Multiple Choice
          </MenuItem>
          {featureDecisions.trueFalseQuestions() && (
            <MenuItem value="true-false">True/False</MenuItem>
          )}
          {featureDecisions.fillInTheBlankQuestions() && (
            <MenuItem value="fill-in-the-blank">Fill in the Blank</MenuItem>
          )}
          {(props.matchingQuestionsEnabled || data.type === "matching") && (
            <MenuItem value="matching">Matching</MenuItem>
          )}
        </Select>
      </FormControl>
      {(data.type === "multiple-choice-single-answer" ||
        data.type === "multiple-choice-multiple-answer") && (
        <FormControlLabel
          control={
            <Switch
              checked={data.type === "multiple-choice-multiple-answer"}
              onChange={(e) => {
                onDataChange(
                  convertQuestionType(
                    data,
                    e.target.checked
                      ? "multiple-choice-multiple-answer"
                      : "multiple-choice-single-answer"
                  )
                )
              }}
            />
          }
          label="Multiple Answer"
        />
      )}
    </Box>
  )
}

/** Convert question type to another type
 * @param data Data for question
 * @param newType New question type
 * @returns Question data with new type
 */
export function convertQuestionType(
  data: QuestionData,
  newType:
    | "multiple-choice-single-answer"
    | "multiple-choice-multiple-answer"
    | "true-false"
    | "fill-in-the-blank"
    | "matching"
): QuestionData {
  // No change
  if (data.type === newType) {
    return data
  }

  // Convert to new type
  if (newType === "multiple-choice-single-answer") {
    return {
      type: "multiple-choice-single-answer",
      stem: data.stem,
      choices:
        data.type === "multiple-choice-multiple-answer"
          ? data.choices
          : ["", "", "", ""],
      correctChoice:
        data.type === "multiple-choice-multiple-answer"
          ? data.correctChoices[0] || 0
          : 0,
    }
  } else if (newType === "multiple-choice-multiple-answer") {
    return {
      type: "multiple-choice-multiple-answer",
      stem: data.stem,
      choices:
        data.type === "multiple-choice-single-answer"
          ? data.choices
          : ["", "", "", ""],
      correctChoices:
        data.type === "multiple-choice-single-answer"
          ? [data.correctChoice]
          : [0],
    }
  } else if (newType === "true-false") {
    return {
      type: "true-false",
      stem: data.stem,
      answer: "True",
    }
  } else if (newType === "fill-in-the-blank") {
    return {
      type: "fill-in-the-blank",
      stem: data.stem,
      answer: "",
    }
  } else if (newType === "matching") {
    return {
      type: "matching",
      stem: data.stem,
      pairs: [
        { left: "", right: "" },
        { left: "", right: "" },
      ],
    }
  }

  throw new Error("Invalid question type")
}

/** Edit multiple choice question choices
 * @param props Props for component
 * @param props.data Data for question
 * @param props.onDataChange Callback to update question data
 */
function ChoicesList(props: {
  data: MultipleChoiceQuestionData
  onDataChange: (data: MultipleChoiceQuestionData) => void
}) {
  const { data, onDataChange } = props
  return (
    <Stack gap={2}>
      {data.choices.map((choice, index) => (
        <TextField
          key={index}
          label={`Choice ${String.fromCharCode(97 + index)}`}
          fullWidth
          value={choice}
          onChange={(e) => {
            const newChoices = [...data.choices]
            newChoices[index] = e.target.value
            onDataChange({ ...data, choices: newChoices })
          }}
          InputProps={{
            startAdornment:
              data.type === "multiple-choice-single-answer" ? (
                <Radio
                  checked={index === data.correctChoice}
                  onChange={() => {
                    onDataChange({ ...data, correctChoice: index })
                  }}
                />
              ) : (
                <Checkbox
                  checked={data.correctChoices.includes(index)}
                  onChange={() => {
                    const newCorrectChoices = [...data.correctChoices]
                    if (newCorrectChoices.includes(index)) {
                      newCorrectChoices.splice(
                        newCorrectChoices.indexOf(index),
                        1
                      )
                    } else {
                      newCorrectChoices.push(index)
                    }
                    newCorrectChoices.sort()
                    onDataChange({ ...data, correctChoices: newCorrectChoices })
                  }}
                />
              ),
            endAdornment: (
              <>
                <IconButton
                  onClick={() => {
                    // Remove choice
                    const newChoices = [...data.choices]
                    newChoices.splice(index, 1)

                    // Adjust answers
                    if (data.type === "multiple-choice-single-answer") {
                      onDataChange({
                        ...data,
                        choices: newChoices,
                        correctChoice:
                          data.correctChoice === index
                            ? 0
                            : data.correctChoice > index
                            ? data.correctChoice - 1
                            : data.correctChoice,
                      })
                    } else {
                      onDataChange({
                        ...data,
                        choices: newChoices,
                        correctChoices: data.correctChoices
                          .filter((choice) => choice !== index)
                          .map((choice) =>
                            choice > index ? choice - 1 : choice
                          ),
                      })
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </>
            ),
          }}
        />
      ))}
      <div key="add-choice">
        <Button
          onClick={() => {
            onDataChange({
              ...data,
              choices: [...data.choices, ""],
            })
          }}
          startIcon={<Add />}
        >
          Add Choice
        </Button>
      </div>
    </Stack>
  )
}

/** Props for MatchingQuestionPairs component */
interface MatchingQuestionPairsProps {
  data: MatchingQuestionData
  onDataChange: (data: MatchingQuestionData) => void
}

/** Edit matching question pairs
 * @param props Props for component
 * @param props.data Data for question
 * @param props.onDataChange Callback to update question data
 * @returns Component
 */
export function MatchingQuestionPairs(props: MatchingQuestionPairsProps) {
  const { data, onDataChange } = props

  /** Handle change to pair
   * @param index Index of pair
   * @param pair New pair
   */
  function handlePairChange(index: number, pair: MatchingQuestionPair) {
    const newPairs = [...data.pairs]
    newPairs[index] = pair
    onDataChange({ ...data, pairs: newPairs })
  }

  /** Handle remove pair
   * @param index Index of pair
   */
  function handleRemovePair(index: number) {
    const newPairs = [...data.pairs]
    newPairs.splice(index, 1)
    onDataChange({ ...data, pairs: newPairs })
  }

  /** Handle add pair */
  function handleAddPair() {
    onDataChange({
      ...data,
      pairs: [...data.pairs, { left: "", right: "" }],
    })
  }

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Matching Pairs:
      </Typography>
      {data.pairs.map((pair, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          gap={2}
          padding={1}
          sx={{ width: "100%" }}
        >
          <TextField
            label="Left"
            value={pair.left}
            onChange={(e) =>
              handlePairChange(index, { ...pair, left: e.target.value })
            }
            fullWidth
          />
          <TextField
            label="Right"
            value={pair.right}
            onChange={(e) =>
              handlePairChange(index, { ...pair, right: e.target.value })
            }
            fullWidth
          />
          <IconButton
            onClick={() => handleRemovePair(index)}
            sx={{ marginLeft: "auto" }}
          >
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Button onClick={handleAddPair} startIcon={<Add />}>
        Add Pair
      </Button>
    </Box>
  )
}

/** Validate question data
 * @param data Data for question
 * @returns Error message or null if valid
 */
export function validateQuestionData(data: QuestionData): string | null {
  if (data.stem.trim() === "") {
    return "Question cannot be blank"
  }

  if (
    data.type === "multiple-choice-single-answer" ||
    data.type === "multiple-choice-multiple-answer"
  ) {
    if (data.choices.filter((c) => !c.trim()).length > 0) {
      return "Cannot have blank choices"
    }
    if (data.choices.length < 2) {
      return "Must have at least two choices"
    }
  }

  if (data.type === "multiple-choice-multiple-answer") {
    if (data.correctChoices.length < 2) {
      return "Must have at least two correct choices"
    }
  }

  if (data.type === "fill-in-the-blank") {
    if (data.stem.indexOf("_") === -1) {
      return "Fill in the blank questions must have a blank (___)"
    }
    if (data.answer.trim() === "") {
      return "Fill in the blank answer cannot be blank"
    }
  }

  // Validate matching questions
  if (data.type === "matching") {
    if (data.pairs.length < 2) {
      return "Must have at least two pairs"
    }
    if (
      data.pairs.some(
        (pair) => pair.left.trim() === "" || pair.right.trim() === ""
      )
    ) {
      return "Cannot have blank pairs"
    }
  }

  return null
}

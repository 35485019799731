import { Stack, Box, CircularProgress, Button } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { PreviewHtmlContent } from "../../custom-elements/smart_templates/PreviewHtmlContent"
import CloseIcon from "@mui/icons-material/Close"
import ReplayIcon from "@mui/icons-material/Replay"
import CheckIcon from "@mui/icons-material/Check"
import _ from "lodash"
import { useStableCallback } from "../../hooks/useStableCallback"
import * as api from "../../api"
import { PopoverContainer } from "./PopoverContainer"
import { getRangeHTML, replaceRangeWithBlock } from "./transformUtils"
import Typography from "@mui/material/Typography"

/**
 * Props for the TransformToComponentPopover component.
 */
export interface TransformToComponentProps {
  /** The reference to the editor.   */
  editor: any
  /** The wrapper for the editor. */
  editorWrapper: HTMLElement
  /** The function to call when the popover is closed. */
  onClose: () => void
  /** The range of the text to transform. */
  range: Range

  transformType:
    | "flip-card-grid"
    | "process"
    | "tabs"
    | "styled-list"
    | "callout-box"
    | "categories"
    | "labeled-image"
}

/** This is the options for the transform to component popover.
 *
 * Note: This is currently empty but we can add options in the future.
 * This keeps the logic for transforming text into a component simple and
 * extendable.
 */
interface TransformToComponentOptions {}

/**
 * Renders a popover for transforming text into a component.
 *
 * @param props - The properties passed to the component.
 */
export function TransformToComponentPopover(props: TransformToComponentProps) {
  const { editor, editorWrapper, onClose, range, transformType } = props

  // Transformed content
  const [transformedHtml, setTransformedHtml] = useState<string>()

  const [transformOptions, setTransformOptions] =
    useState<TransformToComponentOptions>({})

  // Transform options of currently displayed content. Used
  // to determine if currently generating content
  const [displayedTransformOptions, setDisplayedTransformOptions] =
    useState<TransformToComponentOptions>()

  // True if first call to generate content and should be immediate
  const firstGenerateContent = useRef(true)

  // Latest transform options
  const latestTransformOptions = useRef(transformOptions)
  latestTransformOptions.current = transformOptions

  // Server message
  const [message, setMessage] = useState("")

  /** Generate transformed content by calling server */
  const generateContent = useStableCallback(async function () {
    try {
      // Extract HTML from range
      const html = getRangeHTML(range)

      const data = await api.generateComponent(html, transformType)

      // Check if transform options have changed since request was made
      if (latestTransformOptions.current !== transformOptions) {
        return
      }

      if (data.error_message) {
        setDisplayedTransformOptions(transformOptions)
        setMessage(data.error_message)
        return
      }

      // Get new HTML
      const newHtml = data.content
      setTransformedHtml(newHtml)
      setDisplayedTransformOptions(transformOptions)
      setMessage("")
    } catch (err) {
      // If latest transform options have changed, don't show error
      if (latestTransformOptions.current !== transformOptions) {
        return
      }
      setMessage(`Error transforming content. Please try again.`)
      setDisplayedTransformOptions(transformOptions)
    }
  })

  // Create debounced generate content function
  const debouncedGenerateContent = useMemo(() => {
    return _.debounce(generateContent, 750)
  }, [generateContent])

  // Generate transformed content on load or if transform options change
  useEffect(() => {
    // Run immediately if not already generating content
    if (firstGenerateContent.current) {
      generateContent()
      firstGenerateContent.current = false
    } else {
      debouncedGenerateContent()
    }
  }, [transformOptions, debouncedGenerateContent, generateContent])

  const loading = displayedTransformOptions !== transformOptions

  const footer = (
    <>
      <Stack
        direction="row"
        key="main_options"
        alignItems="center"
        justifyContent="right"
      >
        {displayedTransformOptions !== transformOptions ? (
          <Button
            variant="text"
            onClick={() => {
              onClose()
            }}
            startIcon={<CloseIcon fontSize="small" />}
          >
            Cancel
          </Button>
        ) : (
          <Stack spacing={2} direction="row">
            <Button
              key="retry"
              variant="text"
              onClick={() => {
                // Reset displayed transform options so that loading spinner is shown
                setDisplayedTransformOptions(undefined)
                generateContent()
              }}
              startIcon={<ReplayIcon fontSize="small" />}
            >
              Regenerate
            </Button>
            <Button
              key="confirm_apply"
              variant="text"
              onClick={() => {
                replaceRangeWithBlock(
                  range,
                  transformedHtml!,
                  editorWrapper.firstElementChild as HTMLElement
                )
                editor.undo.saveStep()
                onClose()
              }}
              startIcon={<CheckIcon fontSize="small" />}
            >
              Apply
            </Button>
            <Button
              key="cancel"
              variant="text"
              onClick={() => {
                onClose()
              }}
              startIcon={<CloseIcon fontSize="small" />}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  )

  return (
    <PopoverContainer
      editorWrapper={editorWrapper}
      message={!loading ? message : undefined}
      footer={footer}
    >
      <Box
        sx={{
          p: 1,
          overflowY: "auto",
          maxHeight: "40vh",
          minHeight: "20vh",
          // eslint-disable-next-line jsdoc/require-jsdoc
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="20vh"
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && transformedHtml && (
          <>
            <PreviewHtmlContent editor={editor} html={transformedHtml} />
            {transformType === "labeled-image" && (
              <Typography
                variant="caption"
                sx={{ mt: 1, display: "block", textAlign: "center" }}
              >
                Note: Labels can be positioned by editing the component once it
                has been created.
              </Typography>
            )}
          </>
        )}
      </Box>
    </PopoverContainer>
  )
}

import { Add } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  Tab,
  List,
  Card,
  CardContent,
  Typography,
} from "@mui/material"
import produce from "immer"
import { useState } from "react"
import StyledListItemEdit from "./StyledListItemEdit"
import { StyledListData } from "./styledListCustomElement"
import StyledListItem from "./StyledListItem"

const listTypes = [
  {
    label: "Bulleted List",
    value: "bullet",
  },
  {
    label: "Checkbox List",
    value: "checkbox",
  },
  {
    label: "Numbered List",
    value: "number",
  },
]

/**
 * Return props for tab panels with aria-controls
 * @param index - Numberical index of tab
 */
const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
})

/**
 * Component for dialog for adding Styled lists
 * @param props - Component props
 * @param props.initialData - Initial data for lists
 * @param props.onCancel - Callback when hitting cancel
 * @param props.onSave - Callback for confirming list
 *
 */
const AddStyledListDialog = (props: {
  initialData: StyledListData
  onCancel: () => void
  onSave: (data: StyledListData) => void
}) => {
  const { initialData, onCancel, onSave } = props

  const [data, setData] = useState<StyledListData>(initialData)
  const [tabValue, setTabValue] = useState(0)

  const { listType, listItems } = data

  // Disable enforce focus to allow Froala image size editing to work
  return (
    <Dialog open={true} maxWidth="md" fullWidth disableEnforceFocus>
      <DialogTitle>Edit Styled List</DialogTitle>
      <DialogContent>
        <Box mb={4} borderBottom={1} borderColor="divider">
          <Tabs
            variant="fullWidth"
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Content" {...a11yProps(0)} />
            <Tab label="Settings" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box height={500}>
          {tabValue === 0 && (
            <Box>
              <Box component="form">
                {listItems.map((listItem, index) => (
                  <StyledListItemEdit
                    data={data}
                    key={index}
                    index={index}
                    onChange={(data) => setData(data)}
                  />
                ))}
              </Box>
              <Button
                sx={{ mt: 1 }}
                onClick={() => {
                  setData(
                    produce(data, (draft) => {
                      draft.listItems.push({
                        html: `<p>List Item ${draft.listItems.length + 1}</p>`,
                      })
                    })
                  )
                }}
                startIcon={<Add />}
              >
                Add List Item
              </Button>
            </Box>
          )}
          {tabValue === 1 && (
            <Box mt={2} mb={2}>
              <FormControl fullWidth>
                <InputLabel id="bullet-type-label">Bullet Type</InputLabel>
                <Select
                  labelId="bullet-type-label"
                  value={listType}
                  label="Bullet Type"
                  onChange={(e: any) => {
                    setData((prev) => ({
                      ...prev,
                      listType: e.target.value,
                    }))
                  }}
                >
                  {listTypes.map(
                    (listType: { label: string; value: string }) => (
                      <MenuItem key={listType.value} value={listType.value}>
                        {listType.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <Box mt={2}>
                <Typography gutterBottom variant="body1">
                  <b>Preview</b>
                </Typography>
                <Card variant="outlined" sx={{ flexGrow: 1 }}>
                  <CardContent>
                    <List sx={{ flexShrink: 1 }}>
                      {listItems.map((listItem, index) => (
                        <StyledListItem
                          listType={listType}
                          index={index}
                          listItem={listItem}
                        />
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          key="save"
          color="primary"
          variant="contained"
          onClick={() => onSave(data)}
          disabled={data.listItems.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddStyledListDialog

import { createEntityAdapter } from "@reduxjs/toolkit"
import { join } from "lodash/fp"
import compareRemarks from "./utils/compareRemarks"

/**
 * A collection of prebuilt reducers and selectors for managing Remarks.
 *
 * @type {import("./remark.types").RemarksAdaptor}
 */
const remarksAdaptor = createEntityAdapter({
  // Generate composite key for any given remark entity.
  selectId: (entity) => {
    const { id, remarkType } = entity

    const idParts = id.split("#")

    if (idParts.length === 1) {
      return join("#", [remarkType, id])
    }

    if (idParts[0] === remarkType) {
      return id
    }

    throw new Error(
      `Can not select remark ID: "${remarkType}" has malformed ID "${id}"`
    )
  },
  // Sort remark entities to their document path.
  sortComparer: compareRemarks,
})

export default remarksAdaptor

import { createAsyncThunk } from "@reduxjs/toolkit"
import selectCommentId from "../selectors/selectCommentId"

import selectCurrentCourse from "../selectors/selectCurrentCourse"
import selectGuest from "../selectors/selectGuest"
import selectServerId from "../selectors/selectServerId"

/** Action to delete a comment reply. */
export const deleteCommentReply = createAsyncThunk(
  "comments/reply/delete",
  async (arg, context) => {
    const {
      getState,
      extra: { api },
    } = context

    const state = getState()
    const { commentId, id } = arg

    await api.editCommentReply({
      commentId: selectServerId(commentId),
      courseId: selectCurrentCourse(state.remarks),
      id,
      as: selectGuest(state.remarks),
    })

    return { id, commentId }
  }
)

export default deleteCommentReply

/**
 * Build the reducer cases for deleting a comment reply.
 *
 * @param {import("../remark.types").RemarkActionReducerMapBuilder} builder
 */
export const buildCasesForDeleteCommentReply = (builder) => {
  builder.addCase(deleteCommentReply.fulfilled, (state, action) => {
    const { commentId, id } = action.payload

    /** @type {import("@reduxjs/toolkit/node_modules/immer/dist/internal").WritableDraft<import("../remark.types").Comment>} */
    const comment = state.entities[selectCommentId(commentId)]

    if (!comment) {
      return
    }

    const index = comment.replies.findIndex((reply) => reply.id === id)
    if (index !== -1) {
      comment.replies.splice(index, 1)
    }
  })
}

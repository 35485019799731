import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectDetectionById, useAppDispatch } from "../store"
import {
  removeDetection,
  updateDetection,
  toggleOptionsVisible,
} from "../store/remarks"
import {
  findDetectionElement,
  highlightDetection,
  htmlToElement,
} from "../utilities/domUtils"
import { dismiss, restore } from "../store/remarks/actions/reviewDetections"

export const useDetectionValue = (id) =>
  useSelector((state) => selectDetectionById(state, id))

export const useDetectionDispatch = (id) => {
  const dispatch = useAppDispatch()

  return useMemo(
    () => ({
      apply: (replacement) => {
        dispatch((dispatch, getState) => {
          applyTransform(selectDetectionById(getState(), id), replacement)
          dispatch(removeDetection({ id }))
        })
      },
      dismiss: () => dispatch(dismiss({ id })),
      restore: () => dispatch(restore({ id })),
      updateSelectedIndex: (selectedIndex) =>
        dispatch((dispatch) => {
          console.log(selectedIndex)
          dispatch(
            updateDetection({
              id: id,
              selectedIndex: selectedIndex,
            })
          )
        }),
      toggleOptionsVisible: () => dispatch(toggleOptionsVisible({ id })),
    }),
    [dispatch, id]
  )
}

const useDetection = (id) => ({
  ...useDetectionValue(id),
  ...useDetectionDispatch(id),
})

export default useDetection

/**
 * Highlight a the text of a detection and transform it into its replacement
 * @param detection - Data of detection to be transformed
 * @param replacement - Replacement text for transformation
 */
const applyTransform = (detection, replacement) => {
  /**
   * Scroll to the detection if needed prior to the transformation.
   */
  highlightDetection(detection)

  const editor =
    document.getElementsByClassName("fr-toolbar")?.[0]?.["data-instance"]

  if (!editor) {
    return
  }

  let el = document.createElement("html")
  el.innerHTML = editor.html.get()

  let node = findDetectionElement(detection, el.getElementsByTagName("body")[0])
  if (node !== null) {
    if (detection.transformation.type === "text") {
      let success = false
      if (["ol", "ul"].includes(node.tagName.toLowerCase())) {
        for (let child of node.children) {
          success = runTextTransformation(child, detection, replacement)
          if (success) {
            break
          }
        }
      } else {
        success = runTextTransformation(node, detection, replacement)
      }
      if (!success) {
        console.log("Couldn't perform transformation")
        return
      }
    } else if (detection.transformation.type === "tag") {
      node.replaceWith(htmlToElement(replacement))

      //LD-606 -Learning Objectives Comment out detection
      // } else if (detection.transformation.type === "learning_objective") {
      //   //locate the best placement for the new learning objective placeholder

      //   const header_tags = el.querySelectorAll("h1, h2, h3, h4, h5, h6")

      //   if (header_tags.length > 1) {
      //     //after the end of the first header and content
      //     header_tags[1].insertAdjacentHTML(
      //       "beforebegin",
      //       detection.transformation.transformation
      //     )
      //   } else {
      //     // at the end of the document
      //     el.insertAdjacentHTML(
      //       "afterend",
      //       detection.transformation.transformation
      //     )
      //   }
    } else {
      console.log("invalid transformation type")
      return
    }

    editor.html.set(el.innerHTML)
    editor.undo.saveStep()
  }
}

function runTextTransformation(node, detection, replacement) {
  let new_html = node.innerHTML.replace(detection.text, replacement)
  if (new_html === node.innerHTML) {
    // Couldn't perform normal transformation, need to look in styled tags for match
    let new_text = node.textContent
    new_text = new_text.replace(detection.text, replacement)
    if (new_text === node.textContent) {
      return false
    } else {
      node.innerHTML = new_text
    }
  } else {
    node.innerHTML = new_html
  }
  return true
}

import { useTheme } from "@emotion/react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useState } from "react"
import { UploadFile, UploadItem, UrlInfo } from "../../utilities/fileUpload"

interface SelectUrlDialogProps {
  onAccepted: (acceptedItems: any[]) => void
  open: boolean
  setOpen: (open: boolean) => void
}

/**
 * Dialog for choosing sources to create course with
 * @param props - Component props
 * @param props.onAccepted - callback
 * @param props.open - open the dialog
 * @param props.setOpen - close the dialog
 */
const SelectUrlDialog = ({
  onAccepted,
  open,
  setOpen,
}: SelectUrlDialogProps) => {
  const fullScreen = useFullscreenDialog()
  const [url, setUrl] = useState("")
  /**
   * Add a URL to the upload list
   */
  const handleAddUrl = () => {
    const fileInfo = new UrlInfo(url, "text/html", url)
    const uploadFile = new UploadFile(fileInfo, 0, null)
    const uploadItem = new UploadItem([uploadFile], url, "text/html", "web")

    console.log("handleAddUrl", uploadItem)

    onAccepted([uploadItem])
    setUrl("")
    setOpen(false)
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  const cancel = () => setOpen(false)

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      maxWidth="xs"
      scroll={fullScreen ? "paper" : "body"}
      keepMounted
    >
      <DialogTitle>Import from a Web page</DialogTitle>
      <DialogContent>
        <TextField
          label="Enter URL"
          variant="outlined"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          sx={{ mb: 2, mt: 4 }}
        />
        <Typography variant="body2" sx={{ mb: 4, mt: 2 }}>
          Some sites use Javascript to navigate content, this is unsupported. If
          you are missing content, save the webpage as HTML and upload to LEAi
          using that file. For detailed instructions, see{" "}
          <a
            href="/docs/Saving-a-Webpage-as-HTML-for-import-to-LEAi-2024-09-03.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Saving a Webpage as HTML for import to LEAi
          </a>
          .
        </Typography>
        <Divider />
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button onClick={handleAddUrl} disabled={!url.trim()}>
            Add URL
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default SelectUrlDialog

// eslint-disable-next-line jsdoc/require-jsdoc
export const useFullscreenDialog = () => {
  const theme: any = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

import uploadCourseDocument from "../../../store/jobs/uploadCourseDocument"
import {
  downloadRemoteFiles,
  getSelectedFiles,
} from "../../../utilities/fileUpload"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../../contexts/authorization"
import { useFlag } from "../../../utilities/feature-management"
import { getImportFileHeadings } from "../../../api/uploadCourseDocumentsToS3"

/**
 *
 * Provides the logic for uploading and display files on the Create Course Screen.
 */
const useCreateCourse = () => {
  const [loading, setLoading] = useState(false)
  const [fileNames, setFileNames] = useState([])
  const [uploadItems, setUploadItems] = useState([])
  const [error, setError] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    user: { id: tenantId },
  } = useAuth()
  // Message to show progress of the upload
  const [progressMessage, setProgressMessage] = useState("")
  const [uploadProgress, setUploadProgress] = useState([])
  const [uploadJobId, setUploadJobId] = useState(null)

  const rolloutServerlessAudioTranscription = useFlag(
    "rollout-serverless-audio-transcription"
  )

  const rolloutServerlessPptxUpload = useFlag("rollout-serverless-pptx-import")
  const rolloutServerlessDocxUpload = useFlag("rollout-serverless-docx-import")

  const rolloutServerlessImportService = useFlag(
    "rollout-serverless-import-service"
  )

  const rolloutDirectS3UploadFlag = useFlag("rollout-direct-s3-upload")

  const rolloutServerlessImportAPI = useFlag("rollout-serverless-import-api")

  const rolloutDirectS3Upload =
    rolloutDirectS3UploadFlag ||
    rolloutServerlessAudioTranscription ||
    rolloutServerlessPptxUpload ||
    rolloutServerlessDocxUpload ||
    rolloutServerlessImportAPI

  /**
   * Dispatch action to upload document(s) and redirect to the "Creation in progress" screen
   *
   * @param selectedItems - Items to be uploaded
   * @param options - Parameters for the course creation process
   */
  const dispatchUploadJob = (selectedItems, options) => {
    const { courseID, applyTemplates } = options
    return dispatch(
      uploadCourseDocument({
        selectedItems,
        ...options,
        setUploadProgress,
        rolloutDirectS3Upload,
        rolloutServerlessImportService,
        rolloutServerlessImportAPI,
        tenantId,
        uploadJobId,
      })
    )
      .then(({ payload }) => {
        if (courseID) {
          return payload.async_id
        } else {
          history.replace(`/creating-course`, {
            id: payload.async_id,
            applyTemplates,
          })
        }
        setError(null)
      })
      .catch((error) => {
        setError({
          messages: ["We are working to resolve the problem"],
          title: "Something doesn't seem right here",
          subtitle:
            "We are experiencing some difficulties connecting to the server.",
        })
        setLoading(false)
      })
  }

  /**
   * Get the headings for an import file
   *
   * @param fileInfo - fileinfo to gett headings for
   * @param options - import request options
   */
  const onGetHeadings = async (fileInfo, options) => {
    const { courseID, applyTemplates, learningPath } = options
    const { headings, jobId } = await getImportFileHeadings(
      uploadItems,
      applyTemplates,
      learningPath,
      tenantId,
      courseID,
      uploadJobId,
      fileInfo
    )

    setUploadJobId(jobId)

    return headings
  }

  /**
   * Gather all items added to the dropzone and commence the upload process.
   * @returns {Promise<void>}
   * @param options - Parameters for creating a course
   */
  const onStart = async (options) => {
    setLoading(true)

    const selectedItems = getSelectedFiles(fileNames, uploadItems)

    // Download files from Google Drive
    setProgressMessage("Downloading content...")
    const downloadErrors = await downloadRemoteFiles(selectedItems)
    if (downloadErrors.length > 0) {
      const messages = downloadErrors.map((error) => error.errorMessage)

      const hasWebErrors = downloadErrors.some(
        (uploadItem) => uploadItem.source === "web"
      )
      if (hasWebErrors) {
        messages.push(
          "Please check that the URL is correct and that the site allows third parties to download content."
        )
      }

      setError({
        messages,
        title: "There was a problem downloading content.",
        subtitle: "",
      })
      setLoading(false)
      return
    }

    const returningUser = window.localStorage.getItem(
      `returningUser/${tenantId}`
    )

    /**
     * If the user is creating a course for the first time, update local
     * storage to reflect this. It will then no longer display the help message
     * on the home page.
     */
    if (!returningUser) {
      window.localStorage.setItem(`returningUser/${tenantId}`, "true")
    }

    setProgressMessage("Uploading files to the server...")
    return dispatchUploadJob(selectedItems, options)
  }

  /**
   * Maintain a list of fileNames every time one is uploaded to the dropzone.
   * @param acceptedItems
   */
  const onAccepted = (acceptedItems) => {
    if (acceptedItems.length > 0) {
      // exclude items that have the same name
      const uniqueItems = []

      for (const item of acceptedItems) {
        if (!fileNames.includes(item.displayName)) {
          uniqueItems.push(item)
        }
      }

      setUploadItems(uploadItems.concat(uniqueItems))
      const newFileNames = uniqueItems.map((item) => item.displayName)
      setFileNames(fileNames.concat(newFileNames))
    }
  }
  return {
    onStart,
    loading,
    error,
    setError,
    onAccepted,
    fileNames,
    setFileNames,
    uploadItems,
    setUploadItems,
    progressMessage,
    uploadProgress,
    rolloutDirectS3Upload,
    onGetHeadings,
  }
}

export default useCreateCourse

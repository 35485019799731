import { Authenticator, View } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import { Box, Link, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import terms from "../../assets/docs/Terms_and_Conditions_2023.pdf"

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: "Enter your email address",
      isRequired: true,
      label: "Email",
    },
    password: {
      labelHidden: false,
      placeholder: "Enter your password",
      isRequired: true,
      label: "Password",
    },
  },
}

const isProduction = process.env.NODE_ENV === "production"

/**
 * Renders the legacy authentication flows.
 */
function LegacySignInScreen() {
  return (
    <View className="auth-wrapper">
      <Box display="flex" padding={6} justifyContent="center">
        <Box>
          <img
            src="/lex-icon-wordmark-557x202.png"
            alt="Learn Experts Logo"
            height="80px"
          />
        </Box>
      </Box>
      <Authenticator formFields={formFields} hideSignUp={true}></Authenticator>
      {isProduction && (
        <Box display="flex" padding={4} justifyContent="center">
          <Typography variant="body2">
            By logging into LEAi, you agree to our{" "}
            <Link
              component={RouterLink}
              target="_blank"
              to={{
                pathname: terms,
              }}
              underline="none"
            >
              Terms
            </Link>{" "}
            and acknowledge our{" "}
            <Link
              component={RouterLink}
              target="_blank"
              to={{
                pathname: "https://learnexperts.ai/privacy-policy",
              }}
              underline="none"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
      )}
    </View>
  )
}

export default LegacySignInScreen

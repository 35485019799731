import {
  Stack,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import CreateIcon from "@mui/icons-material/Create"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import FlipIcon from "@mui/icons-material/Flip"
import TabIcon from "@mui/icons-material/Tab"
import ListIcon from "@mui/icons-material/List"
import CallSplitIcon from "@mui/icons-material/CallSplit"
import InfoIcon from "@mui/icons-material/Info"
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio"

import { PopoverContainer } from "./PopoverContainer"
import { useFlag } from "../../utilities/feature-management"

/** Types of transformations that can be applied to a selection. */
export type TransformType =
  | "rewrite"
  | "flip-card-grid"
  | "process"
  | "tabs"
  | "styled-list"
  | "callout-box"
  | "categories"
  | "labeled-image"

/**
 * A popover that allows the user to select a type of transformation to apply to a selection.
 *
 * @param props - The props for the popover.
 * @param props.onSelect - A function that is called when a transformation is selected.
 * @param props.onClose - A function that is called when the popover is closed.
 * @param props.editorWrapper - The wrapper element for the editor.
 */
export function SelectTransformTypePopover(props: {
  onSelect: (transformType: TransformType) => void
  onClose: () => void
  editorWrapper: HTMLElement
}) {
  const { onSelect, onClose, editorWrapper } = props

  const enableFlipCards = useFlag(
    "rollout-transform-text-into-interactions-flip-cards"
  )
  const enableTabs = useFlag("rollout-transform-text-into-interactions-tabs")
  const enableProcess = useFlag(
    "rollout-transform-text-into-interactions-process"
  )
  const enableStyledList = useFlag(
    "rollout-transform-text-into-interactions-styled-list"
  )
  const enableCalloutBox = useFlag(
    "rollout-transform-text-into-interactions-callout-box"
  )
  const enableCategories = useFlag(
    "rollout-transform-text-into-interactions-categories"
  )
  const enableLabeledImage = useFlag(
    "rollout-transform-text-into-interactions-labeled-images"
  )

  const listItems: React.ReactNode[] = []
  listItems.push(
    <ListItemButton key="rewrite" onClick={() => onSelect("rewrite")}>
      <ListItemIcon>
        <CreateIcon />
      </ListItemIcon>
      <ListItemText
        primary="Rewrite content"
        secondary="The content is rewritten using learning best-practices."
      />
    </ListItemButton>
  )

  if (enableFlipCards) {
    listItems.push(
      <ListItemButton
        key="flip-card-grid"
        onClick={() => onSelect("flip-card-grid")}
      >
        <ListItemIcon>
          <FlipIcon />
        </ListItemIcon>
        <ListItemText
          primary="Create flip cards"
          secondary="The content is structured as a grid of flip cards."
        />
      </ListItemButton>
    )
  }

  if (enableTabs) {
    listItems.push(
      <ListItemButton key="tabs" onClick={() => onSelect("tabs")}>
        <ListItemIcon>
          <TabIcon />
        </ListItemIcon>
        <ListItemText
          primary="Create tabs"
          secondary="The content is organized into tabs."
        />
      </ListItemButton>
    )
  }

  if (enableProcess) {
    listItems.push(
      <ListItemButton key="process" onClick={() => onSelect("process")}>
        <ListItemIcon>
          <ArrowForwardIcon />
        </ListItemIcon>
        <ListItemText
          primary="Create process"
          secondary="The content is structured as a process."
        />
      </ListItemButton>
    )
  }

  if (enableStyledList) {
    listItems.push(
      <ListItemButton key="styled-list" onClick={() => onSelect("styled-list")}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText
          primary="Create styled list"
          secondary="The content is organized into a styled list."
        />
      </ListItemButton>
    )
  }

  if (enableCalloutBox) {
    listItems.push(
      <ListItemButton key="callout-box" onClick={() => onSelect("callout-box")}>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText
          primary="Create callout box"
          secondary="The content is highlighted in a callout box."
        />
      </ListItemButton>
    )
  }

  if (enableCategories) {
    listItems.push(
      <ListItemButton key="categories" onClick={() => onSelect("categories")}>
        <ListItemIcon>
          <CallSplitIcon style={{ transform: "rotate(180deg)" }} />
        </ListItemIcon>
        <ListItemText
          primary="Create categories"
          secondary="The content is organized into categories."
        />
      </ListItemButton>
    )
  }

  if (enableLabeledImage) {
    listItems.push(
      <ListItemButton
        key="labeled-image"
        onClick={() => onSelect("labeled-image")}
      >
        <ListItemIcon>
          <ImageAspectRatioIcon fontSize="inherit" />
        </ListItemIcon>
        <ListItemText
          primary="Create labeled image"
          secondary="The content is organized into an image with labels."
        />
      </ListItemButton>
    )
  }

  const footer = (
    <>
      <Stack
        direction="row"
        key="main_options"
        alignItems="center"
        justifyContent="right"
      >
        <Button
          variant="text"
          onClick={() => {
            onClose()
          }}
          startIcon={<CloseIcon fontSize="small" />}
        >
          Cancel
        </Button>
      </Stack>
    </>
  )

  return (
    <PopoverContainer editorWrapper={editorWrapper} footer={footer}>
      <Stack direction="row" spacing={2}>
        <List>{listItems.filter((_, index) => index % 2 === 0)}</List>
        <List>{listItems.filter((_, index) => index % 2 !== 0)}</List>
      </Stack>
    </PopoverContainer>
  )
}

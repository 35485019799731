import { ButtonBase, Stack, Theme, Typography } from "@mui/material"
import { ReactNode } from "react"

type RailButtonProps = {
  onClick: (event: any) => void
  label: string
  icon: ReactNode
  path: string
  currentPath: string
}

/**
 * Component for button displayed on navigation rail
 * @param props - Component props
 */
const RailButton = (props: RailButtonProps) => {
  const { onClick, label, icon, path, currentPath } = props

  const active = currentPath === path

  return (
    <Stack
      alignItems="center"
      sx={{
        height: "56px",
        width: "80px",
      }}
      justifyContent="center"
    >
      <ButtonBase
        onClick={() => onClick(path)}
        sx={(theme: Theme) => ({
          width: "56px",
          height: "32px",
          backgroundColor: active
            ? theme.scheme.secondaryContainer
            : "transparent",
          borderRadius: "50px",
          svg: {
            color: theme.scheme.onSurfaceVariant,
          },
        })}
      >
        {icon}
      </ButtonBase>
      <Typography
        sx={(theme: Theme) => ({
          fontSize: "12px",
          fontWeight: active ? "bold" : 500,
          color: theme.scheme.onSurfaceVariant,
        })}
        variant="body1"
      >
        {label}
      </Typography>
    </Stack>
  )
}

export default RailButton

import { Alert, Stack } from "@mui/material"
import BlogPost, { BlogPostData } from "./components/BlogPost"
import WelcomeCard from "./components/WelcomeCard"
import { CardSkeleton } from "../../welcome/WelcomeDisplay"
import BlogPostContainer from "./components/BlogPostContainer"
import { Item } from "../../homeNavigation/types"

type WelcomeDisplayProps = {
  error: any
  posts: BlogPostData[]
  loading: boolean
  welcomeVisible: boolean
  setWelcomeVisible: (visible: boolean) => void
  displayHelpMessage: boolean
  lastEdited: Item | null
  loadingLastEdited: boolean
}

/**
 * Interface for viewing welcome message and links to blog posts on home page
 * @param props - Component props
 */
const WelcomeDisplay = (props: WelcomeDisplayProps) => {
  const {
    error,
    posts,
    loading,
    welcomeVisible,
    setWelcomeVisible,
    displayHelpMessage,
    lastEdited,
    loadingLastEdited,
  } = props

  const dynamicDisplayStyles = {
    display: welcomeVisible
      ? { xs: "none", xl: "flex" }
      : { xs: "none", sm: "flex" },
  }

  return (
    <Stack direction="row" height="180px" gap="16px" marginBottom="16px">
      {welcomeVisible && (
        <WelcomeCard
          displayHelpMessage={displayHelpMessage}
          onDismiss={() => setWelcomeVisible(false)}
          lastEdited={lastEdited}
          loadingLastEdited={loadingLastEdited}
        />
      )}
      {posts.length > 0 && (
        <>
          <BlogPost
            post={posts[0]}
            sx={{
              display: welcomeVisible
                ? { xs: "none", md: "flex" }
                : { xs: "flex" },
            }}
          />
          {posts[1] && (
            <BlogPost
              sx={{
                display: welcomeVisible
                  ? { xs: "none", xl: "flex" }
                  : { xs: "none", md: "flex" },
              }}
              post={posts[1]}
            />
          )}
        </>
      )}
      {loading && !error && (
        <>
          <BlogPostContainer>
            <CardSkeleton />
          </BlogPostContainer>
          <BlogPostContainer sx={dynamicDisplayStyles}>
            <CardSkeleton />
          </BlogPostContainer>
        </>
      )}
      {error && (
        <BlogPostContainer sx={{ alignItems: "center" }}>
          <Alert variant="outlined" severity="error">
            We are experiencing issues retrieving the latest posts. Please
            refresh the page or contact LearnExperts if the problem persists.
          </Alert>
        </BlogPostContainer>
      )}
    </Stack>
  )
}

export default WelcomeDisplay

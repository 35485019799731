import { useEffect, useState } from "react"
import { whiteListedVideoPlatforms } from "../utils/urlUtils"

const validProviders = Object.keys(whiteListedVideoPlatforms)
// Regex to match URL string
const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

/**
 * Check if provided URL links to a valid video resource
 * @param url - URL pointing to video
 */
const checkURLValidity = (url: string) => {
  if (
    !urlPattern.test(url) ||
    !validProviders.some((provider) => url.includes(provider))
  ) {
    // If URL is malformed or not on our whitelist of providers, mark as invalid
    return "Invalid video URL. Please provide a valid URL from YouTube, Vimeo, Vidyard, Yahoo, or Daily Motion."
  }

  // Otherwise validate the URL as valid
  return null
}

/**
 * Hook for handling logic for inserting video links
 */
const useInsertVideoURL = () => {
  const [url, setUrl] = useState("")
  const [invalidMessage, setInvalidMessage] = useState<string | null>()

  useEffect(() => {
    if (url === null || url === "") {
      setInvalidMessage(null)
      return
    }

    const message = checkURLValidity(url)
    setInvalidMessage(message)
  }, [url])

  return {
    invalidMessage,
    setUrl,
    url,
  }
}

export default useInsertVideoURL

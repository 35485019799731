/**
 * The locales that are available for translation.
 */
export const availableTranslationLocales = ["fr", "de", "es", "ja"]

/**
 * The names of the locales.
 */
export const localeNames: Record<string, string> = {
  en: "English",
  fr: "French",
  es: "Spanish",
  de: "German",
  it: "Italian",
  pt: "Portuguese",
  ru: "Russian",
  zh: "Chinese",
  ja: "Japanese",
  ko: "Korean",
  ar: "Arabic",
  hi: "Hindi",
  nl: "Dutch",
  sv: "Swedish",
  pl: "Polish",
  tr: "Turkish",
  vi: "Vietnamese",
  th: "Thai",
  id: "Indonesian",
  el: "Greek",
  he: "Hebrew",
  cs: "Czech",
  da: "Danish",
  fi: "Finnish",
  no: "Norwegian",
  hu: "Hungarian",
  ro: "Romanian",
  uk: "Ukrainian",
  bn: "Bengali",
  fa: "Persian",
}

import { createSelector } from "@reduxjs/toolkit"
import remarksAdaptor from "../remarksAdaptor"
import { isResolved } from "./selectFilteredRemarks"

/**
 * Select the cound of unresolved remarks.
 * @type {(state: import("../remark.types").RemarksState) => ?number }
 */
const selectUnresolvedCount = createSelector(
  (state) => state.status === "loading",
  (state) => remarksAdaptor.getSelectors().selectAll(state),
  (state) => state.reviewedDetections,
  (loading, remarks, reviewDetections) =>
    loading
      ? null
      : remarks.filter((remark) => !isResolved(remark, reviewDetections)).length
)

export default selectUnresolvedCount

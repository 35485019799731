import { createAsyncThunk } from "@reduxjs/toolkit"
import remarksAdaptor from "../remarksAdaptor"
import selectCurrentCourse from "../selectors/selectCurrentCourse"
import selectGuestEmail from "../selectors/selectGuestEmail"
import normalizeComment from "../utils/normalizeComments"
import { hidePendingComment } from "../remarkSlice"
import { selectPendingComment } from "../selectors/selectPendingComment"

/**
 * @typedef {object} AddCommentArg
 * @property {string} text
 */

/** Adds a new comment to the current course. */
const addComment = createAsyncThunk(
  "comments/add",
  /**
   * @param {AddCommentArg} arg
   * @returns {import("../remark.types").Comment}
   */
  async (arg, context) => {
    const {
      getState,
      dispatch,
      extra: { api },
    } = context

    const state = getState()
    const { text = "" } = arg

    const courseId = selectCurrentCourse(state.remarks)

    const pendingComment = selectPendingComment(state)

    const location = pendingComment?.location ?? null

    const guestEmail = selectGuestEmail(state.remarks)
    const guest = guestEmail ? { email: guestEmail } : null

    return api
      .addComment({ courseId, text, location, as: guest })
      .then((data) => {
        dispatch(hidePendingComment())
        return normalizeComment(data)
      })
  }
)

export default addComment

/**
 * Build the reducer cases for adding a new comment.
 *
 * @param {import("../remark.types").RemarkActionReducerMapBuilder} builder
 */
export const buildCasesForAddComment = (builder) => {
  builder.addCase(addComment.fulfilled, (state, action) => {
    remarksAdaptor.addOne(state, action)
    state.pendingComment.visible = false
  })
}

import { Avatar, Box, ListItem, ListItemIcon, useTheme } from "@mui/material"
import { Circle, CheckBox } from "@mui/icons-material"
import { StyledListItemData } from "./styledListCustomElement"

interface StyledListItemProps {
  listType: "bullet" | "checkbox" | "number"
  index: number
  listItem: StyledListItemData
}

/**
 * Render bullet depending on list type configuration
 * @param listType - Type of bullet to render
 * @param index - Position of item in list
 * @param colour - Colour of bullet to render
 */
const getBullet = (
  listType: "bullet" | "checkbox" | "number",
  index: number,
  colour: string | null
) => {
  switch (listType) {
    case "bullet":
      return <Circle fontSize="small" sx={{ color: colour }} />
    case "checkbox":
      return <CheckBox fontSize="large" sx={{ color: colour }} />
    case "number":
      return <Avatar sx={{ bgcolor: colour }}>{index + 1}</Avatar>
    default:
      break
  }
}

/**
 * Component for individual Styled List item
 * @param props - Props for list item component
 */
const StyledListItem = (props: StyledListItemProps) => {
  const theme = useTheme() as any
  const { listType, index, listItem } = props

  return (
    <ListItem
      sx={{
        paddingLeft: 0,
      }}
      alignItems="center"
      key={index}
    >
      <ListItemIcon sx={{ mt: 0 }}>
        {getBullet(listType, index, theme.courseTheme?.colour)}
      </ListItemIcon>
      <Box
        sx={{
          "& *": {
            mt: "0",
            mb: "0",
          },
        }}
        dangerouslySetInnerHTML={{
          __html: listItem.html,
        }}
      />
    </ListItem>
  )
}

export default StyledListItem

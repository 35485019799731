import { Box, Typography } from "@mui/material"
import ImageUploadBox from "./ImageUploadBox"
import ColourPicker from "./ColourPicker"
import SizePicker from "./SizePicker"
import FontUploadBox from "./FontUploadBox"

/**
 * Component for choosing branding options
 *
 * @param props - Component props
 */
const BrandingForm = (props: any) => {
  const { branding, onPropertyChange } = props
  /**
   * Logo change
   * @param imageUrl - image url
   * @param file - image upload file
   */
  const onLogoChange = (imageUrl: string | null, file: any) =>
    onPropertyChange("logo", imageUrl, file)

  /**
   * brandmark change
   * @param imageUrl - image url
   * @param file - image upload file
   */
  const onBrandMarkChange = (imageUrl: string | null, file: any) =>
    onPropertyChange("brandMark", imageUrl, file)

  return (
    <Box>
      <ImageUploadBox
        data-cy="branding-logo"
        title="Logo"
        imageUrl={branding.logo}
        onChange={onLogoChange}
      />
      <Box mb={4}></Box>
      <ImageUploadBox
        data-cy="branding-mark"
        title="Brand Mark"
        imageUrl={branding.brandMark}
        onChange={onBrandMarkChange}
        maxWidth={25}
      />
      <Box mb={4}></Box>
      <ColourPicker {...props} data-cy="color-picker" />
      <Typography variant="body1" fontWeight="bold">
        Fonts
      </Typography>
      <FontUploadBox
        title="Heading"
        variant="heading"
        {...props}
        data-cy="fonts-heading"
      />
      <FontUploadBox
        title="Body"
        variant="body"
        {...props}
        data-cy="fonts-body"
      />
      <SizePicker {...props} data-cy="size-picker" />
    </Box>
  )
}

export default BrandingForm

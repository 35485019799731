import { Add, Delete, Edit } from "@mui/icons-material"
import {
  lighten,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Stack,
  Tab,
  Tabs,
  TextField,
  styled,
} from "@mui/material"
import { useMemo, useState } from "react"
import { CustomElementConfig, RenderViewOptions } from "./CustomElements"
import produce from "immer"
import _ from "lodash"
import FroalaEditor from "react-froala-wysiwyg"
import useEditorConfig from "../components/widgets/Editor/useEditorConfig"
import { useFlag } from "../utilities/feature-management"
import { FroalaCSSBox } from "./FroalaCSSBox"

/** Data representation of tabs state. */
interface TabsData {
  tabs: TabData[]

  /** Background color of tabs component */
  backgroundColor?: "primary"
}

/** Data representation of a single tab. */
interface TabData {
  /** Label of the tab. Appears in the tab itself */
  label: string

  /** Content of the tab in HTML */
  content: string
}

/*
 * This is a custom element that renders a set of tabs.
 * The HTML representation is stored as a section element with a data-component="tabs"
 * attribute. Each card is stored as a div with a data-component="tab" with a data-label attribute.
 * Each card can have HTML that contains images, video, text, etc.
 *
 * Sample HTML:
 * <section data-component="tabs">
 *  <div data-component="tab" data-label="Tab 1">
 *   <p>Tab 1 content</p>
 *   <p><img src="some-optional-image.jpg"></p>
 *  </div>
 *  <div data-component="tab" data-label="Tab 2">
 *   <p>Tab 2 content</p>
 *  </div>
 * </section>
 *
 */
export const tabsCustomElement: CustomElementConfig<TabsData> = {
  selector: "[data-component='tabs']",
  /**
   * Extract data from the light-DOM representation of the custom element
   * @param element The element to extract data from
   */
  getDataFromElement: (element) => {
    const tabs = Array.from(
      element.querySelectorAll("[data-component='tab']")
    ).map((tab) => {
      const label = (tab as HTMLElement).dataset.label ?? ""

      // Extract rich text content
      let content = (tab as HTMLElement).innerHTML

      return { label, content }
    })

    const backgroundColor = element.dataset.backgroundColor as
      | "primary"
      | undefined

    return { tabs, backgroundColor }
  },
  /**
   * Update the light-DOM representation of the custom element from the data
   * @param element The element to update
   * @param data The data to update the element with
   */
  updateElementFromData: (element, data) => {
    // Save contents
    element.innerHTML = data.tabs
      .map(
        (tab) =>
          `<div data-component="tab" data-label="${_.escape(tab.label)}">` +
          tab.content +
          `</div>`
      )
      .join("")

    if (data.backgroundColor) {
      element.dataset.backgroundColor = data.backgroundColor
    } else {
      delete element.dataset.backgroundColor
    }
  },
  /**
   * Render the custom element in the editor
   * @param options Options for rendering the custom element
   * @returns The rendered custom element
   * @see CustomElementConfig
   */
  renderView: (options: RenderViewOptions<TabsData>) => {
    const { data, onDataChange, editor, element, withStyles, readOnly } =
      options
    return (
      <TabsCustomComponent
        editor={editor}
        data={data}
        onDataChange={onDataChange}
        element={element}
        withStyles={withStyles}
        readOnly={readOnly}
      />
    )
  },
}

/**
 * Renders the tabs
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.data The tabs data to render.
 * @param props.onDataChange Callback to update the data.
 * @param props.withStyles Function to wrap children in styles.
 * @param props.readOnly Whether the editor is read-only.
 */
function TabsCustomComponent(props: {
  editor: any
  element: HTMLElement
  data: TabsData
  onDataChange?: (data: TabsData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
}) {
  const { data, onDataChange, readOnly } = props
  const [editorOpen, setEditorOpen] = useState(false)

  return (
    <>
      {props.withStyles(
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TabsDisplayComponent data={data} />
          {!readOnly && (
            <div key="buttons">
              <IconButton
                onClick={() => setEditorOpen(true)}
                data-cy="open-interactive-elements-editor"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  props.element.remove()
                  props.editor.undo.saveStep()
                }}
                data-cy="delete-interactive-elements-editor"
              >
                <Delete />
              </IconButton>
            </div>
          )}
        </Box>
      )}
      {editorOpen && (
        <TabsDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

/** Tabs component with state of which tab is selected
 * @param props See below.
 * @param props.data Data to display.
 * @returns The rendered component.
 */
function TabsDisplayComponent(props: { data: TabsData }) {
  const { data } = props

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  /**
   * Handle tab change
   * @param event event
   * @param newTabIndex index of the new tab
   */
  const handleChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setSelectedTabIndex(newTabIndex)
  }

  return (
    <Box
      sx={{
        border: "solid 1px #ddd",
        flex: 1,
        maxWidth: 800,
        margin: "10px auto 20px auto",
      }}
    >
      <Tabs
        value={selectedTabIndex}
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="tabs"
      >
        {data.tabs.map((tab, index) => (
          <CustomTab
            key={tab.label}
            label={tab.label}
            id={`tab-label-${index}`}
            aria-controls={`tab-contents-${index}`}
            data-cy="tab-button"
          />
        ))}
      </Tabs>
      {data.tabs.map((tab, index) => (
        <TabContents
          index={index}
          tab={tab}
          selectedTabIndex={selectedTabIndex}
          backgroundColor={data.backgroundColor}
        />
      ))}
    </Box>
  )
}

/** Custom tab with emphasized hover and selected styles */
const CustomTab = styled(Tab)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.primary.main,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}))

/**
 * Single tab contents.
 *
 * Make any contenteditable elements read-only as captions should not be editable. These
 * are made editable by the Froala editor when loading the custom component underlying HTML.
 *
 * @param props
 * @param props.tab The tab to render
 * @param props.index The index of the tab
 * @param props.selectedTabIndex The index of the selected tab
 * @param props.backgroundColor The background color of the tabs component
 */
function TabContents(props: {
  tab: TabData
  index: number
  selectedTabIndex: number
  backgroundColor?: "primary"
}) {
  const { tab, index, selectedTabIndex } = props

  return (
    <FroalaCSSBox
      id={`tab-contents-${index}`}
      aria-labelledby={`tab-label-${index}`}
      hidden={index !== selectedTabIndex}
      data-cy="tab-content"
      sx={{
        padding: "1rem",
        "& .fr-video": {
          // nudge iFrame down to be centered within parent span element created by Froala
          "& iframe": {
            marginTop: "8px",
          },
        },
        backgroundColor: (theme: any) => {
          if (props.backgroundColor === "primary") {
            return lighten(theme.palette.primary.main, 0.8)
          }
          return "white"
        },
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: tab.content.replace(/contenteditable="true"/g, ""),
        }}
      />
    </FroalaCSSBox>
  )
}

/**
 * Create dialog to add a tabs.
 * @param onClose Callback to close the dialog with the html to insert or null if cancelled.
 */
export function createAddTabsDialog(onClose: (html: string | null) => void) {
  return (
    <TabsDialog
      onSave={(data) => {
        // Create a new section element
        const section = document.createElement("section")
        section.dataset.component = "tabs"
        tabsCustomElement.updateElementFromData(section, data)

        onClose(section.outerHTML)
      }}
      onCancel={() => onClose(null)}
      initialData={{
        tabs: [
          {
            label: "Tab 1",
            content: "",
          },
          {
            label: "Tab 2",
            content: "",
          },
        ],
      }}
    />
  )
}

/**
 * Dialog to edit a tabs.
 * @param props See below.
 * @param props.initialData Initial data to display.
 * @param props.onCancel Callback when the dialog is cancelled.
 * @param props.onSave Callback when the dialog is saved.
 */
function TabsDialog(props: {
  initialData: TabsData
  onCancel: () => void
  onSave: (data: TabsData) => void
}) {
  const { initialData, onCancel, onSave } = props

  const enableBackground = useFlag("rollout-configurable-interaction-emphasis")

  const [data, setData] = useState<TabsData>(initialData)

  // Disable enforce focus to allow Froala image size editing to work
  return (
    <Dialog open={true} maxWidth="lg" fullWidth disableEnforceFocus>
      <DialogTitle>Edit Tabs</DialogTitle>
      <DialogContent>
        {enableBackground && (
          <div key="background">
            <FormControlLabel
              control={
                <Switch
                  checked={data.backgroundColor === "primary"}
                  onChange={(event) => {
                    setData(
                      produce(data, (draft) => {
                        draft.backgroundColor = event.target.checked
                          ? "primary"
                          : undefined
                      })
                    )
                  }}
                />
              }
              label="Color Contrast"
            />
          </div>
        )}
        {data.tabs.map((tab, index) => (
          <TabEditComponent
            key={index}
            tabs={data}
            onChange={(data) => setData(data)}
            index={index}
          />
        ))}

        <div key="add">
          <Button
            sx={{ mt: 1 }}
            onClick={() => {
              setData(
                produce(data, (draft) => {
                  draft.tabs.push({
                    label: `Tab ${draft.tabs.length + 1}`,
                    content: "",
                  })
                })
              )
            }}
            startIcon={<Add />}
          >
            Add Tab
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button key="cancel" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          key="save"
          color="primary"
          variant="contained"
          onClick={() => onSave(data)}
          disabled={data.tabs.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/** Props for the TabEditComponent */
interface TabEditComponentProps {
  tabs: TabsData
  onChange: (data: TabsData) => void
  index: number
}

/**
 * Edit a single tab.
 * @param props See below.
 */
function TabEditComponent(props: TabEditComponentProps) {
  const { tabs, onChange, index } = props

  const tab = tabs.tabs[index]

  return (
    <div data-cy="editor-tabs">
      <h3>
        Tab {index + 1}
        <IconButton
          sx={{ float: "right" }}
          onClick={() => {
            onChange(
              produce(tabs, (draft) => {
                draft.tabs.splice(index, 1)
              })
            )
          }}
          data-cy="editor-delete-button"
        >
          <Delete />
        </IconButton>
      </h3>
      <Stack spacing={2}>
        <TextField
          label="Tab Label"
          sx={{ mb: 1 }}
          value={tab.label}
          onChange={(e) => {
            onChange(
              produce(tabs, (draft) => {
                draft.tabs[index].label = e.target.value
              })
            )
          }}
          fullWidth
        />
        <TabContentComponent
          content={tab.content}
          onChange={(content) => {
            onChange(
              produce(tabs, (draft) => {
                draft.tabs[index].content = content
              })
            )
          }}
        />
      </Stack>
    </div>
  )
}

/** Props for the TabContentComponent. */
interface TabContentComponentProps {
  /** HTML to edit */
  content: string

  /** Callback when the HTML is changed.
   * @param content The new html data.
   */
  onChange: (content: string) => void
}

/**
 * Edits a single tab content
 *
 * @param props See above.
 */
function TabContentComponent(props: TabContentComponentProps) {
  const { content, onChange } = props
  const enableVideoUpload = useFlag("rollout-tabs-video-upload")

  /** Handle change to model
   * @param model The new model
   */
  const onModelChange = (model: string) => {
    onChange(model)
  }

  // Get editor config
  const editorConfig = useEditorConfig({
    /** Empty function */
    onInitialize: () => {},
    /** Empty function */
    onDestroy: () => {},
  })

  const tabsEditorConfig = useMemo(() => {
    if (!editorConfig) {
      return null
    }

    return produce(editorConfig, (draftConfig: any) => {
      // Set height to 400px of config
      draftConfig.height = 400

      // Fix z-index of modal
      draftConfig.zIndex = 2000

      if (!enableVideoUpload) {
        // Remove Insert Video toolbar button
        let richTextButtons = draftConfig.toolbarButtons.moreRich.buttons
        draftConfig.toolbarButtons.moreRich.buttons = richTextButtons.filter(
          (button: string) => button !== "insertVideo"
        )
      }
    })
  }, [editorConfig, enableVideoUpload])

  if (!tabsEditorConfig) {
    return null
  }

  return (
    <FroalaEditor
      tag="textarea"
      config={tabsEditorConfig}
      model={content}
      onModelChange={onModelChange}
    />
  )
}

import { Button, Menu, MenuItem, Theme } from "@mui/material"
import { SortOption, SortSelectProps } from "../../welcome/SortSelect"
import { useState } from "react"
import { alpha } from "@mui/material"
import { ArrowDropDown, Sort } from "@mui/icons-material"

/**
 * Custom dropdown for selecting sortable options
 * @param props - Component props
 */
const SortByControl = (props: SortSelectProps) => {
  const { options, sortBy, handleSelectSortOption } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const selectedOption = options.find((option) => option.value === sortBy)

  return (
    <>
      <Button
        sx={(theme: Theme) => ({
          paddingX: "6px",
          height: "32px",
          color: theme.scheme.onSecondaryContainer,
          backgroundColor: theme.scheme.secondaryContainer,
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: alpha(theme.scheme.surfaceTint, 0.08),
          },
        })}
        startIcon={<Sort />}
        endIcon={<ArrowDropDown />}
        aria-haspopup="true"
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
      >
        {selectedOption ? selectedOption.label : "Sort By"}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {options.map((option: SortOption) => (
          <MenuItem
            value={option.value}
            onClick={() => {
              handleSelectSortOption(option.value)
              setAnchorEl(null)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SortByControl

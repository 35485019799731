import { ReactNode } from "react"
import ReactDOM from "react-dom"
import { QuickInsertMenu } from "./QuickInsertMenu"
import { createQuickInsertItems } from "./quickInsertItems"
import "./quick-insert.css"
import saveStarredItems from "./api/saveStarredItems"

/** Options for quick insert setup */
export interface SetupQuickInsertOptions {
  /** Froala editor instance */
  editor: any

  /** Editor wrapper element (fr-wrapper) */
  editorWrapper: HTMLElement

  /** Called to insert a template */
  insertSmartTemplate: (template: string, anchorElement: HTMLElement) => void

  /** Called to display a React element within the tree */
  displayReactElement: (generator: (onClose: () => void) => ReactNode) => void

  /** True to enable tabs component */
  enableTabs?: boolean

  /** True to enable categories component */
  enableCategories?: boolean

  /** True to enable process component */
  enableProcess?: boolean

  /** True to enable importing sources from editor */
  enableImport?: boolean

  /** True to enable two column component */
  enableTwoColumns?: boolean

  /** True to enable styled lists */
  enableStyledLists?: boolean

  /** True to enable callout boxes */
  enableCalloutBoxes?: boolean

  /** True to enable star functionality */
  enableFavourites?: boolean
}

/** Setup quick insert menu. Appears on "/" being typed in a blank paragraph
 * and presents a list of items to insert.
 * @param options Options
 */
export function setupQuickInsert(options: SetupQuickInsertOptions) {
  const {
    editor,
    editorWrapper,
    insertSmartTemplate,
    displayReactElement,
    enableFavourites,
  } = options

  // Get the content editable element of Froala
  const editorContentEditable = editorWrapper.querySelector(
    ".fr-element"
  ) as HTMLElement

  // --- State of the quick insert
  // Hint element is the element that is used to show the hint
  let hintElement: HTMLElement | null = null

  // Hint anchor is the element that the hint is anchored to
  let hintAnchorElement: HTMLElement | null = null

  // Menu root is the element that the react menu is mounted to
  let menuReactRoot: HTMLElement | null = null

  // Menu anchor is the element that the menu is anchored to
  let menuAnchorElement: HTMLElement | null = null

  /** Get the anchor element that the selection is in, ensuring that collapsed.
   * An anchor element is the top-level element that the selection is in.
   */
  function getAnchorElement() {
    let selection = window.getSelection()
    if (!selection) {
      return null
    }

    // Ensure that selection is in editor and is collapsed
    const isSelectionInEditor =
      editorWrapper &&
      selection &&
      editorWrapper.contains(selection.anchorNode) &&
      editorWrapper.contains(selection.focusNode)

    // Ensure that anchor node is an element, going up the tree if necessary
    let anchorNode = selection.anchorNode
    while (
      anchorNode &&
      (anchorNode.nodeType !== Node.ELEMENT_NODE ||
        anchorNode.parentElement !== editorContentEditable)
    ) {
      anchorNode = anchorNode.parentElement
    }

    if (!isSelectionInEditor || !selection.isCollapsed || !anchorNode) {
      return null
    }

    return anchorNode as HTMLElement
  }

  /** Determine if hint should be shown for an anchor element.
   * Ensure that anchor element is a paragraph and is empty except for a CR or a
   * span with zero width space due to a Froala quirk.
   * @param anchorElement Anchor element
   */
  function shouldHintShow(
    anchorElement: HTMLElement | null
  ): anchorElement is HTMLElement {
    // Can't be null
    if (anchorElement == null) {
      return false
    }

    // Must be a P element
    if (anchorElement.tagName !== "P") {
      return false
    }

    // Must be empty (except for CR or zero width spaces)
    if (anchorElement.innerText.replace(/[\u200b\n]/g, "") !== "") {
      return false
    }

    // Must not contain any non-trivial elements
    const childNodes = anchorElement.querySelectorAll("*")
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i]
      if (childNode.nodeType === Node.ELEMENT_NODE) {
        const childElement = childNode as HTMLElement
        if (
          !["SPAN", "BR", "B", "I", "STRONG", "EM"].includes(
            childElement.tagName
          )
        ) {
          return false
        }
      }
    }
    return true
  }

  /** Handle selection changes */
  function onSelectionChanged() {
    // Remove existing hint
    if (hintElement) {
      hintElement.remove()
      hintElement = null
      hintAnchorElement = null
    }

    const anchorElement = getAnchorElement()

    // Determine if hint should be shown
    if (shouldHintShow(anchorElement)) {
      hintAnchorElement = anchorElement

      // Create hint element
      hintElement = document.createElement("div")
      hintElement.className = "quick-insert-hint"
      hintElement.style.top = `${anchorElement.offsetTop}px`
      hintElement.style.left = `${anchorElement.offsetLeft}px`
      hintElement.innerHTML = `Type "/" to insert`
      editorWrapper.appendChild(hintElement)

      // Close menu if hint is shown
      closeMenu()
    }
    /**
     * Note: The else if block for handling element changes that was here has been removed.
     *
     * This allows the logic to be handled in the onSelectionChanged function within
     * the useQuickInsert hook. This is required because it requires the starredIDs state
     * variable to save the starred items to the server and close the menu, which this function
     * does not have access to.
     *
     * */
  }

  /**
   * Close the menu, optionally saving any new starred items.
   * @param starredItems - Most recent iteration of favourites
   */
  function closeMenu(starredItems?: string[]) {
    if (menuReactRoot) {
      if (starredItems) {
        saveStarredItems(starredItems)
      }
      ReactDOM.unmountComponentAtNode(menuReactRoot)
      menuReactRoot.remove()
      menuReactRoot = null
    }
  }

  /** Open the menu */
  function openMenu() {
    // Close existing menu
    closeMenu()

    // Get anchor element
    menuAnchorElement = getAnchorElement()!

    // Create react root
    menuReactRoot = document.createElement("div")
    editorWrapper.appendChild(menuReactRoot)

    // Create insert context
    const insertContext = {
      editor,
      anchorElement: menuAnchorElement,
      insertSmartTemplate,
      displayReactElement,
    }

    // Render react component
    ReactDOM.render(
      <QuickInsertMenu
        insertContext={insertContext}
        closeMenu={closeMenu}
        enableFavourites={enableFavourites}
        quickInsertItems={createQuickInsertItems({
          enableTabs: options.enableTabs,
          enableCategories: options.enableCategories,
          enableProcess: options.enableProcess,
          enableImport: options.enableImport,
          enableTwoColumns: options.enableTwoColumns,
          enableStyledLists: options.enableStyledLists,
          enableCalloutBoxes: options.enableCalloutBoxes,
        })}
      />,
      menuReactRoot
    )
  }

  /** Handle keydown events to show menu
   * @param event Keyboard event
   */
  function onKeydown(event: KeyboardEvent) {
    if (hintElement != null && event.key === "/") {
      console.log("Opening menu")
      openMenu()
    }
  }

  document.addEventListener("selectionchange", onSelectionChanged)
  editorWrapper.addEventListener("keydown", onKeydown)

  // Listen for hint anchor element or menu anchor element being removed
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      for (let i = 0; i < mutation.removedNodes.length; i++) {
        const removedNode = mutation.removedNodes[i]
        if (removedNode === hintAnchorElement) {
          hintElement?.remove()
          hintElement = null
        }
        if (removedNode === menuAnchorElement) {
          closeMenu()
        }
      }
    }
  })
  observer.observe(editorContentEditable, { childList: true, subtree: true })

  return () => {
    document.removeEventListener("selectionchange", onSelectionChanged)
    editorWrapper.removeEventListener("keydown", onKeydown)
    observer.disconnect()
  }
}

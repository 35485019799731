import { ToggleButton, ToggleButtonGroup } from "@mui/lab"
import { Box, Typography } from "@mui/material"

/**
 * Component for selected one of three sizes
 * @param props - Component props
 */
const SizePicker = (props: any) => {
  const { branding, onPropertyChange } = props

  return (
    <Box>
      <Typography variant="body1" fontWeight="bold">
        Font Size
      </Typography>
      <ToggleButtonGroup
        color="primary"
        fullWidth
        value={branding.size}
        exclusive
        onChange={(e: any) => {
          onPropertyChange("size", e.target.value)
        }}
        aria-label="Size"
      >
        <ToggleButton value="smaller">Smaller</ToggleButton>
        <ToggleButton value="normal">Normal</ToggleButton>
        <ToggleButton value="bigger">Bigger</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default SizePicker

import React from "react"
import { grey, red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"
import { Link as RouterLink } from "react-router-dom"

const LinkBehaviour = React.forwardRef((props, ref) => {
  const { href, to, ...other } = props
  return <RouterLink ref={ref} to={href} {...other} />
})

const MenuItemLinkBehaviour = React.forwardRef((props, ref) => {
  const { href, to, ...other } = props
  const Component = href ? RouterLink : "li"

  return <Component ref={ref} to={href} {...other} />
})

const colors = {
  LEX_WHITE: "#fbf9ff",
}

/*
      Current themes for the frontend, based on Palette colours provided. A work in progress.
     */
const muiTheme = createTheme({
  smartTemplate: {
    fontFamily: "'Roboto Condensed', cursive",
  },
  typography: {
    fontFamily: ['"Heebo"', '"Arial"', '"Helvetica"', '"sans-serif"'].join(","),
    body2: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: "#41B6E6",
      light: "#edf2f6",
      contrastText: colors.LEX_WHITE,
    },
    secondary: {
      main: "#00AB84",
    },
    tertiary: {
      main: "#9c3de1",
    },
    neutral: {
      light: grey[300],
      main: grey[600],
    },
    addition: {
      light: "#80d5c2",
      main: "#00AB84",
      dark: "#005642",
    },
    removal: {
      main: red["800"],
    },
    text: {
      primary: "#333333",
      secondary: "#707070",
      contrast: colors.LEX_WHITE,
    },
    lexWhite: colors.LEX_WHITE,
    lexLightGrey: "#e7e8ef",
    lexGrey: "#707070",
    lexBlack: "#333333",
  },
  components: {
    MuiSkeleton: {
      styleOverrides: {
        text: (props) => ({
          display: props.style.width ? "inline-block" : "block",
        }),
      },
    },
    MuiMenuItem: {
      defaultProps: {
        component: MenuItemLinkBehaviour,
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehaviour,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none", // This line makes tab labels not all caps
        },
      },
    },
  },
})

export default muiTheme

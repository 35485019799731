import { Stack, Typography } from "@mui/material"
import ColourSelectionControl from "./ColourSelectionControl"
import FontSelectionControl from "./FontSelectionControl"
import ImageUploadControl from "./ImageUploadControl"
import SizeSelectionControl from "./SizeSelectionControl"
import Grid from "@mui/material/Grid"

type BrandingFormProps = {
  branding: any
  onPropertyChange: (property: string, url: string | null, file: any) => void
}

/**
 * Component that displays controls for setting branding options
 * @param props - Component props
 */
const BrandingForm = (props: BrandingFormProps) => {
  const { branding, onPropertyChange } = props

  return (
    <Grid spacing={2} columnSpacing={4} container>
      <Grid item xs={12} lg={6}>
        <Stack gap={2}>
          <ImageUploadControl
            title="Logo"
            imageUrl={branding.logo}
            onChange={(imageUrl: string | null, file: any) =>
              onPropertyChange("logo", imageUrl, file)
            }
          />
          <ImageUploadControl
            title="Brand Mark"
            imageUrl={branding.brandMark}
            onChange={(imageUrl: string | null, file: any) =>
              onPropertyChange("brandMark", imageUrl, file)
            }
            maxWidth={24}
          />
          <ColourSelectionControl {...props} />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack gap={2}>
          <Stack>
            <Typography gutterBottom variant="titleMedium">
              Fonts
            </Typography>
            <Stack gap={2}>
              <FontSelectionControl
                title="Heading"
                variant="heading"
                {...props}
              />
              <FontSelectionControl title="Body" variant="body" {...props} />
            </Stack>
          </Stack>
          <SizeSelectionControl {...props} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default BrandingForm

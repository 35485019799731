import PageLayout from "../../components/layouts/PageLayout"
import { Grid, Typography, Box, Alert } from "@mui/material"
import HelpSection from "./HelpSection"

/**.
 * Model for displaying Help sections on screen
 *
 * @type {[{description: string, links: [{label: string, href: string}, {label: string, href: string}, {label: string, href: string}, {label: string, href: string}], title: string}, {description: string, links: [{label: string, href: string}], title: string}, {description: string, links: [{label: string, href: string}, {label: string, href: string}, {label: string, href: string}, {label: string, href: string}], title: string}, {description: JSX.Element, title: string}, {description: JSX.Element, title: string}]}
 */
const helpSections = [
  {
    title: "Getting Started With LEAi",
    description:
      "Watch these videos to get started creating your courses with LEAi.",
    links: [
      {
        label: "Navigating the Home Screen",
        href: "https://youtu.be/xCDXxhLbov0",
      },
      {
        label: "Creating a Course",
        href: "https://youtu.be/WjdtxE12DLM",
      },
      {
        label: "Editing a Course - Part 1",
        href: "https://youtu.be/V1WJ_ro02iw",
      },
      {
        label: "Editing a Course - Part 2",
        href: "https://youtu.be/yMS2cdRCFZA",
      },
      {
        label: "Using LEAi Smart Templates",
        href: "https://youtu.be/C9nqKhpX2ls",
      },
      {
        label: "Actioning LearnAdvisor Recommendations",
        href: "https://youtu.be/tkjRmInjg6M",
      },
      {
        label: "Exporting Courses",
        href: "https://youtu.be/5MhtxfcRLEE",
      },
    ],
  },
  {
    title: "User Help",
    description:
      "Our comprehensive help guide will answer all your questions on using LEAi.",
    links: [
      {
        label: "Navigating the Home Screen",
        href: "/docs/Navigating-the-Home-Screen-2024-02-07.pdf",
      },
      {
        label: "Creating a Course",
        href: "/docs/Creating-a-Course-2024-02-07.pdf",
      },
      {
        label: "Editing Your Course",
        href: "/docs/Editing-Your-Course-2024-02-07.pdf",
      },
      {
        label: "Using LEAi Smart Template Sections",
        href: "/docs/Using-LEAi™-Smart-Template-Sec-2024-02-07.pdf",
      },
      {
        label: "Reading the LearnAnalyzer",
        href: "/docs/Reading-the-LearnAnalyzer™-2024-02-07.pdf",
      },
      {
        label: "Actioning the LearnAdvisor Recommendations",
        href: "/docs/Actioning-the-LearnAdvisor™-Re-2024-02-07.pdf",
      },
      {
        label: "Adding Interactive Elements",
        href: "/docs/Adding-Interactive-Elements-2024-02-07.pdf",
      },
      {
        label: "Exporting Courses",
        href: "/docs/Exporting-Courses-2024-02-07.pdf",
      },
      {
        label: "Creating Microlearning",
        href: "/docs/Creating-Microlearning-2024-02-07.pdf",
      },
      {
        label: "Updating and Maintaining Your Courses",
        href: "/docs/Updating-and-Maintaining-your--2024-02-07.pdf",
      },
      {
        label: "Collaborating With Others",
        href: "/docs/Collaborating-with-others-2024-02-07.pdf",
      },
      {
        label: "Viewing Course History",
        href: "/docs/Viewing-Course-History-2024-02-07.pdf",
      },
      {
        label: "Creating and Updating Learning Paths",
        href: "/docs/Creating-and-Updating-Learning-2024-02-07.pdf",
      },
      {
        label: "Setting Your Branding",
        href: "/docs/Setting-Your-Branding-2024-02-07.pdf",
      },
      {
        label: "Publishing to Skilljar",
        href: "/docs/Publish-to-Skilljar.pdf",
      },
      {
        label: "Accessibility Compliance",
        href: "/docs/Accessibility-Compliance-2024-02-07.pdf",
      },
      {
        label: null,
        href: null,
        divider: true,
      },
      {
        label: "Full LEAi User Guide",
        href: "/docs/LEAi-User-Guide-February 2024.pdf",
      },
    ],
  },
  {
    title: "Expert Advice",
    description:
      "Want to build courses like the pros? Check out these articles from our experts.",
    links: [
      {
        label: "11 Tips on How to Create Great Online Courses!",
        href: "https://learnexperts.ai/blog/11-tips-to-create-great-online-courses/",
      },
      {
        label: "What’s Microlearning?",
        href: "https://learnexperts.ai/blog/whats-microlearning/",
      },
      {
        label: "How to Build a Storyboard for eLearning",
        href: "https://learnexperts.ai/blog/how-to-build-a-storyboard-for-elearning/",
      },
      {
        label: "“Tell me. Show me. Let me try. Test me.” for Optimal Learning",
        href: "https://learnexperts.ai/blog/tell-me-show-me-let-me-try-test-me-for-optimal-learning/",
      },
      {
        label: "Implementing a Professional Certification Program",
        href: "https://learnexperts.ai/blog/implementing-certification-program-best-practices-strategies/",
      },
    ],
  },
  {
    title: "Knowledge Articles",
    description: <i>Coming soon</i>,
  },
]

/**
 * Component for rendering various links to Help Resources
 */
const HelpScreen = () => {
  return (
    <PageLayout fixed maxWidth="lg">
      <Box marginBottom={2}>
        <Typography gutterBottom variant="h4">
          LearnExperts Help Hub
        </Typography>
        <Typography gutterBottom variant="body1">
          If you’re looking to learn about using LEAi you’ve come to the right
          place! If you are a new LEAi user we recommend watching the Getting
          Started videos below. Visit often as we add content often to help you
          get the most out of the platform and create great learning fast!
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {helpSections.map((section) => (
          <Grid item sm={12} md={6}>
            <HelpSection {...section} />
          </Grid>
        ))}
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="center">
        <Alert severity="info">
          <Typography variant="body1">
            <strong>
              Still need help? Contact Support at
              customersupport@learnexperts.ca
            </strong>
          </Typography>
        </Alert>
      </Box>
    </PageLayout>
  )
}

export default HelpScreen

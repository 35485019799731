import { useState } from "react"
import { RewritePopover } from "./RewritePopover"
import { TransformToComponentPopover } from "./TransformToComponentPopover"
import {
  SelectTransformTypePopover,
  TransformType,
} from "./SelectTransformTypePopover"
import { useTransformToComponentEnabled } from "./transformUtils"

/**
 * Renders a popover for transforming the selected content.
 * @param props - The properties passed to the component.
 * @param props.editor - The editor instance.
 * @param props.editorWrapper - The wrapper of the editor.
 * @param props.onClose - The callback for closing the popover.
 * @param props.range - The range of the selected content.
 */
export function TransformPopover(props: {
  editor: any
  editorWrapper: HTMLElement
  onClose: () => void
  range: Range
}) {
  const { editor, editorWrapper, onClose, range } = props
  const transformToComponentEnabled = useTransformToComponentEnabled()

  // Default to rewrite if transform to component is not enabled
  const [transformType, setTransformType] = useState<TransformType | null>(
    transformToComponentEnabled ? null : "rewrite"
  )

  if (!transformType) {
    return (
      <SelectTransformTypePopover
        editorWrapper={editorWrapper}
        onSelect={setTransformType}
        onClose={onClose}
      />
    )
  }

  if (transformType === "rewrite") {
    return (
      <RewritePopover
        editor={editor}
        editorWrapper={editorWrapper}
        onClose={onClose}
        range={range}
      />
    )
  } else {
    return (
      <TransformToComponentPopover
        editor={editor}
        editorWrapper={editorWrapper}
        onClose={onClose}
        range={range}
        transformType={transformType}
      />
    )
  }
}

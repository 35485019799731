import { createAsyncThunk } from "@reduxjs/toolkit"
import selectCommentId from "../selectors/selectCommentId"

import selectCurrentCourse from "../selectors/selectCurrentCourse"
import selectGuest from "../selectors/selectGuest"
import selectServerId from "../selectors/selectServerId"

/** Action to reply to a comment. */
export const replyToComment = createAsyncThunk(
  "comments/reply",
  async (arg, context) => {
    const {
      getState,
      extra: { api },
    } = context

    const state = getState()

    const { id: commentId, text } = arg

    return api.replyToComment({
      commentId: selectServerId(commentId),
      courseId: selectCurrentCourse(state.remarks),
      text,
      as: selectGuest(state.remarks),
    })
  }
)

export default replyToComment

/**
 * Build the reducer cases for replying to a comment.
 *
 * @param {import("../remark.types").RemarkActionReducerMapBuilder} builder
 */
export const buildCasesForReplyToComment = (builder) => {
  builder.addCase(replyToComment.fulfilled, (state, action) => {
    const reply = action.payload

    /** @type {import("@reduxjs/toolkit/node_modules/immer/dist/internal").WritableDraft<import("../remark.types").Comment>} */
    const comment = state.entities[selectCommentId(reply.commentId)]
    if (comment) {
      comment.replies.push(reply)
    }
  })
}

import { isArray } from "lodash"

/** @typedef {import("../remark.types").Comment} Comment */

/**
 * Normalize the structure of a comment or set of comments returned from the server.
 *
 * @template {{}} S
 * @template {S | S[]} T
 *
 * @param {T} data
 * @returns {T extends S[] ? Comment[] : Comment}
 */
const normalizeComment = (data) => {
  if (isArray(data)) {
    return data.map(normalizeComment)
  }

  return {
    ...data,
    remarkType: "comment",
  }
}

export default normalizeComment

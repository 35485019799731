import { createContext, useContext } from "react"
import useFilter from "./useFilter"

/** A context for the detections interface. */
const DetectionsListContext = createContext({})

/** A context for filtering detections. */
const DetectionsFilterContext = createContext({})

/**
 * A context provider for detections data.
 */
const DetectionsProvider = (props) => {
  const {
    applyTransform,
    detections: allDetections,
    markDetectionReviewed,
    children,
  } = props
  const { detections, filter, filterByType, showDismissed } =
    useFilter(allDetections)

  const dismiss = (id) => markDetectionReviewed(id, true)
  const restore = (id) => markDetectionReviewed(id, false)

  return (
    <DetectionsListContext.Provider
      value={{
        detections,
        loading: !allDetections,
        count: detections?.length ?? 0,
        allDetections,
        allDetectionsCount: allDetections?.length ?? 0,
        dismiss,
        restore,
        applyTransform,
      }}
    >
      <DetectionsFilterContext.Provider
        value={{
          filter,
          filterByType,
          showDismissed,
        }}
      >
        {children}
      </DetectionsFilterContext.Provider>
    </DetectionsListContext.Provider>
  )
}

export default DetectionsProvider

/**
 * Access the detections interface.
 */
export const useDetections = () => useContext(DetectionsListContext)

/**
 * Access the detections filter interface.
 */
export const useDetectionControls = () => useContext(DetectionsFilterContext)

import { PostAdd } from "@mui/icons-material"
import {
  Box,
  Typography,
  Skeleton,
  Link,
  Button,
  Theme,
  Paper,
} from "@mui/material"
import { useHistory } from "react-router"
import {} from "react-router-dom"
import WelcomeCardLayout from "./WelcomeCardLayout"
import { Item } from "../../../homeNavigation/types"

type WelcomeCardProps = {
  onDismiss: () => void
  displayHelpMessage: boolean
  loadingLastEdited: boolean
  lastEdited: Item | null
}

/**
 * Component for displaying welcome message and visual
 * @param props - Component props
 */
const WelcomeCard = (props: WelcomeCardProps) => {
  const { onDismiss, displayHelpMessage, loadingLastEdited, lastEdited } = props
  const history = useHistory()

  /**
   * Navigate to most recently edited course in editor
   *
   * @param e - Click event
   */
  const jumpToCourse = (e: any) => {
    e.preventDefault()

    if (!lastEdited) {
      return
    }

    history.push(`/editor/?course=${lastEdited.id}`, {
      course: lastEdited,
    })
  }

  return (
    <Box
      borderRadius="12px"
      flex="1 1 10%"
      display="flex"
      alignItems="center"
      height="100%"
      overflow="hidden"
    >
      <Paper
        sx={(theme: Theme) => ({
          height: "100%",
          width: "100%",
          backgroundColor: theme.scheme.secondaryContainer,
        })}
        elevation={0}
      >
        {!loadingLastEdited ? (
          <>
            {!lastEdited ? (
              <WelcomeCardLayout
                title="Welcome!"
                actions={
                  <>
                    <Button size="small" color="tertiary" onClick={onDismiss}>
                      Dismiss
                    </Button>
                    <Button
                      startIcon={<PostAdd />}
                      size="small"
                      variant="contained"
                      color="tertiary"
                      onClick={(e) => {
                        e.preventDefault()
                        history.push("/create-course")
                      }}
                    >
                      Add course
                    </Button>
                  </>
                }
              >
                <Typography gutterBottom variant="bodyMedium">
                  My name is LEAi. I am here to guide you through creating
                  best-practices learning material.
                </Typography>
                {displayHelpMessage ? (
                  <Typography gutterBottom variant="bodyMedium">
                    To get started with LEAi, visit our{" "}
                    <Link
                      sx={
                        {
                          /**
                           * Set colour of link to be dark primary theme
                           * @param theme - MUI theme
                           */
                          //   color: (theme: Theme) => theme.palette.primary.dark,
                        }
                      }
                      href="/help"
                    >
                      Help Hub
                    </Link>{" "}
                    and watch the onboarding videos.
                  </Typography>
                ) : (
                  <Typography gutterBottom variant="bodyMedium">
                    To get started, create your first course.
                  </Typography>
                )}
              </WelcomeCardLayout>
            ) : (
              <WelcomeCardLayout
                title="Welcome back!"
                actions={
                  <>
                    <Button color="tertiary" size="small" onClick={onDismiss}>
                      Dismiss
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="tertiary"
                      onClick={jumpToCourse}
                    >
                      Jump to course
                    </Button>
                  </>
                }
              >
                <Typography gutterBottom variant="bodyMedium">
                  Last time you were making edits to <b>{lastEdited?.title}</b>.
                  Would you like to continue where you left off?
                </Typography>
              </WelcomeCardLayout>
            )}
          </>
        ) : (
          <WelcomeCardLayout>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton width="50%" />
          </WelcomeCardLayout>
        )}
      </Paper>
    </Box>
  )
}

export default WelcomeCard

import { Auth } from "@aws-amplify/auth"
import { aws_config } from "./aws_config"
bootstrap()

/**
 * Bootstrap the authentication config.
 */
export function bootstrap() {
  const origin = window.location.origin

  Auth.configure({
    ...aws_config,
    oauth: {
      domain: "learnexperts.auth.ca-central-1.amazoncognito.com",
      redirectSignIn: `${origin}/login`,
      redirectSignOut: `${origin}/login`,
      responseType: "code",
    },
  })
}

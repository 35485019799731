import { Box } from "@mui/material"
import Visual from "../../../../assets/images/leai-transformation.svg"

/**
 * Image to accompany welcome message
 */
const WelcomeImage = () => {
  return (
    <Box
      height="100%"
      flex="0 1 140px"
      overflow="hidden"
      display={{ xs: "none", sm: "flex" }} // hide on smaller viewports
      justifyContent="center"
      maxWidth="340px" // Nax width based on image dimensions
      sx={{
        borderTopRightRadius: "10px 50%",
        borderBottomRightRadius: "80px 50%",
      }}
    >
      <Box component="img" height="100%" src={Visual} alt="Welcome to LEAi" />
    </Box>
  )
}

export default WelcomeImage

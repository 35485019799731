import { Typography, Link, TypographyProps } from "@mui/material"
import terms from "../../../assets/docs/Terms_and_Conditions_2023.pdf"
import { motion } from "framer-motion"

/** A link for accessing the site's terms-of-service. */
const TermsOfServiceLink = (
  <Link target="_blank" href={terms}>
    Terms
  </Link>
)

/** A link for accessing the site's privacy policy. */
const PrivacyPolicyLink = (
  <Link
    target="_blank"
    href={{ pathname: "https://learnexperts.ai/privacy-policy" } as any}
  >
    Privacy Policy
  </Link>
)

/**
 * Displays links to LEAi policies.
 * @param props Component properties.
 */
function Terms(props: TypographyProps<typeof motion.div>) {
  return (
    <Typography
      component={motion.div}
      layout="position"
      variant="caption"
      align="center"
      {...props}
    >
      By logging into LEAi, you agree to our {TermsOfServiceLink} and
      acknowledge our {PrivacyPolicyLink}.
    </Typography>
  )
}

export default Terms

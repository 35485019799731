import { createSelector } from "@reduxjs/toolkit"
import { pick } from "lodash/fp"
import remarksAdaptor from "../remarksAdaptor"
import selectFilter from "./selectFilter"
import { Remark, RemarksState } from "../remark.types"

/**
 * Determine if the remark matches the given filter value.
 *
 * Attempts to match the filter type against the remarkType value. For
 * detections it will match against the remarkType then "rule" values.
 *
 * A filter type of "__all" will match any remark.
 *
 * @param filter The desired filter.
 * @param remark A remark instance to match.
 *
 * @example
 * ```js
 * matchesFilterType("comment", { id: "...", remarkType: "comment" })
 * // => true
 * matchesFilterType(
 *   "comment",
 *   { id: "...", remarkType: "detection", rule: "first_person" }
 * )
 * // => false
 * matchesFilterType(
 *   "detection/first_person",
 *   { id: "...", remarkType: "detection", rule: "first_person" }
 * )
 * // => true
 * ```
 */
export const matchesFilterType = (filter: string, remark: Remark) => {
  // Always display pending comments
  if (
    filter === "__all" ||
    filter === remark.remarkType ||
    remark.remarkType === "pendingComment"
  ) {
    return true
  }

  const subType = filter.split("/")[1]

  return "detection" === remark.remarkType && subType === remark.rule
}

/**
 * Select a list of remarks with user filters applied.
 *
 * @param state The current remarks state.
 */
export const selectFilteredRemarks = createSelector(
  [
    (state: RemarksState) => remarksAdaptor.getSelectors().selectAll(state),
    selectFilter,
    (state: RemarksState) => state.reviewedDetections,
  ],
  (remarks, filter, reviewedDetections) => {
    return remarks
      .filter(
        (value) => filter.resolved === isResolved(value, reviewedDetections)
      )
      .filter((remark) => matchesFilterType(filter.type, remark))
      .map(pick(["id", "remarkType", "location", "courseId"]))
  }
)

export default selectFilteredRemarks

/**
 * Check if a given remark has been resolved.
 *
 * @param remark The target remark.
 * @param reviewedDetections The set of reviewed detection keys.
 * @returns
 */
export const isResolved = (
  remark: Remark,
  reviewedDetections: { [key: string]: boolean }
): boolean =>
  (remark.remarkType === "detection" &&
    (reviewedDetections[remark.id] ||
      (remark.detectionKey && reviewedDetections[remark.detectionKey]))) ||
  (remark.remarkType === "comment" && !!remark.resolvedOn)

import { createSlice } from "@reduxjs/toolkit"
import remarksAdaptor from "./remarksAdaptor"
import normalizeDetection from "./utils/normalizeDetection"
import normalizeComment from "./utils/normalizeComments"
import selectDetectionId from "./selectors/selectDetectionId"
import { buildCasesForAddComment } from "./actions/addComment"
import { buildCasesForEditComment } from "./actions/editComment"
import { buildCasesForDeleteComment } from "./actions/deleteComment"
import { buildCasesForResolveComment } from "./actions/resolveComment"
import { buildCasesForReplyToComment } from "./actions/replyToComment"
import { buildCasesForDeleteCommentReply } from "./actions/deleteCommentReply"
import { buildCasesForRefreshComments } from "./actions/refreshComments"
import { buildCasesForEditCommentReply } from "./actions/editCommentReply"
import { buildCasesForRefreshDetections } from "./actions/refreshDetections"
import { buildCasesForFetchDetections } from "./actions/fetchDetections"
import { buildCasesForDetectionsReview } from "./actions/reviewDetections"

/** @type {import("./remark.types").RemarksState} */
const initialState = remarksAdaptor.getInitialState({
  status: "idle",
  filter: { type: "__all", resolved: false },
  pendingComment: { visible: false, location: null },
  reviewedDetections: {},
  detectionsLoading: true,
})

/**
 * A collection of actions and reducers for managing Remark state.
 */
export const remarksSlice = createSlice({
  name: "remarks",
  initialState: initialState,
  reducers: {
    /**
     *  Reset the remarks state pending new course data.
     *
     * @param {import("@reduxjs/toolkit").PayloadAction<{ courseId: string }>} action
     * @returns {import("./remark.types").RemarksState}
     * */
    pending: (state, action) => {
      return {
        ...initialState,
        status: "loading",
        currentCourse: action.payload.courseId,
      }
    },
    /**
     * Display pending comment input and the comment's location if given
     */
    showPendingComment: (state, action) => {
      remarksAdaptor.upsertOne(state, {
        id: "pending",
        remarkType: "pendingComment",
        location: action.payload.location,
      })
    },

    /**
     * Remove the pending comment from the remarks list
     */
    hidePendingComment: (state, action) => {
      remarksAdaptor.removeOne(
        state,
        remarksAdaptor.selectId({
          id: "pending",
          remarkType: "pendingComment",
        })
      )
    },

    /**
     * Set email of guest in readonly mode
     */
    setGuestEmail: (state, action) => {
      state.guestEmail = action.payload.email
    },
    /**
     * Filter remarks by the given type.
     * @param {import("@reduxjs/toolkit").PayloadAction<string>} action
     */
    filterByType: (state, action) => {
      state.filter.type = action.payload
    },
    /**
     * Filter remarks by the given resolution state.
     * @param {import("@reduxjs/toolkit").PayloadAction<boolean>} action
     */
    filterToResolved: (state, action) => {
      state.filter.resolved = action.payload
    },
    /** Update the remarks state from a given course response. */
    fromCourseResponse: (state, action) => {
      const response = action.payload
      const includeDetections = action.payload.includeDetections
      const data = action.payload?.data ?? {}

      const comments = normalizeComment(data.comments ?? [])
      const detections = includeDetections
        ? normalizeDetection(data.detections ?? [])
        : []

      remarksAdaptor.setAll(state, [...comments, ...detections])

      state.reviewedDetections = data.reviewed_detections

      if (includeDetections) {
        state.detectionsLoading = false
      }

      const requiresUpgrade =
        includeDetections && response?.data?.upgrade_detections
      if (!requiresUpgrade) {
        state.status = "fulfilled"
      }
    },
    removeDetection: (state, action) => {
      remarksAdaptor.removeOne(state, selectDetectionId(action.payload.id))
    },
    updateDetection: (state, action) => {
      remarksAdaptor.updateOne(state, {
        id: selectDetectionId(action.payload.id),
        changes: {
          selectedIndex: action.payload.selectedIndex,
        },
      })
    },
    toggleOptionsVisible: (state, action) => {
      console.log(action)
      const id = selectDetectionId(action.payload.id)
      const { optionsVisible } = remarksAdaptor
        .getSelectors()
        .selectById(state, id)

      remarksAdaptor.updateOne(state, {
        id,
        changes: {
          optionsVisible: !optionsVisible,
        },
      })
    },
  },
  extraReducers: (builder) => {
    buildCasesForAddComment(builder)
    buildCasesForEditComment(builder)
    buildCasesForDeleteComment(builder)
    buildCasesForResolveComment(builder)
    buildCasesForReplyToComment(builder)
    buildCasesForEditCommentReply(builder)
    buildCasesForDeleteCommentReply(builder)
    buildCasesForDetectionsReview(builder)
    buildCasesForRefreshDetections(builder)
    buildCasesForRefreshComments(builder)
    buildCasesForFetchDetections(builder)
  },
})

const { actions, reducer } = remarksSlice

export const {
  filterByType,
  filterToResolved,
  fromCourseResponse,
  pending,
  removeDetection,
  setGuestEmail,
  showPendingComment,
  hidePendingComment,
  updateDetection,
  toggleOptionsVisible,
} = actions

export default reducer

import { parseQueryString } from "../utilities/queryString"
import GiveCourseScreen from "../components/screens/GiveCourseScreen"

const GiveCoursePage = ({ location }) => {
  const breadcrumbs = location?.state?.breadcrumbs ?? [
    {
      label: "Courses",
      href: "/",
    },
    {
      label: "Tools",
      href: "/tools",
    },
  ]

  const { course } = parseQueryString(location.search)

  return <GiveCourseScreen course={course} breadcrumbs={breadcrumbs} />
}

export default GiveCoursePage

import { useMemo } from "react"
import { useAppDispatch } from "../store"
import {
  fromCourseResponse,
  pending,
  refreshDetections,
  setGuestEmail,
  refreshComments,
} from "../store/remarks"
import { fetchDetections } from "../store/remarks/actions/fetchDetections"
import { debounce } from "lodash"

/**
 * Use the set of action dispatchers for manipulating remarks.
 */
const useRemarksDispatch = () => {
  const dispatch = useAppDispatch()

  return useMemo(() => {
    const fetchDetectionsDebounced = debounce(
      (html, readOnly) => {
        dispatch(fetchDetections({ html, readOnly }))
      },
      1000,
      { maxWait: 3000 }
    )

    return {
      /**
       * Reset the remarks state pending new course data.
       *
       * @param {string} courseId
       */
      pending: (courseId) => {
        dispatch(pending({ courseId }))
      },
      /**
       * Sets the guest email if a user is unauthenticated
       */
      setGuestEmail: (email) => {
        dispatch(setGuestEmail({ email }))
      },
      /**
       * Update the remarks state from a given course response.
       */
      fromCourseResponse: (response, { includeDetections }) => {
        dispatch(
          fromCourseResponse({
            data: response.data,
            status: response.status,
            includeDetections,
          })
        )
      },

      refreshDetections: (response) => {
        dispatch(refreshDetections())
      },

      refreshComments: (response) => {
        dispatch(refreshComments())
      },

      /**
       * Fetch updated detections for the given html.
       */
      fetchDetections: fetchDetectionsDebounced,
    }
  }, [dispatch])
}

export default useRemarksDispatch

import axiosGlobal from "./axios"
import { EXPORT_API_ENPOINT } from "./exportEndpoint"

/**
 * Sent export request to the export service
 * @param exportItem - Object with export details
 * @param axios - axios
 */
const startExport = async (exportItem: any, axios = axiosGlobal) => {
  const resp = await axios.post<any>(EXPORT_API_ENPOINT, exportItem, {
    baseURL: "/",
  })

  return resp
}

export default startExport

import { gapi } from "gapi-script"
import config from "../config"

async function load_gapi() {
  /*
   * Initialize Google API
   */

  const apiCreds = config.googleDriveApiCredentials

  // lopad google js files
  await new Promise((res, rej) => {
    gapi.load("client", { callback: res, onerror: rej })
  })

  // init the client
  await gapi.client.init({
    discoveryDocs: ["https://discovery.googleapis.com/$discovery/rest"],
    apiKey: apiCreds.apiKey,
    clientId: apiCreds.clientId,
    scope: ["https://www.googleapis.com/auth/drive.readonly"].join(" "),
  })

  // load drive
  await gapi.client.load("drive", "v3")
}

export async function downloadDriveFile(fileId, mimeType, exportType) {
  /**
   * Convert and download a file from google drive
   *
   * @param {string} fileId Google file id
   * @param {string} mimeType mime type of file
   * @param {string} exportType mime type to conver to
   * @returns {Promise<response>} request response result
   */

  // load google api - will no nothing if already loaded
  await load_gapi()

  if (exportType != null) {
    return gapi.client.drive.files.export({
      fileId: fileId,
      mimeType: exportType,
    })
  } else {
    return gapi.client.drive.files.get({
      fileId: fileId,
      alt: "media",
    })
  }
}

import { createAsyncThunk } from "@reduxjs/toolkit"
import { selectCommentById } from "../.."
import remarksAdaptor from "../remarksAdaptor"
import selectCommentId from "../selectors/selectCommentId"
import selectCurrentCourse from "../selectors/selectCurrentCourse"
import selectServerId from "../selectors/selectServerId"
import selectGuest from "../selectors/selectGuest"

/**
 * @typedef {import("../remark.types").Comment} Comment
 * @typedef {{
 *   extra: {
 *     api: {
 *       deleteComment: ({ courseId: string, id: string }) => Promise<void>
 *     }
 *   }
 *   state: import("../..").AppState
 *   rejectedMeta: { original: Comment }
 *   rejectValue: Error
 * }} ThunkApiConfig
 * @typedef {{ id: string }} ThunkArg
 */

/**
 * Builds an action for deleting a comment.
 *
 * @type {import("@reduxjs/toolkit").AsyncThunk<void, ThunkArg, ThunkApiConfig>}
 */
export const deleteComment = createAsyncThunk(
  "comments/delete",
  async (arg, context) => {
    const {
      getState,
      extra: { api },
      rejectWithValue,
    } = context

    const state = getState()

    const courseId = selectCurrentCourse(state.remarks)
    const id = selectServerId(arg.id)
    const original = selectCommentById(state, id)

    try {
      return await api.deleteComment({
        courseId,
        id,
        as: selectGuest(state.remarks),
      })
    } catch (e) {
      return rejectWithValue(e, { original })
    }
  }
)

export default deleteComment

/**
 * Builds the reducer cases for deleting a comment.
 *
 * @param {import("@reduxjs/toolkit").ActionReducerMapBuilder<import("../remark.types").RemarksState>} builder
 */
export const buildCasesForDeleteComment = (builder) => {
  builder
    .addCase(deleteComment.pending, (state, action) => {
      const { id } = action.meta.arg
      remarksAdaptor.removeOne(state, selectCommentId(id))
    })
    .addCase(deleteComment.rejected, (state, action) => {
      remarksAdaptor.addOne(state, action.meta.original)
    })
}

import { useCallback, useEffect, useState } from "react"
import { UploadItem, downloadRemoteFiles } from "../../../utilities/fileUpload"
import * as api from "../../../api"
import useFlag from "../../../utilities/feature-management/useFlag"

/**
 * Hook that handles logic for loading and selecting heads from an upload file
 *
 * @param uploadItem - Current upload item data containing headings
 * @param onUpdateUploadItem - Callback to update the item with checked headings
 * @param setError - Callback to set error
 * @param onGetHeadings - Callback to get doc headings
 * @param isDragging - Flag to denote whether a drag event is in progress
 */
const useFileContainerHeadings = (
  uploadItem: UploadItem,
  onUpdateUploadItem: () => void,
  setError: (error: any) => void,
  onGetHeadings: (uploadItem: UploadItem, options: any) => any[],
  isDragging: boolean
) => {
  const [loadingHeadings, setLoadingHeadings] = useState(false)
  const [isShiftKeyDown, setIsShiftKeyDown] = useState(false)

  const [lastSelectedIndex, setLastSelectedIndex] = useState(0)

  const rolloutServerlessImportAPI = useFlag("rollout-serverless-import-api")

  useEffect(() => {
    /**
     * Detect if shift key is held down
     * @param event - Keydown event
     */
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsShiftKeyDown(true)
      }
    }

    /**
     * Detect if shift key is released
     * @param event - Keydown event
     */
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setIsShiftKeyDown(false)
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [])

  const fetchHeadings = useCallback(async () => {
    if (uploadItem.headings == null && !loadingHeadings) {
      setLoadingHeadings(true)

      const errors = await downloadRemoteFiles([uploadItem])

      if (errors.length > 0) {
        const messages = errors.map((error) => error.errorMessage)
        setError({
          messages,
          title: "There was a problem downloading content.",
          subtitle: "",
        })
        uploadItem.headingsOpen = false
        setLoadingHeadings(false)
      } else {
        try {
          let documentHeadings
          if (rolloutServerlessImportAPI) {
            documentHeadings = await onGetHeadings(uploadItem, {})

            if (documentHeadings == null) {
              documentHeadings = []
            }
          } else {
            documentHeadings = await api.fetchDocumentHeadings(uploadItem)
          }

          uploadItem.headings = documentHeadings.map((heading, index) => {
            return { index, selected: uploadItem.selected, ...heading }
          })
          onUpdateUploadItem()
        } finally {
          setLoadingHeadings(false)
        }
      }
    }
  }, [
    uploadItem,
    loadingHeadings,
    setError,
    rolloutServerlessImportAPI,
    onUpdateUploadItem,
    onGetHeadings,
  ])

  /**
   * Toggle display of headings for the file.
   */
  const onToggleHeadings = () => {
    if (!uploadItem.headingsOpen) {
      fetchHeadings()
    }

    uploadItem.headingsOpen = !uploadItem.headingsOpen
    onUpdateUploadItem()
  }

  /**
   * Select/unselect one of the file's headings.
   * @param uploadItem - the file
   * @param heading - the heading
   */
  const onCheckHeading = (uploadItem: UploadItem, heading: any) => {
    if (!isDragging && uploadItem.headings) {
      heading.selected = !heading.selected

      if (isShiftKeyDown) {
        const startIndex = Math.min(heading.index, lastSelectedIndex)
        const endIndex = Math.max(heading.index, lastSelectedIndex)

        for (let i = startIndex; i <= endIndex; i++) {
          uploadItem.headings[i].selected = heading.selected
        }
      }

      if (heading.selected) {
        // at least one heading is selected, make sure the file is too
        uploadItem.selected = true
      } else {
        // if no headings are selected, deselect the file
        const allDeselected =
          uploadItem.headings!.find((head) => head.selected) == null

        if (allDeselected) {
          uploadItem.selected = false
        }
      }
      if (heading.index !== lastSelectedIndex) {
        setLastSelectedIndex(heading.index)
      }
      onUpdateUploadItem()
    }
  }

  return {
    onToggleHeadings,
    loadingHeadings,
    onCheckHeading,
  }
}

export default useFileContainerHeadings

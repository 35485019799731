import { useCallback, useMemo, useState } from "react"

/**
 * Filter state for detections.
 *
 * @param {Object[]} detections
 */
const useFilter = (detections = []) => {
  const [filter, setFilter] = useState({ type: "__all", dismissed: false })

  const filterByType = useCallback(
    (type) => setFilter((filter) => ({ ...filter, type })),
    []
  )

  const showDismissed = useCallback(
    (dismissed) => setFilter((filter) => ({ ...filter, dismissed })),
    []
  )

  const filteredDetections = useMemo(
    () => applyFilter(detections, filter),
    [detections, filter]
  )

  return {
    detections: filteredDetections,
    filter,
    filterByType,
    showDismissed,
  }
}

export default useFilter

/**
 * Apply the given filter over a collection of detections.
 */
const applyFilter = (detections = [], filter) => {
  const { dismissed, type } = filter

  return detections.filter(
    (detection) =>
      (type === "__all" || detection.rule === type) &&
      detection.reviewed === dismissed
  )
}

import { useEffect, useRef, useState } from "react"
import * as api from "../api"
import { throttle } from "lodash"
import {
  findDetectionElement,
  getPathFromSelection,
  highlightDetection,
  htmlToElement,
  findSelectedElementByScrollPosition,
  findElementBySelection,
  findSectionHeadingByOffset,
  uuidv4,
} from "../utilities/domUtils"
import useRemarksDispatch from "./useRemarksDispatch"
import { useStableCallback } from "./useStableCallback"
import {
  cleanupSmartTemplateControls,
  addSmartTemplate,
  findOwningSectionHeaderElement,
} from "../utilities/smartTemplates"
import observeMutations from "../features/outline/utils/observeMutations"
import { CustomElements } from "../custom-elements/CustomElements"
import { useTheme } from "@mui/material"
import { questionCustomElement } from "../custom-elements/questionCustomElement"
import { questionCustomElementPlainStem } from "../custom-elements/questionCustomElementPlainStem"
import { flipCardGridCustomElement } from "../custom-elements/flipCardGridCustomElement"
import { labeledImageCustomElement } from "../custom-elements/labeledImageCustomElement"
import { setupQuickInsert } from "../features/quick-insert/setupQuickInsert"
import { addDragDropToFroala } from "../utilities/addDragDropToFroala"
import useDocumentStats from "../features/outline/hooks/useDocumentStats"
import { useFlag } from "../utilities/feature-management"
import { smartTemplateControlCustomElement } from "../custom-elements/smart_templates/smartTemplateControlCustomElement"
import { tabsCustomElement } from "../custom-elements/tabsCustomElement"
import { categoriesCustomElement } from "../custom-elements/categoriesCustomElement"
import { processCustomElement } from "../custom-elements/processCustomElement"
import BottomControl from "../features/importSourcesToEditor/bottomControlCustomElement"
import { createPortal } from "react-dom"
import { ParagraphInserter } from "../features/ParagraphInserter"
import { styledListCustomElement } from "../custom-elements/StyledList/styledListCustomElement"
import useSaveOnExit from "./useSaveOnExit"
import { AutoAltTextPopup } from "../components/widgets/Editor/AutoAltTextPopup"
import { calloutBoxCustomElement } from "../custom-elements/calloutBoxCustomElement"
import { useSelector } from "react-redux"
import useEditorDispatch from "../store/editor/useEditorDispatch"
import { selectCurrentSectionNumber } from "../store/editor/selectors"

// Auto save config
const AUTO_SAVE_INTERVAL = 180000 // auto-saves course every 3 minutes

const EDITOR_DIV_SELECTOR = "fr-wrapper"

/** States for the saving of a course */
export const SAVE_STATE = {
  SAVING: "saving",
  SAVED: "saved",
  UNSAVED: "unsaved",
  OFFLINE: "offline",
}

/**
 * Hook to manage the state of the editor
 * @param courseId - ID of the course
 * @param readOnly - Is the editor in read-only mode?
 */
const useEditorState = (courseId, readOnly) => {
  const editorRef = useRef()
  const theme = useTheme()

  /** Incrementing flag if scroll updates are disabled (i.e. ignore scroll events)
   * Temporary disabled when externally scrolling and re-enabled when the user stops scrolling
   * as determined by debounce on scroll events. Since there may be multiple external
   * updates, this is an integer that gets incremented when the external event is received
   * and decremented when the debounce fires. If this is zero, scroll events are enabled.
   */
  const scrollUpdateDisabledFlag = useRef(0)

  // Current version of the html. Differs from loadedHtml if save required
  const [html, setHtml] = useState(null)
  const [version, setVersion] = useState()
  const [title, setTitle] = useState("") // Current title of the course
  const [detections, setDetections] = useState()
  const [detectionsLoading, setDetectionsLoading] = useState(false)
  const [saveState, setSaveState] = useState(SAVE_STATE.SAVED)
  const [initialized, setInitialized] = useState(false)
  const [error, setError] = useState()
  const [editor, setEditor] = useState()

  const currentSectionNumber = useSelector(selectCurrentSectionNumber)
  const remarks = useRemarksDispatch()

  const { setScrollPath, setCurrentSectionHeader, setCurrentSectionNumber } =
    useEditorDispatch()

  const container = editor?.$el?.[0]
  const { duration } = useDocumentStats(container)

  const enableTabsComponent = useFlag("rollout-tabbed-content-interaction")
  const enableCategoriesComponent = useFlag("rollout-categories-interaction")
  const enableProcessComponent = useFlag("rollout-process-interaction")
  const enableImportSources = useFlag("rollout-editor-import-sources")
  const enableTwoColumnsComponent = useFlag("rollout-two-column-content")
  const enableParagraphInserter = useFlag("rollout-insert-between-sections")
  const enableStyledListsComponent = useFlag("rollout-styled-lists")
  const enableFavourites = useFlag("rollout-quick-insert-menu-favourites")
  const enableHtmlQuestionStems = useFlag("rollout-html-question-stems")
  const enableCalloutBoxes = useFlag("rollout-callout-boxes")
  const enableServerlessDetections = useFlag("rollout-serverless-detections")

  const handleCourseLoad = useStableCallback((response) => {
    setTitle(response.data.title)
    setHtml(response.data.assembled_html)
    setVersion(response.data.version_id)

    remarks.fromCourseResponse(response, {
      includeDetections: !enableServerlessDetections,
    })

    if (!readOnly) {
      setDetections(
        reviewDetections(
          response.data.detections,
          response.data.reviewed_detections
        )
      )

      //LD-374 Upgrade Detections?
      //Trigger a full save to get the latest detections
      // console.log(response.data.upgrade_detections)
      const requiresDetectionUpgrade =
        !enableServerlessDetections &&
        response.data.upgrade_detections &&
        !readOnly
      if (requiresDetectionUpgrade) {
        console.log("Upgrading Detections")
        asyncCourseSave(courseId, html)
      }
    } else {
      setDetections([])
    }
  })

  // Load document from server
  useEffect(() => {
    setHtml(null)
    remarks.pending(courseId)

    api
      .loadCourse(courseId)
      .then(handleCourseLoad)
      .catch((e) => {
        console.log(e)
        console.log("Failed to load course")
        // TODO This should fail more thoroughly
        setError(e)
      })
  }, [courseId, readOnly, remarks, theme, handleCourseLoad])

  useEffect(() => {
    const isReadyForDetections = enableServerlessDetections && html !== null
    if (!isReadyForDetections) {
      return
    }

    remarks.fetchDetections(html, readOnly)
  }, [html, remarks, enableServerlessDetections, readOnly])

  /** Save the course
   *
   * The save function is important to persist the user's work.
   * - The user can save manually at any time (clicking the Save icon or keyboard shortcut)
   * - The system will also save when the user leaves the editor
   * - The system will also auto save periodically to guard against loosing significant work
   *
   * Given our current design,
   * functions such as machine learning detections and intelligent updates take time.
   *
   * Save will:
   * - persist the course details
   * - save the new course HTML
   * - run, cache and return intelligent updates
   * - run, cache and return machine learning detections
   *
   * TODO: Make save actions asynchronous. Move all save processing out of the web tier and model.
   *
   * There are types of save: manual, auto, close.
   * Manual: save can be triggered at any time and will execute immediately.
   * e.g. manually clicking save, keyboard shortcut or leaving the editor
   * Auto: aims to help the user not lose hours of work.
   * Since processing detections and intelligent updates take time.
   * Close: user has navigated from the editor, trigger a save
   *
   */
  const saveCourse = useStableCallback(async (options = {}) => {
    if (!initialized || readOnly || html === null) {
      return
    }
    if (saveState === SAVE_STATE.SAVED) {
      // No change in course content
      //console.log(`Save: No change in course content. return`)
      return
    }
    if (saveState === SAVE_STATE.SAVING) {
      // There is already a save request in progress, skip saving
      // console.log(`There is already a save request in progress, skip saving`)
      return
    }
    setDetectionsLoading(true)

    // Initiate an asynchronous save job
    asyncCourseSave(courseId, html, options)
  })

  // Autosave periodically
  useEffect(() => {
    if (readOnly) {
      return
    }
    //console.log("Autosaving")
    const interval = setInterval(() => saveCourse(), AUTO_SAVE_INTERVAL)
    return () => clearInterval(interval) // stops auto-saving course
  }, [readOnly, saveCourse])

  /**
   * Start Async Save
   * Send the course to the server to be saved. An async job will start.
   *
   * The process is finished when the status is 'complete'
   *
   * Detections will run and will be cached on the server and returned
   * TODO: Intelligent Updates will run and will be cached on the server and returned
   *
   * @param {*} courseId the identifier
   * @param {*} html    current course html in the editor
   */
  const asyncCourseSave = useStableCallback(async (courseId, html, options) => {
    if (readOnly) {
      return
    }
    //defines how long to wait between async job checks
    const pollDelayTime = 1000

    /**
     * Send a request to start the async task that returns an async id
     * that is used to track async job progress
     */
    const startAsyncSaveJob = async () => {
      setSaveState(SAVE_STATE.SAVING)

      const { jobId, version } = await api.saveCourse(
        courseId,
        {
          html,
          duration: duration?.values?.minutes,
        },
        { keepalive: options?.keepalive }
      )
      setVersion(version)
      return jobId
    }

    /**
     * Check the async job for status, completion or failure.
     * Keep checking until complete (or it fails)
     * @param async_id the id of the async job
     */
    async function checkSaveAsyncJob(async_id) {
      try {
        let job = await api.checkAsyncJob(async_id)
        let asyncJob = job.data
        //console.log(asyncJob.status)

        //check to see if the json data file is ready for download
        //if not keep checking
        if (asyncJob.status === "complete") {
          //console.log("complete")

          setSaveState(SAVE_STATE.SAVED)

          //Get comments
          remarks.refreshComments()

          loadDetections()
        } else if (asyncJob.status === "failed_no_retry") {
          console.log("failed_no_retry")
          setSaveState(SAVE_STATE.UNSAVED)
        } else {
          setTimeout(() => {
            checkSaveAsyncJob(asyncJob.async_id)
          }, pollDelayTime)
        }
        return asyncJob.async_id
      } catch (err) {
        console.log(err)
        let errorMsg = `Error checking async job status.`
        console.log(errorMsg)
        setSaveState(SAVE_STATE.UNSAVED)
        // TODO: handle these states?
        // setSaveState(SAVE_STATE.OFFLINE)
        // setSaveState(SAVE_STATE.UNSAVED)
      }
    }

    // initiate the request to the async job service
    // and then start polling for updates
    // the first async job check is sent without delay in case
    // processing is already complete.
    try {
      const jobId = await startAsyncSaveJob(courseId, html)
      checkSaveAsyncJob(jobId)
    } catch (error) {
      console.log(error)
      console.log("error: could not start async job")
      setSaveState(SAVE_STATE.UNSAVED)
    }
  })

  /**
   * Updates various parts of state to reflect the newly selected section and its accompanying parent header node
   *
   * @param headerNode - The parent header (H1, H2 etc...) node of whatever section has been selected
   * @param currentNode - Node that has been selected to be set as the current from some previous logic
   * @param titleNode - Node representing the top-level, H1 node of the entire document
   * @param elementId - Numeric ID representing the ordinal position of the currentNode
   */
  const updateCurrentSection = useStableCallback(
    (headerNode, currentNode, titleNode, elementId) => {
      currentNode = headerNode.nextSibling
      let largerHeaderTags = getLargerHeaderTags(headerNode.tagName)
      while (currentNode) {
        // Skip text nodes
        if (currentNode.nodeType === 3) {
          currentNode = currentNode.nextSibling
          continue
        }
        if (largerHeaderTags.includes(currentNode.tagName.toLowerCase())) {
          break
        }

        currentNode = currentNode.nextSibling
      }

      if (largerHeaderTags.includes(headerNode.tagName.toLowerCase())) {
        setCurrentSectionHeader(headerNode.innerHTML)
      } else {
        setCurrentSectionHeader(null)
      }
    }
  )

  /**
   * Determine new scroll path to be synced with the LearnAdvisor pane. Old behavior
   * calculates this via scroll position of viewport, while the new behavior determines
   * this by the current selection within the document.
   */
  const calculateScrollPath = useStableCallback((selection) => {
    const newPath = getPathFromSelection(selection)
    setScrollPath(newPath)
  })

  // Update selected element from current selection.
  useEffect(() => {
    const updateSelectedElementBySelection = throttle(
      (e) => {
        let editor = document.getElementsByClassName("fr-element")[0]

        let selection = window.getSelection()
        const isSelectionInEditor =
          editor &&
          selection &&
          editor.contains(selection.anchorNode) &&
          editor.contains(selection.focusNode)

        if (!isSelectionInEditor) {
          return
        }

        const { headerNode, currentNode, currentHeaderId, elementId, found } =
          findElementBySelection(editor, selection.anchorNode)

        if (found) {
          const titleNode = editor.firstElementChild
          updateCurrentSection(headerNode, currentNode, titleNode, elementId)
          setCurrentSectionNumber(currentHeaderId)

          calculateScrollPath(selection)
        }
      },
      1000 /* 1s */,

      { trailing: false }
    )

    document.addEventListener(
      "selectionchange",
      updateSelectedElementBySelection
    )
    return () => {
      document.removeEventListener(
        "selectionchange",
        updateSelectedElementBySelection
      )
    }
  }, [calculateScrollPath, updateCurrentSection])

  const updateSize = useStableCallback(() => {})

  // Listen for resize events
  useEffect(() => {
    window.addEventListener("resize", updateSize)
    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [updateSize])

  /** Update the current section using the scroll position */
  const updateSelectedElementByScrollPosition = useStableCallback(() => {
    // Ignore if external scrolling is in progress
    if (scrollUpdateDisabledFlag.current > 0) {
      return
    }

    let editor = document.getElementsByClassName("fr-element")[0]
    if (!editor) {
      return
    }

    const { headerNode, currentNode, currentHeaderId, elementId, found } =
      findSelectedElementByScrollPosition(editor)

    if (found) {
      const titleNode = editor.firstChild
      updateCurrentSection(headerNode, currentNode, titleNode, elementId)
      setCurrentSectionNumber(currentHeaderId)
    }
  })

  /** State that contains React elements to display within React tree such
   * as modals. This is so that events can cause modals to be displayed
   * within the React tree.
   */
  const [reactElements, setReactElements] = useState([])

  /** Displays a react element. Call with a function that takes an onClose
   * argument and returns a react element. The onClose argument is a function
   * that can be called to close the modal.
   */
  const displayReactElement = useStableCallback((elementFn) => {
    setReactElements((elements) => {
      let element

      // Save scroll position
      const scrollPosition = document.querySelector(".fr-wrapper").scrollTop

      /**
       * Closes the react element and restores the scroll position
       */
      const closeReactElement = () => {
        setReactElements((elements) => {
          return elements.filter((e) => e !== element)
        })
        // Restore scroll position. This is done in a timeout to ensure that
        // the scroll position is restored. Froala jumps to the top of the editor
        // when the modal is closed after being clicked.
        setTimeout(() => {
          document.querySelector(".fr-wrapper").scrollTop = scrollPosition
        }, 10)
      }
      element = elementFn(() => closeReactElement())
      return [...elements, element]
    })
  })

  /** Ref that contains function to call when editor is being destroyed
   * Called with editor as only argument
   */
  const handleDestroyRef = useRef()

  // Handle initialized event from Froala editor
  const handleInitialized = useStableCallback((editor) => {
    setEditor(editor)
    setInitialized(true)

    const editorWrapperDiv =
      document.getElementsByClassName(EDITOR_DIV_SELECTOR)[0]
    const editorDiv = document.getElementsByClassName("fr-element")[0]

    // If read only ensure videos don't have class attributes set can cause issues playing videos
    if (readOnly) {
      let elements = [...document.getElementsByClassName("fr-video")]
      for (let elm of elements) {
        elm.setAttribute("class", "")
      }
    }

    /**
     * If we are redirected from a readonly editor to the full editor due to the user owning the course,
     * a second render + reinitialization of the editor occurs. Since the redirect occurs quickly, it
     * unmounts the initial readonly editor before this initialize function can grab the editor_div
     * element. If this occurs, we know that a second initialization will happen where it can correctly
     * grab the editor_div element. Therefore we can place a guard here as a safety for if a redirect
     * occurs.
     */
    if (editorWrapperDiv) {
      cleanupEditorHtml()

      setTimeout(() => {
        updateSize()
        updatePlaceholderWhenChanged()
      }, 300)

      /**
       * Disable browser default for ctrl+s
       * @param e keydown event
       */
      function handleDocumentKeydown(e) {
        if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
          e.preventDefault()
        }
      }

      /**
       * Save course on ctrl+s
       * @param e keydown event
       */
      function handleEditorDivKeydown(e) {
        if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
          saveCourse()
        }
      }

      editorWrapperDiv.addEventListener(
        "scroll",
        updateSelectedElementByScrollPosition
      )

      // Disable browser default for ctrl+s
      document.addEventListener("keydown", handleDocumentKeydown, false)

      // ctrl+s keyboard shortcut to save
      editorWrapperDiv.addEventListener("keydown", handleEditorDivKeydown)

      // Listen for mutations to trigger cleaning html
      const removeMutationObserver = observeMutations(
        editorWrapperDiv,
        cleanupEditorHtml,
        {
          childList: true,
          subtree: true,
        }
      )

      // NOTE: Any custom elements that are relevant during a rewrite content preview
      // should also be added to RewriteSmartTemplateCustomComponent.tsx
      const customElementConfigs = [
        enableHtmlQuestionStems
          ? questionCustomElement
          : questionCustomElementPlainStem,
        flipCardGridCustomElement,
        labeledImageCustomElement,
      ]
      customElementConfigs.push(smartTemplateControlCustomElement)
      if (enableTabsComponent) {
        customElementConfigs.push(tabsCustomElement)
      }
      if (enableCategoriesComponent) {
        customElementConfigs.push(categoriesCustomElement)
      }
      if (enableProcessComponent) {
        customElementConfigs.push(processCustomElement)
      }
      if (enableStyledListsComponent) {
        customElementConfigs.push(styledListCustomElement)
      }
      if (enableCalloutBoxes) {
        customElementConfigs.push(calloutBoxCustomElement)
      }

      // Display custom elements, mounting as a React element
      // so that context is passed through
      displayReactElement((onClose) => (
        <CustomElements
          key="custom-elements"
          editor={editor}
          editorDiv={editorDiv}
          configs={customElementConfigs}
          readOnly={readOnly}
        />
      ))

      // Display auto alt text popup
      displayReactElement((onClose) => (
        <AutoAltTextPopup key="auto-alt-text-popup" />
      ))

      /**
       * Append a tag at the bottom of the editor to be hydrated with the
       * BottomControl custom element component.
       */
      if (enableImportSources && !readOnly) {
        const bottomControlElement = document.createElement("div")
        editorWrapperDiv.appendChild(bottomControlElement)

        displayReactElement(() =>
          createPortal(
            <BottomControl editor={editor} editorDiv={editorWrapperDiv} />,
            bottomControlElement
          )
        )
      }

      // Display paragraph inserter
      if (enableParagraphInserter && !readOnly) {
        displayReactElement((onClose) => (
          <ParagraphInserter
            key="paragraph-inserter"
            editor={editor}
            editorWrapper={editorWrapperDiv}
          />
        ))
      }

      // Add quick insert
      let removeQuickInsert = null
      if (!readOnly) {
        removeQuickInsert = setupQuickInsert({
          editor,
          editorWrapper: editorWrapperDiv,
          /**
           * Inserts a smart template into the editor
           * @param template smart template type to insert
           * @param anchorElement element to insert template after
           */
          insertSmartTemplate: (template, anchorElement) => {
            const headerElement = findOwningSectionHeaderElement(
              document.getElementsByClassName("fr-element")[0],
              anchorElement
            )
            if (!headerElement) {
              return
            }
            addSmartTemplate(
              editorRef.current.editor,
              template,
              headerElement
            ).catch((e) => {
              setError({
                message: "Something went wrong creating your template.",
              })
            })
          },
          displayReactElement,
          enableFavourites,
          enableTabs: enableTabsComponent,
          enableCategories: enableCategoriesComponent,
          enableProcess: enableProcessComponent,
          enableImport: enableImportSources,
          enableTwoColumns: enableTwoColumnsComponent,
          enableStyledLists: enableStyledListsComponent,
          enableCalloutBoxes: enableCalloutBoxes,
        })
      }

      // Add drag and drop functionality to Froala
      let removeDragDrop = null
      if (!readOnly) {
        removeDragDrop = addDragDropToFroala(editor, editorWrapperDiv)
      }

      /**
       * Create function to remove listeners
       */
      handleDestroyRef.current = () => {
        editorWrapperDiv.removeEventListener(
          "scroll",
          updateSelectedElementByScrollPosition
        )

        document.removeEventListener("keydown", handleDocumentKeydown)

        editorWrapperDiv.removeEventListener("keydown", handleEditorDivKeydown)

        removeMutationObserver()

        if (removeDragDrop) {
          removeDragDrop()
        }

        if (removeQuickInsert) {
          removeQuickInsert()
        }

        // Self-destroy ref
        handleDestroyRef.current = undefined
      }
    }
  })

  /** Handle the editor's destroy event by calling the registered
   * destroy function, if present.
   */
  const handleDestroy = useStableCallback((editor) => {
    if (handleDestroyRef.current) {
      handleDestroyRef.current(editor)
    }
  })

  /** Clean the html (in the DOM tree) of the editor both initially and when
   * a mutation has taken place
   */
  const cleanupEditorHtml = useStableCallback(() => {
    if (readOnly) {
      return
    }

    // Get editor control
    const innerEditor =
      document.getElementsByClassName(EDITOR_DIV_SELECTOR)[0].firstElementChild

    // Ensure that all heading have ids
    for (const heading of innerEditor.children) {
      if (
        ["H1", "H2", "H3", "H4", "H5", "H6"].includes(heading.tagName) &&
        !heading.id
      ) {
        heading.id = uuidv4()
      }
    }

    // Ensure that all smart template controls are properly placed
    cleanupSmartTemplateControls(innerEditor)
  })

  const handleModelChange = useStableCallback((newHtml) => {
    if (readOnly) {
      return
    }
    // If actual change
    if (newHtml !== html) {
      // Immediately update html
      setHtml(newHtml)

      // If initialized, flag as changed and clean placeholders
      if (initialized) {
        setSaveState(SAVE_STATE.UNSAVED)
        updatePlaceholderWhenChanged()
      }
    }
  })

  /** Update document because of header list change */
  const updateDocument = useStableCallback((html, options = {}) => {
    const { triggerSave } = options
    editorRef.current.editor.html.set(html)
    editorRef.current.editor.undo.saveStep()
    if (triggerSave) {
      saveCourse()
    }
  })

  /**
   * Add a template at the appropriate location in the editor.
   */
  const handleAddTemplateSection = useStableCallback((templateType) => {
    const sectionHeading = findSectionHeadingByOffset(currentSectionNumber)

    addSmartTemplate(
      editorRef.current.editor,
      templateType,
      sectionHeading
    ).catch((e) => {
      setError({ message: "Something went wrong creating your template." })
    })
  })

  //Get Detections from server cache
  //to truly reset the detections, initiate a document save
  const loadDetections = useStableCallback(async () => {
    if (enableServerlessDetections) {
      return
    }

    remarks.refreshDetections()
    setDetectionsLoading(true)
    const detectionsResponse = await api.downloadJSON("detections", courseId)
    setDetections(reviewDetections(detectionsResponse.data, reviewDetections))
    setDetectionsLoading(false)
  })

  const updateDetectionReviewedStatus = useStableCallback(
    (detection_id, reviewed_status) => {
      let updatedDetections = detections.map((detection) => {
        if (detection.id === detection_id) {
          detection.reviewed = reviewed_status
          api.reviewDetection(courseId, detection.id, detection.reviewed)
        }
        return detection
      })

      setDetections(updatedDetections)
    }
  )

  const removeDetection = useStableCallback((detection) => {
    let newDetections = detections.filter((d) => d.id !== detection.id)
    setDetections(newDetections)
  })

  const applyTransformHandler = useStableCallback((detection) => {
    let el = document.createElement("html")
    el.innerHTML = html

    let node = findDetectionElement(
      detection,
      el.getElementsByTagName("body")[0]
    )
    if (node !== null) {
      if (detection.transformation.type === "text") {
        let success = false
        if (["ol", "ul"].includes(node.tagName.toLowerCase())) {
          for (let child of node.children) {
            success = runTextTransformation(child, detection)
            if (success) {
              break
            }
          }
        } else {
          success = runTextTransformation(node, detection)
        }
        if (!success) {
          console.log("Couldn't perform transformation")
          return
        }
      } else if (detection.transformation.type === "tag") {
        node.replaceWith(htmlToElement(detection.transformation.transformation))
      } else {
        console.log("invalid transformation type")
        return
      }

      editorRef.current.editor.html.set(el.innerHTML)
      editorRef.current.editor.undo.saveStep()
      highlightDetection(detection)

      removeDetection(detection)
    }
  })

  useSaveOnExit(
    { html, isDirty: saveState === SAVE_STATE.UNSAVED },
    { saveCourse }
  )

  return {
    applyTransformHandler,
    detections,
    detectionsLoading,
    displayReactElement,
    editorRef,
    handleDestroy,
    handleInitialized,
    handleModelChange,
    html,
    handleAddTemplateSection,
    reactElements,
    saveCourse,
    saveState,
    title,
    updateDetectionReviewedStatus,
    updateDocument,
    error,
    editor,
    version,
  }
}

export default useEditorState

/**
 * Mark detections as reviewed or not
 * @param detections array of detections
 * @param reviewed_detections object of detection ids and their reviewed status
 */
function reviewDetections(detections, reviewed_detections) {
  detections.forEach((detection) => {
    detection.reviewed = reviewed_detections[detection.id] || false
  })
  return detections
}

const PLACEHOLDER_ARRAY = [
  "COURSE TITLE PLACEHOLDER",
  "[Use an action word to give your course a title that describes the task and what the audience will learn.]",
  "[LEARNING OBJECTIVES PLACEHOLDER: This section provides the list of knowledge or skills that the learner should have after they have completed the course. To help create the objectives, LEAi uses the headings from the course sections. Typically, each objective should start with an action verb ending in 'ing' such as: Building, Identifying, Using, or Creating; similar to the task-based headings for the sections of your course.]",
  "SECTION TITLE PLACEHOLDER",
  "[Use an action word to give your section a title that describes the task and what the audience will learn.]",
  "[IMAGE PLACEHOLDER: Use an image to support visual learners and increase engagement.]",
  "[VIDEO PLACEHOLDER: Use a video to enhance your course, supporting both visual and auditory learners.]",
  "[Demonstrations show the learner how to combine multiple concepts or steps covered in the instructions text above using a practical business scenario that achieves a desired outcome or result. You can present this in a video format where the subject matter expert or course developer records the demonstration and inserts the video into this course. Alternatively, you can write a list of steps for the learner to follow either during a live/virtual classroom or role-play practice.]",
  "[Measure a learner's progress and retention of the topics in the section through test questions.]",
  "[Exercises enable the learner to get practical experience on the topics they have learned. It is best practice to combine multiple concepts or steps covered in the section and / or demonstration, to accomplish a larger task using a practical business scenario that achieves a desired outcome or result. Following the practical business scenario set up, present the learner with a list of steps for them to complete independently. The concepts and tasks covered are typically similar to the demonstration(s) in the section to reinforce the learning, but with the added real-world scenario included.]",
]

/** Remove placeholder class when placeholder text changes  */
function updatePlaceholderWhenChanged() {
  let editor = document.getElementsByClassName("fr-element")[0]
  if (!editor) {
    return
  }
  let placeholders = editor.querySelectorAll(".placeholder")
  placeholders.forEach((placeholder) => {
    if (!placeholder.classList.contains("keep-placeholder")) {
      let placeholderText = placeholder.textContent

      if (!PLACEHOLDER_ARRAY.includes(placeholderText)) {
        placeholder.classList.remove("placeholder")
      }
    }
  })
}

/** Get list of header tags that are larger or equal to the passed in header tag.
 * For example getLargerHeaderTags("h2") would return ["h1", "h2"]
 * @param header_tag the header tag to get larger tags for
 */
function getLargerHeaderTags(header_tag) {
  const header_tags = ["h1", "h2", "h3", "h4", "h5", "h6"]
  return header_tags.slice(0, header_tags.indexOf(header_tag.toLowerCase()) + 1)
}

/**
 * Run the text transformation on the passed in node
 * @param node the node to run the transformation on
 * @param detection the detection object
 */
function runTextTransformation(node, detection) {
  let new_html = node.innerHTML.replace(
    detection.text,
    detection.transformation.transformation
  )
  if (new_html === node.innerHTML) {
    // Couldn't perform normal transformation, need to look in styled tags for match
    let new_text = node.textContent
    new_text = new_text.replace(
      detection.text,
      detection.transformation.transformation
    )
    if (new_text === node.textContent) {
      return false
    } else {
      node.innerHTML = new_text
    }
  } else {
    node.innerHTML = new_html
  }
  return true
}

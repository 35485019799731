import {
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

/**
 * Displays a Card containing relevant Help Links.
 *
 * @param props - Contents to display within the section.
 * @returns {JSX.Element}
 * @class
 */
const HelpSection = (props) => {
  const { title, description, links } = props

  return (
    <Card
      sx={{
        height: "100%",
        /**
         * Set background to be light colour theme
         * @param theme - MUI theme
         */
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
      elevation={0}
    >
      <CardContent>
        <Typography align="center" variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography align="center" variant="body1" gutterBottom>
          {description}
        </Typography>
        {links && (
          <>
            <Divider />
            <List>
              {links.map((item) => {
                const { label, href, divider } = item
                return (
                  <>
                    {divider ? (
                      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                    ) : (
                      <ListItem
                        sx={{
                          display: "flex",
                          padding: 0.5,
                          justifyContent: "center",
                        }}
                      >
                        <Link
                          component={RouterLink}
                          target="_blank"
                          to={{
                            pathname: href,
                          }}
                          underline="none"
                          sx={{
                            textAlign: "center",
                            /**
                             * Style link according to theme
                             * @param theme - MUI theme
                             */
                            color: (theme) => theme.palette.primary.dark,
                          }}
                        >
                          {label}
                        </Link>
                      </ListItem>
                    )}
                  </>
                )
              })}
            </List>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default HelpSection

import React from "react"
import { UploadItem } from "../../utilities/fileUpload"
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material"
import { ChevronRight, KeyboardArrowDown } from "@mui/icons-material"
import DragHandleIcon from "@mui/icons-material/DragHandle"

import useUploadMimeTypes from "../../hooks/useUploadMimeTypes"
import { audioMimeTypes } from "../../config"
import { useFlag } from "../../utilities/feature-management"
import useFileContainerHeadings from "./hooks/useFileContainerHeadings"

interface LoadingBoxProps {
  width: number
}

/**
 * Render skeleton when loading items of a given width
 * @param props - Component props
 * @param props.width - MUI width of skeleton to render
 */
const LoadingBox: React.FC<LoadingBoxProps> = ({ width }) => {
  return (
    <FormControlLabel
      sx={{
        pl: 10,
      }}
      onClick={(event) => {
        event.stopPropagation()
      }}
      control={<Checkbox size="small" checked disabled={true} />}
      label={<Skeleton variant="text" sx={{ width, height: 34 }} />}
    />
  )
}

/**
 * Prevent click event from propagating and affecting elements underneath
 * @param event - Click event
 */
const stopClickPropagation = (event: React.MouseEvent) => {
  event.stopPropagation()
}

interface FileContainerProps {
  uploadItem: UploadItem
  /** Called when upload items are mutated directly */
  onUpdateUploadItem: () => void

  fileName: string
  setError: (error: any) => void
  isDragging: boolean
  allowDrag: boolean
  onMoveUp: (fileName: string) => void
  onMoveDown: (fileName: string) => void
  onGetHeadings: (uploadItem: UploadItem, options: any) => any[]
  last: boolean
}

/**
 * Display and controller for a file in the file upload list.
 * @param props - params
 */
const FileContainer: React.FC<FileContainerProps> = (props) => {
  const {
    uploadItem,
    onUpdateUploadItem,
    fileName,
    setError,
    isDragging,
    allowDrag,
    onMoveUp,
    onMoveDown,
    onGetHeadings,
    last,
  } = props

  const { mimeTypeAllowsHeaders } = useUploadMimeTypes()
  const { onToggleHeadings, loadingHeadings, onCheckHeading } =
    useFileContainerHeadings(
      uploadItem,
      onUpdateUploadItem,
      setError,
      onGetHeadings,
      isDragging
    )

  const { headings, headingsOpen } = uploadItem

  const transcriptOptionsEnabled = useFlag(
    "rollout-word-for-word-transcriptions"
  )

  /**
   * Select/unselect a file.
   */
  const onCheck = () => {
    if (!isDragging) {
      uploadItem.selected = !uploadItem.selected

      // When a file is selected/deselected, do the same for all of its headings
      if (uploadItem.headings != null) {
        for (const heading of uploadItem.headings) {
          heading.selected = uploadItem.selected
        }
      }

      onUpdateUploadItem()
    }
  }

  /**
   * Allow files to be reordered using the arrow keys
   * @param event - key event
   */
  const onDragButtonKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowUp") {
      event.preventDefault()
      onMoveUp(fileName)
    }
    if (event.key === "ArrowDown") {
      event.preventDefault()
      onMoveDown(fileName)
    }
  }

  /** Returns true if the upload item can't have headings */
  const disableHeadings = () => {
    return (
      uploadItem.source === "web" || !mimeTypeAllowsHeaders(uploadItem.type)
    )
  }

  /**
   * Change the transcribe mode for a file
   * @param uploadItem - the file
   * @param value - the new transcribe mode
   */
  const onTranscribeModeChange = (
    uploadItem: UploadItem,
    value: "rewrite" | "headings" | "raw"
  ) => {
    uploadItem.transcribeMode = value
    onUpdateUploadItem()
  }

  return (
    <Box style={last ? {} : { borderBottom: "1px solid gray" }}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <IconButton
            aria-label="open"
            onClick={onToggleHeadings}
            disabled={disableHeadings()}
          >
            {headingsOpen ? <KeyboardArrowDown /> : <ChevronRight />}
          </IconButton>
          <FormControlLabel
            onClick={stopClickPropagation}
            control={
              <Checkbox
                checked={uploadItem.selected == null || uploadItem.selected}
                onChange={onCheck}
                onClick={stopClickPropagation}
              />
            }
            label={fileName}
          />
        </Box>
        <Box>
          {transcriptOptionsEnabled && audioMimeTypes[uploadItem.type] && (
            <Select
              variant="standard"
              value={uploadItem.transcribeMode}
              onChange={(event) =>
                onTranscribeModeChange(uploadItem, event.target.value as any)
              }
              size="small"
            >
              <MenuItem value={"rewrite"}>Rewrite as Course</MenuItem>
              <MenuItem value={"headings"}>Transcript with Headings</MenuItem>
              <MenuItem value={"raw"}>Plain Transcript</MenuItem>
            </Select>
          )}
          {allowDrag && (
            <IconButton
              aria-label="move"
              sx={{ cursor: "grab" }}
              onKeyDown={onDragButtonKeyDown}
              onClick={stopClickPropagation}
            >
              <DragHandleIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box>
        <Box width="100%">
          <Collapse in={headingsOpen}>
            <FormGroup>
              {loadingHeadings
                ? [250, 300, 300].map((width, index) => (
                    <LoadingBox key={index} width={width} />
                  ))
                : headings != null &&
                  headings.map((heading, index) => (
                    <FormControlLabel
                      key={index}
                      onClick={stopClickPropagation}
                      control={
                        <Checkbox
                          size="small"
                          checked={heading.selected}
                          onChange={() => onCheckHeading(uploadItem, heading)}
                          onClick={stopClickPropagation}
                        />
                      }
                      label={heading.label}
                      sx={{
                        pl: 10,
                        width: "100%",
                        "& .MuiFormControlLabel-label": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    />
                  ))}
            </FormGroup>
          </Collapse>
        </Box>
      </Box>
    </Box>
  )
}

export default FileContainer

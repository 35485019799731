import { css, keyframes, styled } from "@mui/material/styles"
import isPropValid from "@emotion/is-prop-valid"
import { Box, Button, Typography } from "@mui/material"
import React, { useCallback, useState } from "react"
import SelectDocumentDialog from "../../components/screens/SelectDocumentDialog"
import SelectUrlDialog from "../../components/screens/SelectUrlDialog"
import useCourseDropzone from "../../hooks/useCourseDropzone"
import useGoogleDrivePicker from "../../hooks/useGoogleDrivePicker"
import useUploadMimeTypes from "../../hooks/useUploadMimeTypes"
import { Add } from "@mui/icons-material"
import FileUploadList from "./FileUploadList"
import { useFlag } from "../../utilities/feature-management"
import SelectExistingCourseSourceDialog from "./SelectExistingCourseSourceDialog"

/**
 * Style to apply to dropzone when dragging files overtop of it
 *
 * @param theme
 * @returns {Keyframes}
 */
const pulseKeyframe = (theme) => keyframes`
  0% {
    background: ${theme.palette.grey[50]};
  }
  100% {
    background: ${theme.palette.grey[300]};
  }
`

const CourseDropzoneRoot = styled("div", {
  name: "CourseDropzone",
  slot: "Root",
  shouldForwardProp: isPropValid,
})(
  ({ isDragActive, theme }) =>
    isDragActive &&
    css`
      animation: ${pulseKeyframe(theme)} 0.8s linear 0s infinite alternate;
    `
)

/**
 * Component for dragging and dropping in files for upload, or alternatively using the "Add" button
 * to upload them. Also displays all currently uploaded files as sortable items in the FileUploadList
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CourseDropzone = (props) => {
  const [chooseDialogOpen, setChooseDialogOpen] = useState(false)
  const [selectUrlDialogOpen, setSelectUrlDialogOpen] = useState(false)
  const [selectCourseDialogOpen, setSelectCourseDialogOpen] = useState(false)

  const {
    fileNames,
    uploadItems,
    setUploadItems,
    setFileNames,
    setError,
    onAccepted,
    onGetHeadings,
    courseID,
    onInsertHTML = () => null,
  } = props

  // If a course ID was provided, we are viewing the dropzone in the editor
  const inEditor = courseID != null

  const { getRootProps, getInputProps, open, isDragActive } = useCourseDropzone(
    {
      onAccepted,
    }
  )

  const { openDrivePicker } = useGoogleDrivePicker({
    onAccepted,
  })

  const onSelect = useCallback(
    (action) => {
      setChooseDialogOpen(false)
      if (action === "skip") {
        onAccepted([])
      } else if (action === "computer") {
        open()
      } else if (action === "google-drive") {
        openDrivePicker()
      } else if (action === "web") {
        setSelectUrlDialogOpen(true)
      } else if (action === "leai") {
        setSelectCourseDialogOpen(true)
      }
    },
    [onAccepted, open, openDrivePicker]
  )

  const { allowedFileExtensions } = useUploadMimeTypes()

  const useGoogleDriveUpload = useFlag("rollout-upload-from-google")
  const useWebImport = useFlag("rollout-import-from-web")

  const useExistingCourseImport =
    useFlag("rollout-import-html-from-existing-courses") && inEditor

  return (
    <>
      <CourseDropzoneRoot
        {...getRootProps({ isDragActive })}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "2px dashed",
          borderColor: "neutral.main",
          color: "text.secondary",
          borderRadius: 1,
          py: 4,
          px: 2,
          gap: 4,
          marginBottom: 4,
        }}
      >
        <input data-cy="course-dropzone" {...getInputProps()} />
        <Box textAlign="center">
          <Typography fontWeight={500} variant="body1" gutterBottom>
            {!inEditor
              ? "Create a course by dropping a document(s), folder or zip file here."
              : "Add to your course by dropping a document(s), folder or zip file here."}
          </Typography>
          <Typography variant="body2">
            {`(${allowedFileExtensions().join(", ")})`}
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<Add />}
          color="primary"
          onClick={(e) => {
            e.preventDefault()
            if (useGoogleDriveUpload || useWebImport) {
              setChooseDialogOpen(true)
            } else {
              open()
            }
          }}
        >
          Upload
        </Button>
        {uploadItems.length > 0 && (
          <FileUploadList
            fileNames={fileNames}
            setFileNames={setFileNames}
            uploadItems={uploadItems}
            setUploadItems={setUploadItems}
            setError={setError}
            onGetHeadings={onGetHeadings}
          />
        )}
      </CourseDropzoneRoot>
      <SelectDocumentDialog
        open={chooseDialogOpen}
        onSelect={onSelect}
        useWebImport={useWebImport}
        useGoogleDriveUpload={useGoogleDriveUpload}
        useExistingCourseImport={useExistingCourseImport}
      />
      <SelectUrlDialog
        onAccepted={onAccepted}
        open={selectUrlDialogOpen}
        setOpen={setSelectUrlDialogOpen}
      />
      {useExistingCourseImport && (
        <SelectExistingCourseSourceDialog
          currentCourseID={courseID}
          onInsertHTML={onInsertHTML}
          open={selectCourseDialogOpen}
          setOpen={setSelectCourseDialogOpen}
        />
      )}
    </>
  )
}
export default CourseDropzone

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import detectionsConfig from "../../../config/detections"
import useRemarksFilter from "../../../hooks/useRemarksFilter"

const TYPE_FILTER_OPTIONS = [
  { value: "__all", label: "All" },
  { value: "comment", label: "Comment" },
  ...Object.entries(detectionsConfig.detections).map(([key, detection]) => ({
    value: `detection/${key}`,
    label: detection.title,
  })),
]

/**
 * Control for filtering remarks by type.
 */
const RemarksTypeFilter = () => {
  const { type, filterByType } = useRemarksFilter()
  const onChange = (event) => filterByType(event.target.value)

  return (
    <FormControl variant="standard">
      <InputLabel>Type</InputLabel>
      <Select value={type} label="Filter" onChange={onChange}>
        {TYPE_FILTER_OPTIONS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RemarksTypeFilter

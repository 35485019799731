import { useCallback } from "react"
import useDrivePicker from "react-google-drive-picker"
import config from "../config"
import { UploadItem, UploadFile } from "../utilities/fileUpload"
import useUploadMimeTypes from "../hooks/useUploadMimeTypes"

// mime types used in this file
const mimeType = {
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  google_slides: "application/vnd.google-apps.presentation",
  google_doc: "application/vnd.google-apps.document",
}

/**
 * Convert list of selections from the Google Drive picker
 * into a list of UploadItems.
 * UploadItems will be used in the create course UI
 * to display, select and upload to lex API
 *
 * @param {object[]} itemList Google drive selections.
 * @return {UploadItem[]}
 */
const processSelectedItems = (itemList) => {
  const uploadItems = []

  for (const [index, selectedItem] of itemList.entries()) {
    // Figure out what to convert to, .pptx or .docx
    let exportType = null
    let fileName = selectedItem.name

    if (selectedItem.mimeType === mimeType.google_slides) {
      // Only google slides should be converted to pptx
      exportType = mimeType.pptx
      fileName = fileName + ".pptx"
    } else if (selectedItem.mimeType === mimeType.google_doc) {
      // Only google docs should be converted to docx
      exportType = mimeType.docx
      fileName = fileName + ".docx"
    }

    // Get file info of selected drive file
    const fileInfo = {
      id: selectedItem.id, // used later to download from drive
      name: fileName,
      type: selectedItem.mimeType,
      exportType: exportType,
      path: fileName,
    }

    // Create an UploadItem with one UploadFile
    const uploadFile = new UploadFile(fileInfo, index, null)
    const uploadItem = new UploadItem(
      [uploadFile],
      fileName,
      selectedItem.mimeType,
      "google-drive"
    )

    // Add UploadItem to list
    uploadItems.push(uploadItem)
  }

  return uploadItems
}

const useGoogleDrivePicker = (props) => {
  /*
   * Google provides a picker for drive files, the react-google-drive-picker adds
   * auth checking before opening the picker
   */
  const { onAccepted } = props
  const { allowedMimeTypes } = useUploadMimeTypes()
  const [openPicker] = useDrivePicker()

  const openDrivePicker = useCallback(() => {
    const apiCreds = config.googleDriveApiCredentials

    openPicker({
      clientId: apiCreds.clientId,
      developerKey: apiCreds.apiKey,
      viewMimeTypes: Object.keys(allowedMimeTypes()).join(","),
      setIncludeFolders: true,
      supportDrives: true,
      multiselect: true,

      callbackFunction: (data) => {
        if (data.action === "picked") {
          if (data.docs.length > 0) {
            const uploadItems = processSelectedItems(data.docs)
            onAccepted(uploadItems)
          }
        }
      },
    })
  }, [onAccepted, openPicker])

  return { openDrivePicker }
}

export default useGoogleDrivePicker

import React, { useState, useEffect, useCallback, useMemo } from "react"
import { Typography, Box, CircularProgress, Alert } from "@mui/material"
import { Prompt } from "react-router-dom"
import PageLayout from "../../components/layouts/PageLayout"
import { TranslationRuleData } from "./TranslationRuleData"
import { TranslationRulesTable } from "./TranslationRulesTable"
import { loadTranslationRules, saveTranslationRules } from "../../api"
import { useAuth } from "../../contexts/authorization"
import { debounce } from "lodash"
import { useStableCallback } from "../../hooks/useStableCallback"

/**
 * Screen for displaying and editing translation rules.
 */
export const TranslationRulesScreen = () => {
  const {
    user: { id: tenantId },
  } = useAuth() as any

  const [translationRules, setTranslationRules] =
    useState<TranslationRuleData | null>(null)
  const [originalRules, setOriginalRules] =
    useState<TranslationRuleData | null>(null)

  const hasChanges = useMemo(
    () => JSON.stringify(translationRules) !== JSON.stringify(originalRules),
    [translationRules, originalRules]
  )

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const handleSave = useStableCallback(async () => {
    if (!hasChanges || !translationRules) return

    setError(null)
    try {
      await saveTranslationRules(tenantId, translationRules)
      setOriginalRules(translationRules)
    } catch (error) {
      console.error("Failed to save translations:", error)
      setError("Failed to save translations. Please check your connection.")
    }
  })

  /**
   * Debounce the save function to prevent excessive calls to the server.
   */
  const debouncedSave = useMemo(
    () => debounce(handleSave, 1000, { leading: false, trailing: true }),
    [handleSave]
  )

  const handleDataChange = useCallback(
    (newData: TranslationRuleData) => {
      setTranslationRules(newData)
      debouncedSave()
    },
    [debouncedSave]
  )

  useEffect(() => {
    /**
     * Fetch the translation rules for the current tenant.
     */
    const fetchRules = async () => {
      try {
        setError(null)
        const rules = await loadTranslationRules(tenantId)
        setTranslationRules(rules)
        setOriginalRules(rules)
      } catch (error) {
        console.error("Failed to fetch translation rules:", error)
        setError("Failed to load translation rules. Please try again later.")
      } finally {
        setIsLoading(false)
      }
    }

    fetchRules()
  }, [tenantId])

  if (isLoading) {
    return (
      <PageLayout fixed maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </PageLayout>
    )
  }

  return (
    <PageLayout
      fixed
      maxWidth="lg"
      breadcrumbs={[
        { label: "Courses", href: "/" },
        { label: "Tools", href: "/tools" },
        { label: "User-Defined Translations" },
      ]}
    >
      <Prompt
        when={hasChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Box>
        <Typography gutterBottom variant="h4">
          User-Defined Translations
        </Typography>
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {translationRules && (
          <>
            <Typography variant="body1" paragraph>
              Here you can define common translations that you would like to
              automatically apply as part of any translated export. This might
              include proper product or business names, or just preferred
              language.
            </Typography>
            <TranslationRulesTable
              data={translationRules}
              onDataChange={handleDataChange}
            />
          </>
        )}
      </Box>
    </PageLayout>
  )
}

import { concat, isFunction, reduce, update } from "lodash/fp"
import { useEffect, useRef, useState } from "react"
import { getToken } from "../../../api"
import { useFeatureDecisions } from "../../../contexts/features"
import editorConfig from "./Editor.config"
import moveCursorTo from "../../../features/outline/utils/moveCursorTo"
import isHeading from "../../../features/outline/utils/isHeading"
import { Hub } from "aws-amplify"
import makePasteHandlers from "./makePasteHandlers"

/**
 * Resolve configuration for a FroalaEditor.
 *
 * @param {object} options - Config options object
 * @param {func} options.onInitialize - Called when the editor has finished initializing its interface.
 * @param {func} options.onDestroy - Called when the editor is being destroyed
 * @returns {Promise<object>}
 */
const resolveConfig = async (options) => {
  const { onInitialize, onDestroy, readOnly, updateDocument, ...config } =
    options

  // Fetch authentication token so the editor can upload document assets\
  // (images, videos, etc) to our servers.
  // TODO: Switch to cookie based authentication.
  const token = !readOnly ? await getToken() : null

  let hubListenerCancel

  return {
    ...editorConfig,
    ...config,
    events: {
      ...editorConfig.events,
      initialized: function () {
        hubListenerCancel = Hub.listen("auth", async ({ payload }) => {
          // If a token refresh event has occurred, re-obtain the new token and update the authorization header with it
          if (payload.event === "tokenRefresh") {
            // The payload object from AWS Amplify's Hub API doesn't even contain the new token, so we have to manually fetch it
            const newToken = !readOnly ? await getToken() : null

            if (newToken) {
              console.log("Token refreshed, updating authorization header.")
              console.log(newToken)
              // Update the authentication header
              this.opts.requestHeaders = {
                ...this.opts.requestHeaders,
                "X-Cognito-Authorization": `Bearer ${newToken}`,
              }
            }
          }
        })

        if (isFunction(onInitialize)) {
          onInitialize(this)
          if (readOnly) {
            this.edit.off()
            this.toolbar.hide()
          }
        }

        this.events.on(
          "keydown",
          function (keypressEvent) {
            /**
             * This handler is for the specific use case where a carriage return is pressed
             * at the beginning of a Heading element, so that a Normal text element is inserted
             * above the heading, rather than another heading of the same level.
             */
            if (keypressEvent.key === "Enter") {
              if (isHeading(this.selection.element())) {
                // Check if the cursor is at the beginning of a line
                const range = this.selection.ranges(0)
                const startOfLine = range.endOffset === 0

                if (startOfLine) {
                  // Prevent default carriage return from occurring
                  keypressEvent.preventDefault()
                  keypressEvent.stopPropagation()

                  // Manually insert carriage return with normal style text
                  this.html.insert("<p><br/></p>", false)

                  // Place the cursor at the beginning of heading that was shifted down
                  const shiftedHeading =
                    this.selection.element().nextElementSibling
                  if (shiftedHeading) {
                    moveCursorTo(shiftedHeading)
                  }

                  // Save into undo stack the changes.
                  this.undo.saveStep()

                  // Returning false prevents default key behavior from occuring
                  return false
                }
              }
            }
          },
          true
        )
      },

      "popups.show.table.edit": function () {
        const node = this.selection.get().anchorNode

        if (node) {
          // If the selected table is considered a Two Column element, hide the popup (per LD-1922)
          const table = node.parentNode.closest("table")

          if (table.dataset.component === "borderless-table") {
            this.popups.hide("table.edit")
          }
        }
      },
      destroy: function () {
        if (hubListenerCancel && isFunction(hubListenerCancel)) {
          hubListenerCancel()
        }
        if (isFunction(onDestroy)) {
          onDestroy(this)
        }
      },
      ...makePasteHandlers(),
    },
    requestHeaders: {
      ...editorConfig.requestHeaders,
      ...(!readOnly && {
        "X-Cognito-Authorization": `Bearer ${token}`,
      }),
    },
  }
}

/**
 * Resolve configuration for a FroalaEditor.
 *
 * @param {object} options - Configuration options object
 * @param {func} options.onInitialize - Called when the editor has finished initializing its interface.
 * @param {func} options.onDestroy - Called when the editor is being destroyed
 * @returns {object}
 */
const useEditorConfig = (options) => {
  const optionsRef = useRef(options)
  const [config, setConfig] = useState(null)
  const decisions = useFeatureDecisions()

  useEffect(() => {
    resolveConfig(optionsRef.current)
      .then((config) =>
        applyDecorators(
          [
            decisions.includeEditorChangeTracking() && withTrackChanges,
            withImageEditor,
          ],
          config
        )
      )
      .then(setConfig)
  }, [setConfig, decisions])

  return config
}

export default useEditorConfig

/**
 * Update a Froala configuration to include the change tracking.
 *
 * @param {object} config An editor configuration.
 * @returns {object} The modified configuration.
 */
const withTrackChanges = (config) =>
  update(
    ["toolbarButtons", "moreMisc", "buttons"],
    (buttons) => concat(buttons, ["trackChanges"]),
    config
  )

/**
 * Enable the TUI image editor plugin in the given Froala configuration.
 *
 * @param {object} config A Froala config.
 * @returns {object} The updated Froala config.
 */
const withImageEditor = (config) =>
  update(["pluginsEnabled"], (coll) => concat(coll, ["imageTUI"]), config)

/**
 * Apply a set of decorators to a given Froala configuration.
 *
 * @param {function[]} decorators A set of decorators to apply.
 * @param {object} config A Froala config.
 * @returns {object} The updated Froala config.
 */
const applyDecorators = (decorators, config) =>
  reduce(
    (config, decorate) => decorate(config),
    config,
    decorators.filter(Boolean)
  )

import axiosInstance from "../../../api/axios"

/**
 * Send a list of modified branding parameters to the server to be saved.
 *
 * @param arg - Object containing changes and recent fonts parameters
 */
const updateBranding = (arg) => {
  const { changes, recentFonts } = arg
  const data = new FormData()

  for (const property in changes) {
    const value = changes[property]
    /**
     * If we are applying changes to one of the font settings with a Google Font
     * instead of user-upload, we want to serialize the url and name properties
     * to send to the server.
     *
     * If we are providing a File object or some other property, we can simply
     * append it to the form data.
     */
    if (
      (property === "heading" || property === "body") &&
      !(value instanceof File)
    ) {
      data.append(property, JSON.stringify(value))
    } else {
      data.append(property, value)
    }
  }

  data.append("recentFonts", JSON.stringify(recentFonts))

  return axiosInstance.post(`/branding`, data).then((response) => response.data)
}

export default updateBranding

import React from "react"
import {
  Box,
  LinearProgress,
  Typography,
  Grid,
  Divider,
  Paper,
  useTheme,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { parseQueryString } from "../../utilities/queryString"
import PageLayout from "../layouts/PageLayout"
import Diff from "../atoms/Diff"
import { useAuditHistoryDetails } from "../../hooks/useAuditHistory"
import {
  AccessTime,
  AccountCircle,
  AirlineStops,
  ArrowDownward,
  ArrowForward,
  Description,
  Email,
  PostAdd,
  Storage,
  Warning,
} from "@mui/icons-material"
import {
  formatDate,
  isoStringToRelativeTime,
} from "../../utilities/transformers"

/**
 * Dialog styles
 */
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10em",
  },
  icon: {
    float: "left",
    marginRight: "1em",
  },
  label: {
    display: "inline-block",
    width: "8em",
    clear: "left",
    marginRight: "1em",
  },
  value: {
    display: "inline-block",
  },
  gridItem: {
    height: "100%",
  },
  diff: {
    height: "100%",
    padding: theme.spacing(2),
  },
}))

// If HTML diff is empty or just surround tags with no content, return false
const hasContent = (item) => {
  let before = item.before_html.replace(/<[^>]*>?/gm, "")
  let after = item.after_html.replace(/<[^>]*>?/gm, "")
  return before || after
}

const HTMLDiff = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const { htmlPreviews } = props
  return (
    <Box>
      <Grid container>
        <Grid item md={6.25} sx={{ display: { xs: "none", md: "block" } }}>
          <Typography marginBottom={2} fontWeight="bold">
            Before:
          </Typography>
        </Grid>
        <Grid item md={5.75} sx={{ display: { xs: "none", md: "block" } }}>
          <Typography marginBottom={2} fontWeight="bold">
            After:
          </Typography>
        </Grid>
        {htmlPreviews
          ? htmlPreviews.filter(hasContent).map((item, index) => (
              <Grid item key={index} sx={{ width: "100%" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      marginBottom={1}
                      sx={{ display: { xs: "block", md: "none" } }}
                      fontWeight="bold"
                    >
                      Edit {index + 1}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5.75}>
                    <Paper className={classes.diff} variant="outlined" square>
                      <Diff changes={item.before_html} />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={0.5}>
                    <Box
                      height="100%"
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                      paddingTop={theme.spacing(1)}
                      paddingBottom={theme.spacing(1)}
                    >
                      <ArrowForward
                        sx={{ display: { xs: "none", md: "block" } }}
                      />
                      <ArrowDownward
                        sx={{ display: { xs: "block", md: "none" } }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5.75}
                    sx={{ marginBottom: { xs: theme.spacing(10), md: 0 } }}
                  >
                    <Paper className={classes.diff} variant="outlined" square>
                      <Diff changes={item.after_html} />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            ))
          : ""}
      </Grid>
    </Box>
  )
}

const SourceFiles = ({ sources }) => {
  const styles = useStyles()

  return (
    sources != null &&
    sources.length !== 0 && (
      <Grid container marginBottom="1em">
        <Grid item>
          <Typography gutterBottom variant="body1" className={styles.label}>
            <span className={styles.icon}>
              <PostAdd />
            </span>
            <strong>Sources:</strong>
          </Typography>
        </Grid>
        <Grid item>
          {sources.map((source, index) => (
            <Box key={index}>
              <Typography className={styles.value} variant="body1">
                {source}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    )
  )
}

/**
 * Show a list of warnings found while uploading a file, ie. "No audio found"
 * @param root0 - params
 * @param root0.warnings - list of warnings {filename, message}
 */
const SourceWarnings = ({ warnings }) => {
  const styles = useStyles()

  return (
    warnings != null &&
    warnings.length !== 0 && (
      <Grid container marginBottom="1em">
        <Grid item>
          <Typography gutterBottom variant="body1" className={styles.label}>
            <span className={styles.icon}>
              <Warning />
            </span>
            <strong>Warnings:</strong>
          </Typography>
        </Grid>
        <Grid item>
          {warnings.map((warning, index) => (
            <Box key={index}>
              <Typography
                className={styles.value}
                variant="body1"
              >{`${warning.message} (${warning.filename})`}</Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    )
  )
}

/**
 * Show a label value pair with an icon
 * @param root0 - params
 * @param root0.label - the lable
 * @param root0.value - the value
 * @param root0.icon - the icon
 */
const LabelValuePair = ({ label, value, icon }) => {
  const styles = useStyles()

  return (
    value != null && (
      <Box marginBottom="1em">
        <Typography gutterBottom variant="body1" className={styles.label}>
          {icon && <span className={styles.icon}>{icon}</span>}
          <strong>{label}:</strong>
        </Typography>
        <Typography className={styles.value} variant="body1">
          {value}
        </Typography>
      </Box>
    )
  )
}

/**
 * Render Engine for Audit Details
 *
 * Each Audit Action type has it's own rendering for it's data
 * @param {} props
 * @returns
 */
const AuditDataDetail = (props) => {
  const { actionType, details } = props

  switch (actionType) {
    case "login":
    case "logout":
      return (
        <>
          <LabelValuePair label="IP Address" value={details.ipAddress} />
          <LabelValuePair label="User Agent" value={details.userAgent} />
        </>
      )
    case "course_created":
    case "course_duplicated":
    case "course_shared":
    case "insert_course_content":
      return (
        <>
          <SourceFiles sources={details.sources} />
          <SourceWarnings warnings={details.warnings} />
        </>
      )
    case "course_deleted":
    case "course_exported":
    case "course_microlearning_condense":
    case "course_microlearning_concept":
    case "course_microlearning_chunk":
      return (
        <>
          <LabelValuePair label="Source File(s)" value={details.sourceFile} />
        </>
      )
    case "course_saved":
      return (
        <>
          <LabelValuePair label="Source File(s)" value={details.sourceFile} />
          <LabelValuePair label="IP Address" value={details.ipAddress} />
          <LabelValuePair label="User Agent" value={details.userAgent} />
          <HTMLDiff htmlPreviews={details.htmlPreviews} />
        </>
      )
    case "course_transferred":
      return (
        <LabelValuePair label="Transferred To" value={details.transferred_to} />
      )
    case "course_published":
      return (
        <>
          <LabelValuePair label="Published Version" value={details.version} />
          <LabelValuePair label="Learner Link" value={details.publicUrl} />
        </>
      )
    case "path_created":
    case "path_deleted":
    case "path_add_courses":
    case "path_remove_courses":
    case "path_reorder_courses":
      return (
        <>
          <LabelValuePair
            label="Learning Path Title"
            value={details.path_title}
          />
          <LabelValuePair label="Learning Path ID" value={details.path_id} />
        </>
      )
    default:
      return <>{actionType}</>
  }
}

function HistoryDetailsScreen({ location }) {
  const { historyLog, isLoading, dataFilterAction } = useAuditHistoryDetails(
    parseQueryString(window.location.search)
  )

  const selectedAction = dataFilterAction.find(
    (action) => action.id === historyLog.action_type
  )

  const historyURL =
    location.prevURL != null ? location.prevURL : "?page=0&limit=20&back=tools"

  const backBreadcrumb = () => {
    if (location.breadcrumbs != null) {
      return location.breadcrumbs
    } else {
      return [
        {
          label: "Tools",
          href: "/tools",
        },
      ]
    }
  }

  return (
    <>
      <PageLayout
        fixed
        maxWidth="xl"
        breadcrumbs={[
          ...backBreadcrumb(),
          { label: "History", href: `/history/${historyURL}` },
          { label: "History Details" },
        ]}
      >
        {isLoading ? (
          <LinearProgress color="primary" />
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Details
            </Typography>
            <Typography gutterBottom variant="body1">
              {historyLog.description}
            </Typography>
            <Typography marginBottom="2em" variant="body2">
              Performed by {historyLog.username}{" "}
              {isoStringToRelativeTime(historyLog.recorded_at)}
            </Typography>
            <Divider />

            <Box marginTop="2em" marginBottom="2em">
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <LabelValuePair
                    icon={<AirlineStops />}
                    label="Action"
                    value={selectedAction != null ? selectedAction.label : ""}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <LabelValuePair
                    icon={<Description />}
                    label="Course"
                    value={historyLog.course_title}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <LabelValuePair
                    icon={<AccountCircle />}
                    label="Username"
                    value={historyLog.username}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <LabelValuePair
                    icon={<Email />}
                    label="Email"
                    value={historyLog.details.email}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <LabelValuePair
                    label="Date"
                    icon={<AccessTime />}
                    value={formatDate(historyLog.recorded_at)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <LabelValuePair
                    label="Log ID"
                    icon={<Storage />}
                    value={historyLog.history_id}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />

            <Box marginTop="2em">
              <AuditDataDetail
                actionType={historyLog.action_type}
                details={historyLog.details}
              />
            </Box>
          </>
        )}
      </PageLayout>
    </>
  )
}

export default HistoryDetailsScreen

import { last, split, isString } from "lodash/fp"

/**
 * Select the canonical server ID for a remark entity.
 *
 * @example
 * ```js
 * selectServerId("comment#bf396ce3-2d80-453c-ab6d-f8f15fc8e0e8")
 * // "bf396ce3-2d80-453c-ab6d-f8f15fc8e0e8"
 *
 * selectServerId("bf396ce3-2d80-453c-ab6d-f8f15fc8e0e8")
 * // "bf396ce3-2d80-453c-ab6d-f8f15fc8e0e8"
 * ```
 *
 * @param {string | import("../remark.types").Remark} entity
 * @returns {string}
 */
const selectServerId = (entity) =>
  isString(entity) ? last(split("#", entity)) : selectServerId(entity?.id)

export default selectServerId

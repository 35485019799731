/** Utility functions related to comments */

import {
  getPathFromNode,
  isBlockElement,
  getElementByPath,
  getPrevPathElement,
  getNextPathElement,
} from "./domUtils"

/** Given an element (must be a block element), create the location structure (see docs/comments.md in lex-api) */
export function getLocationFromElement(node, rootNode) {
  // tag is the block element tag
  const tag = node.tagName.toLowerCase()

  // Get path of node
  const path = getPathFromNode(node, rootNode)
  const text = node.textContent

  return { path, tag, text, offset: null }
}

/** Given a range, create the location structure (see docs/comments.md in lex-api) */
export function getLocationFromRange(range, rootNode) {
  // Move back in tree order to get first block parent element
  let offset = 0
  let node = range.startContainer

  // Add fragment to offset if text node
  if (node.nodeType === Node.TEXT_NODE) {
    offset += range.startOffset
  }

  // Work backwards to block node
  while (!isBlockElement(node)) {
    // Move leftwards, adding text contents to offset
    while (node.previousSibling) {
      node = node.previousSibling
      offset += node.textContent.length
    }

    node = node.parentNode
  }

  // Build text by moving forward until we reach the end of the range
  let text = ""
  let endNode = range.startContainer
  let startOffset = range.startOffset

  while (true) {
    // If reached the end node
    if (endNode === range.endContainer) {
      text += endNode.textContent.substr(
        startOffset,
        range.endOffset - startOffset
      )
      break
    }
    // Add all text content if text node
    if (endNode.nodeType === Node.TEXT_NODE) {
      text += endNode.textContent.substr(startOffset)
    }

    startOffset = 0

    // Move forward to next node
    if (endNode.firstChild) {
      endNode = endNode.firstChild
    } else if (endNode.nextSibling) {
      endNode = endNode.nextSibling
    } else {
      endNode = endNode.parentNode
      while (endNode && !endNode.nextSibling) {
        endNode = endNode.parentNode
      }
      if (!endNode) {
        break
      }

      endNode = endNode.nextSibling
    }
    if (!endNode) {
      break
    }

    // If has moved to another block element, break
    if (isBlockElement(endNode)) {
      break
    }
  }

  // tag is the block element tag
  const tag = node.tagName.toLowerCase()

  // Get path of node
  const path = getPathFromNode(node, rootNode)

  return { path, tag, offset, text }
}

/** Given a location, find the corresponding range in the editor element
 * It may require a fuzzy search, as the location may have moved in the
 * document since the comment was added.
 * Returns a range or null if not found.
 */
export function findRangeForLocation(location, rootElem) {
  // Find the closest block element using the path
  let { element, path } = getElementByPath(location.path, rootElem)

  // For block comments, find a matching element with 75% similarity
  if (location.offset == null) {
    const { matchElement } = findClosestMatchingElement(
      element,
      path,
      location.tag,
      location.text
    )

    if (matchElement == null) {
      return null
    }

    // Create range that surrounds the element
    const range = document.createRange()
    range.selectNodeContents(matchElement)
    return { range, matchElement }
  } else {
    // For range comments, first find matching element
    const { matchElement, matchText } = findClosestMatchingElement(
      element,
      path,
      location.tag,
      location.text
    )

    if (matchElement == null) {
      return null
    }

    // Get text of element
    const textContent = matchElement.textContent

    // Find the offsets of the text in the element
    const matches = [
      ...textContent.matchAll(new RegExp(escapeRegExp(matchText), "g")),
    ]

    let bestMatch = null
    let bestDistance = null
    for (const match of matches) {
      const distance = Math.abs(match.index - location.offset)
      if (bestMatch == null || distance < bestDistance) {
        bestMatch = match
        bestDistance = distance
      }
    }

    if (bestMatch == null) {
      return null
    }

    const start = convertOffsetToTextNodeOffset(matchElement, bestMatch.index)
    const end = convertOffsetToTextNodeOffset(
      matchElement,
      bestMatch.index + matchText.length
    )
    if (start.node == null || end.node == null) {
      return null
    }
    const range = document.createRange()
    range.setStart(start.node, start.offset)
    range.setEnd(end.node, end.offset)
    return { range, matchElement }
  }
}

/** Finds the element that closest matches the text. Returns:
 * { matchElement, matchText } where matchText is the actual text found
 * that may be a substring of text */
function findClosestMatchingElement(element, path, tag, text) {
  // Threshold of matching words fraction to be considered a match
  const MATCH_THRESHOLD = 0.75

  // If element is a good match, return it
  const { matchText, score } = matchElementToTagAndText(element, tag, text)
  if (score >= MATCH_THRESHOLD) {
    return { matchElement: element, matchText }
  }

  // Search upward and downward simultaneously looking for a good match
  // as the element may have moved up or down
  let upwardResult = { element, path }
  let downwardResult = { element, path }

  while (true) {
    if (upwardResult.element) {
      upwardResult = getPrevPathElement(upwardResult.element, upwardResult.path)
      if (upwardResult.element != null) {
        const { matchText, score } = matchElementToTagAndText(
          upwardResult.element,
          tag,
          text
        )
        if (score >= MATCH_THRESHOLD) {
          return { matchElement: upwardResult.element, matchText }
        }
      }
    }

    if (downwardResult.element) {
      downwardResult = getNextPathElement(
        downwardResult.element,
        downwardResult.path
      )
      if (downwardResult.element != null) {
        const { matchText, score } = matchElementToTagAndText(
          downwardResult.element,
          tag,
          text
        )
        if (score >= MATCH_THRESHOLD) {
          return { matchElement: downwardResult.element, matchText }
        }
      }
    }

    if (upwardResult.element == null && downwardResult.element == null) {
      return { matchElement: null, matchText: null }
    }
  }
}

/** Determine how close of a match (0 - 1) the element is to the
 * tag and text. 0 means no match, 1 means perfect match.
 * Returns { matchText, score }
 */
function matchElementToTagAndText(element, tag, text) {
  // If type doesn't match, no match
  if (element.tagName.toLowerCase() !== tag.toLowerCase()) {
    return { matchText: "", score: 0 }
  }

  // Try successively smaller text fragments until we find a match. Start must always match
  for (let frac = 1; frac > 0; frac -= 0.1) {
    const start = text.substr(0, Math.floor(text.length * frac))
    if (element.textContent.includes(start)) {
      return { matchText: start, score: frac }
    }
  }
  return { matchText: "", score: 0 }
}

/** Escape regex characters */
function escapeRegExp(s) {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
}

/** Using a starting element, convert an offset to an exact text node
 * and offset within the text node by traversing the DOM.
 */
function convertOffsetToTextNodeOffset(startElem, offset) {
  let node = startElem
  let nodeOffset = 0

  while (node != null) {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent
      if (nodeOffset + text.length >= offset) {
        return { node, offset: offset - nodeOffset }
      }
      nodeOffset += text.length
    }
    node = getNextNode(node)
  }
  return { node: null, offset: 0 }
}

/** Given a node, return the next node in the DOM tree. */
function getNextNode(node) {
  if (node.firstChild) {
    return node.firstChild
  }
  if (node.nextSibling) {
    return node.nextSibling
  }
  while (node != null && node.parentNode != null) {
    node = node.parentNode
    if (node.nextSibling) {
      return node.nextSibling
    }
  }
  return null
}

import React, { useEffect, useState } from "react"

import {
  LinearProgress,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  Button,
  Stack,
  Alert,
  AlertTitle,
  Box,
  Paper,
  FormGroup,
  Pagination,
  Skeleton,
} from "@mui/material"
import CheckCircle from "@mui/icons-material/CheckCircle"
import { SearchOutlined } from "@mui/icons-material"
import { styled } from "@mui/styles"
import { range } from "lodash"

import PageLayout from "../layouts/PageLayout"
import CheckboxSelect from "../../components/widgets/CheckboxSelect"
import useFind, { states } from "../../hooks/useFind"
import useRandomGraphic from "../widgets/RandomErrorGraphic"
import ConfirmDialog from "../widgets/ConfirmDialog"

/**
 * Find/Replace page
 * UI for find/replace across courses.
 * Includes:
 *  find controls
 *  search results
 *  progress while searching/replacing
 *  controls for replacing
 */

const ResultText = styled(Typography)(({ theme }) => ({
  color: theme.palette.addition.main,
}))

const ReplaceButton = styled(Button)(({ theme }) => ({
  color: theme.palette.addition.main,
  borderColor: theme.palette.addition.main,
}))

function FindScreen(props) {
  const find = useFind()

  const {
    searchState,
    searchText,
    replaceText,
    replaceNumCourses,
    unFoundText,
    completeMessage,
    warningMessage,
    problemCourses,
    searchResult,
  } = find

  return (
    <PageLayout
      fixed
      maxWidth="xl"
      breadcrumbs={[
        {
          label: "Courses",
          href: "/",
        },
        { label: "Tools", href: "/tools" },
        { label: "Find and Replace" },
      ]}
    >
      {/* Page Header */}

      <Typography variant="h4">Find and Replace</Typography>
      <Typography variant="body1" align="justify">
        Change text quickly across all your courses.
      </Typography>

      {/* Find Section */}

      <FindSection find={find} />

      {/* Progress Sections */}

      {searchState === states.SEARCHING && <LinearProgress color="primary" />}

      {searchState === states.REPLACING && (
        <ReplaceProgress
          searchText={searchText}
          replaceText={replaceText}
          numCourses={replaceNumCourses}
        />
      )}

      {/* Message Sections */}

      {searchState === states.NO_RESULTS && (
        <NoResultsSection unFoundText={unFoundText} />
      )}
      {searchState === states.COMPLETE && (
        <>
          {completeMessage !== "" && (
            <CompleteSection message={completeMessage} />
          )}

          {warningMessage !== "" && (
            <WarningSection
              message={warningMessage}
              problemCourses={problemCourses}
            />
          )}
        </>
      )}
      {searchState === states.ERROR && <ErrorSection />}

      {/* Results Section */}

      {searchState === states.HAS_RESULTS && searchResult != null && (
        <ReplaceSection find={find} />
      )}
    </PageLayout>
  )
}

function FindSection({ find }) {
  // Find section:
  //   Controls for Find: search text, search param controls, Find button

  const {
    searchState,
    searchText,
    setSearchText,
    matchCase,
    setMatchCase,
    wholeWord,
    setWholeWord,
    onFindAll,
    findEnabled,
  } = find

  const disableControls = searchState === states.REPLACING

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (findEnabled) {
            onFindAll()
          }
        }}
      >
        <TextField
          sx={{ mt: 2 }}
          variant="outlined"
          placeholder="Find What"
          fullWidth
          disabled={searchState === states.REPLACING}
          value={searchText}
          onInput={(e) => {
            setSearchText(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={!findEnabled} onClick={onFindAll}>
                  <SearchOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
      <Box sx={{ ml: 2, mt: 0.5 }}>
        <Typography variant="caption">
          The word or phrase you would like to change. Three characters or more.
        </Typography>
      </Box>
      <Grid container sx={{ ml: 2, mb: 4, pr: 2 }}>
        <Grid item xs={12} sm={5} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disableControls}
                onChange={(e) => setMatchCase(e.target.checked)}
                checked={matchCase}
              />
            }
            label="Match Case"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disableControls}
                checked={wholeWord}
                onChange={(e) => setWholeWord(e.target.checked)}
              />
            }
            label="Match Whole Word"
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={6} justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={!findEnabled}
            onClick={() => onFindAll()}
          >
            Find All
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

function ReplaceProgress({ searchText, replaceText, numCourses }) {
  // Show progress during replace

  return (
    <>
      <LinearProgress color="primary" />

      <Typography variant="body1" align="right" display="block" sx={{ mt: 2 }}>
        {`Replacing '${searchText}' with '${replaceText}' ${
          numCourses === 0
            ? ""
            : numCourses === 1
            ? " in one course."
            : " in " + numCourses + " courses."
        }`}
      </Typography>
    </>
  )
}

function CompleteSection({ message }) {
  // Show message when replace has finished
  return (
    <Alert severity="success">
      <AlertTitle>Replace Complete</AlertTitle>
      <Typography variant="body1">{message}</Typography>
    </Alert>
  )
}

const Graphic = styled("img")({
  maxWidth: "200px",
  userSelect: "none",
  userDrag: "none",
})

function WarningSection({ message, problemCourses }) {
  // Show message when replace has finished with an error
  return (
    <Stack direction="row" spacing={8} sx={{ mt: 7 }}>
      <Graphic src={useRandomGraphic()} alt="" draggable="false" />
      <Box display="flex" sx={{ alignItems: "center" }}>
        <Stack spacing={2}>
          <Typography variant="h6">
            Whoops, we couldn't replace everything.
          </Typography>
          <Typography variant="body1">{message}</Typography>
          <Typography variant="body1">
            It's possible that these documents have been edited or deleted.
          </Typography>
          {problemCourses.length > 0 && (
            <ul>
              {problemCourses.map((course, index) => (
                <li key={index}>
                  <Typography variant="body1">{course}</Typography>
                </li>
              ))}
            </ul>
          )}
          <Typography variant="body1">
            You can try to find these terms again.
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}

function NoResultsSection({ unFoundText }) {
  // Show message when find has no results
  return (
    <Alert severity="info">
      <AlertTitle>
        {`We couldn't find any matches for '${unFoundText}'.`}
      </AlertTitle>
      <Typography variant="body1">
        Double check your search for any typos or spelling errors or try a
        different search term.
      </Typography>
    </Alert>
  )
}

function ErrorSection() {
  // Show message when an error occurs
  return (
    <Stack direction="row" spacing={2} sx={{ mt: 7 }}>
      <Graphic src={useRandomGraphic()} alt="" draggable="false" />
      <Stack spacing={2}>
        <Typography variant="h6">We're Sorry.</Typography>
        <Typography variant="body1">
          Looks like something went wrong.
        </Typography>
        <Typography variant="body1">
          Please try again later, contact LearnExperts if the problem persists.
        </Typography>
      </Stack>
    </Stack>
  )
}

function ReplaceSection({ find }) {
  // Show results of find and controls for replace:
  //  Course selection
  //  Individual result selection per course

  const {
    searchResult,
    selectedCourses,
    setSelectedCourses,
    replaceText,
    setReplaceText,
    confirmingReplace,
    setConfirmingReplace,
    confirmRequired,
    onReplaceAll,
  } = find

  const [replaceAllHelpText, setReplaceAllHelpText] = useState("")

  const confirmReplaceAll = () => {
    const confirmReason = confirmRequired(selectedCourses)
    if (confirmReason != null) {
      setConfirmingReplace({
        course: "all",
        numCourses: selectedCourses.length,
        confirmReason,
      })
    } else {
      onReplaceAll()
    }
  }

  useEffect(() => {
    let courseText = ""
    if (selectedCourses.length === 0) {
      courseText = "Choose courses to apply changes"
    } else {
      const totalSelected = searchResult.courses
        .map((course) =>
          selectedCourses.includes(course.course_id) ? course.total : 0
        )
        .reduce((acc, val) => acc + val)

      let matchText = ""
      if (totalSelected === 1) {
        matchText = "one match"
      } else if (totalSelected === 2) {
        matchText = "2 matches"
      } else {
        matchText = `all ${totalSelected.toLocaleString()} matches`
      }

      if (selectedCourses.length === 1) {
        courseText = `Replace ${matchText} in one course`
      } else {
        courseText = `Replace ${matchText} in ${selectedCourses.length} courses`
      }
    }

    setReplaceAllHelpText(courseText)
  }, [selectedCourses, searchResult])

  return (
    <>
      <Stack>
        <TextField
          variant="outlined"
          placeholder="Replace With"
          fullWidth
          value={replaceText}
          onInput={(e) => {
            setReplaceText(e.target.value)
          }}
        />
        <Box sx={{ ml: 2, mt: 0.5 }}>
          <Typography variant="caption">
            The new text you would like the text changed to.
          </Typography>
        </Box>
        <Grid container sx={{ mt: 4 }}>
          <Grid item sm={12} md={5} lg={4}>
            <Paper style={{ backgroundColor: "#D5D5D5" }} sx={{ p: 2 }}>
              <Typography fontWeight="bold" variant="body1">
                Choose the courses where these changes can be applied.
              </Typography>
              <CheckboxSelect
                options={searchResult.courses}
                getKey={({ course_id }) => course_id}
                getLabel={({ course_title }) => course_title}
                getTooltip={({ course_title }) => course_title}
                loading={false}
                selection={selectedCourses}
                onSelectionChange={setSelectedCourses}
              />
            </Paper>
          </Grid>
          <Grid item sx={{ p: 2 }} sm={12} md={7} lg={8}>
            <Stack>
              <Typography variant="h6">Replace All</Typography>
              <Typography variant="body1" align="justify">
                Choose to update all the selected courses right now or make
                individual updates.
              </Typography>
              <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 4 }}>
                <ResultText flexGrow={1} variant="body1" align="right">
                  {replaceAllHelpText}
                </ResultText>
                <ReplaceButton
                  startIcon={<CheckCircle />}
                  sx={{ ml: 1 }}
                  disabled={selectedCourses.length === 0}
                  variant="outlined"
                  onClick={confirmReplaceAll}
                >
                  Replace all
                </ReplaceButton>
              </Box>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Individual Replace
              </Typography>
              {searchResult.courses.map((course) => (
                <CourseSection
                  key={course.course_id}
                  find={find}
                  course={course}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {confirmingReplace && <ConfirmReplaceDialog find={find} />}
    </>
  )
}

function CourseSection({ find, course }) {
  // Show find results for a course.
  // Show controls to select individual results for replacement

  const {
    searchText,
    matchCase,
    onFindForCourse,
    expectedResultsCount,
    resultPageSize,
    setConfirmingReplace,
    confirmRequired,
    onReplaceOne,
  } = find

  const [selectedResults, setSelectedResults] = useState(range(course.total))
  const selectAll = () => {
    setSelectedResults(range(course.total))
  }
  const deselectAll = () => {
    setSelectedResults([])
  }

  const [loading, setLoading] = useState(false)
  const [loadingSlowly, setLoadingSlowly] = useState(false)
  const [page, setPage] = useState(1)
  const [replaceHelpText, setReplaceHelpText] = useState("")

  const handlePageChange = (event, value) => {
    if (!loading) {
      let loaded = false
      setPage(value)
      setLoading(true)
      setTimeout(() => {
        if (!loaded) {
          setLoadingSlowly(true)
        }
      }, 500)
      onFindForCourse(course.course_id, value).finally(() => {
        loaded = true
        setLoading(false)
        setLoadingSlowly(false)
      })
    }
  }

  const onReplace = () => {
    const confirmReason = confirmRequired([course.course_id])
    if (confirmReason != null) {
      setConfirmingReplace({
        course,
        numCourses: 1,
        selectedResults,
        confirmReason,
      })
    } else {
      onReplaceOne(course, selectedResults)
    }
  }

  useEffect(() => {
    let matchText = ""
    if (selectedResults.length === 0) {
      matchText = "Select search results to apply changes"
    } else if (selectedResults.length === 1) {
      matchText = `Replace one match`
    } else {
      matchText = `Replace ${selectedResults.length} matches`
    }

    setReplaceHelpText(matchText)
  }, [selectedResults, course])

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography flexGrow={1} align="left">
        {course.course_title}
      </Typography>
      <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 4 }}>
        <ResultText flexGrow={1} variant="body1" align="right">
          {replaceHelpText}
        </ResultText>
        <ReplaceButton
          disabled={loading || selectedResults.length === 0}
          startIcon={<CheckCircle />}
          sx={{ ml: 1 }}
          variant="outlined"
          onClick={onReplace}
        >
          Replace
        </ReplaceButton>
      </Box>

      {course.total > 2 && (
        <Box sx={{ mb: loading ? 1.85 : 2 }}>
          <Box
            display="flex"
            sx={{ pt: 1, pb: 1, pr: 1 }}
            justifyContent="space-between"
            alignItems="center"
            style={{
              borderBottom: loading ? "0px" : "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <Stack direction="row" justifyContent="right" spacing={1}>
              <Button
                size="small"
                disabled={loading || selectedResults.length === course.total}
                onClick={selectAll}
              >
                Select All
              </Button>
              <Button
                size="small"
                disabled={loading || selectedResults.length === 0}
                onClick={deselectAll}
              >
                Deselect All
              </Button>
            </Stack>
            {course.total > resultPageSize && (
              <Pagination
                disabled={loading}
                size="small"
                siblingCount={0}
                count={Math.ceil(course.total / resultPageSize)}
                page={page}
                onChange={handlePageChange}
              />
            )}
          </Box>
          {loading && <LinearProgress sx={{ height: 2 }} color="primary" />}
        </Box>
      )}

      {loading && loadingSlowly ? (
        <SkeltonResultCheckboxList
          count={expectedResultsCount(course, page)}
          checked={selectedResults.length !== 0}
        />
      ) : (
        <ResultCheckboxList
          options={course.results}
          {...{
            selectedResults,
            setSelectedResults,
            searchText,
            matchCase,
          }}
        />
      )}
    </Paper>
  )
}

function SkeltonResultCheckboxList({ count, checked }) {
  return (
    <FormGroup>
      {range(count).map((index) => (
        <FormControlLabel
          sx={{
            mb: 1,
            "& .MuiFormControlLabel-label": {
              width: "100%",
            },
          }}
          key={index}
          control={<Checkbox size="small" disabled checked={checked} />}
          label={<Skeleton height={50} variant="text" />}
        />
      ))}
    </FormGroup>
  )
}

function ResultCheckboxList({ options, selectedResults, setSelectedResults }) {
  // Show individual find results for a course.
  // with check boxes to select for replacement
  const isSelected = (id) => selectedResults.includes(id)
  const select = (id) => setSelectedResults([id, ...selectedResults])
  const deselect = (id) =>
    setSelectedResults(selectedResults.filter((option_id) => option_id !== id))

  return (
    <FormGroup>
      {options.map(({ context, index }) => (
        <FormControlLabel
          key={index}
          sx={{ mb: 2 }}
          control={
            <Checkbox
              size="small"
              checked={isSelected(index)}
              onChange={(e) => {
                e.target.checked ? select(index) : deselect(index)
              }}
            />
          }
          label={
            <ResultText
              data-cy="find-result"
              dangerouslySetInnerHTML={{
                __html: context,
              }}
            ></ResultText>
          }
        />
      ))}
    </FormGroup>
  )
}

export const ConfirmReplaceDialog = ({ find }) => {
  const {
    searchText,
    replaceText,
    confirmingReplace,
    setConfirmingReplace,
    onReplaceConfirmed,
  } = find

  return (
    <ConfirmDialog
      title={"Confirm Replace"}
      message={
        <>
          {confirmingReplace.confirmReason === "mixedCase" && (
            <Typography variant="body1" sx={{ mb: 2 }} align="justify">
              {`We found upper and lower case words for '${searchText}'. We will change them all to '${replaceText}' with this exact case. If this isn't what you want, you can can use the 'Match Case' option to change exactly what you want.`}
            </Typography>
          )}
          {confirmingReplace.confirmReason === "blankReplaceText" && (
            <Typography variant="body1" sx={{ mb: 2 }} align="justify">
              {`You are about to replace '${searchText}' with a blank string.`}
            </Typography>
          )}
          <Typography
            variant="body1"
            align="justify"
          >{`Are you sure you want to replace '${searchText}' with '${replaceText}' in ${
            confirmingReplace.numCourses
          } course${confirmingReplace.numCourses > 1 ? "s" : ""}.`}</Typography>
        </>
      }
      cancelText={"No, Cancel Replace"}
      confirmText={"Yes"}
      onCancel={(e) => {
        e.preventDefault()
        setConfirmingReplace(null)
      }}
      onConfirm={(e) => {
        e.preventDefault()
        onReplaceConfirmed()
        setConfirmingReplace(null)
      }}
    />
  )
}

export default FindScreen

import { useCallback, useEffect, useState, useRef } from "react"
import { isEqual } from "lodash"
import * as api from "../api"
import { useHistory } from "react-router-dom"
import { convertObjectToQuery } from "../utilities/transformers"

const SEARCH_DEBOUNCE_TIME = 1000

const dataFilterWho = [{ label: "Just Me", id: "me" }]

const dataFilterAction = [
  { label: "Create Course", id: "course_created" },
  { label: "Create Chunked Microlearning", id: "course_microlearning_chunk" },
  { label: "Create Concept Microlearning", id: "course_microlearning_concept" },
  { label: "Course Intelligent Update", id: "course_intelligent_update" },
  { label: "Course Saved", id: "course_saved" },
  { label: "Delete Course", id: "course_deleted" },
  { label: "Restore Course", id: "course_restored" },
  { label: "Trash Course", id: "course_trashed" },
  { label: "Duplicate Course", id: "course_duplicated" },
  { label: "Export Course", id: "course_exported" },
  { label: "Give Course", id: "course_transferred" },
  { label: "Share Course", id: "course_shared" },
  { label: "Publish Course", id: "course_published" },
  { label: "Rename Course", id: "course_rename" },
  { label: "Publish Course to LMS", id: "publish_course_to_lms" },
  { label: "Create Learning Path", id: "path_created" },
  { label: "Delete Learning Path", id: "path_deleted" },
  { label: "Rename Learning Path", id: "path_renamed" },
  { label: "Reorder Learning Path", id: "path_reorder_courses" },
  { label: "Add to Learning Path", id: "path_add_courses" },
  { label: "Remove from Learning Path", id: "path_remove_courses" },
]

const defaultFilters = {
  page: 0,
  limit: 20,
  filterCourse: null,
  filterActor: null,
  filterAction: null,
  filterSince: null,
  filterBefore: null,
  search: null,
}

export const useAuditHistoryDetails = (query) => {
  const [result, setResult] = useState({
    historyLog: { details: {} },
    error: null,
  })

  const fetchHistoryDetails = useCallback(
    (id) =>
      api.getHistoryDetails(id).then((res) => {
        return res.data
      }),
    []
  )

  const [isLoading, setIsLoading] = useState(false)

  const setData = (historyLog) => {
    setIsLoading(false)
    setResult((result) => ({ ...result, historyLog }))
  }
  const setError = (error) => setResult((result) => ({ ...result, error }))

  useEffect(() => {
    setIsLoading(true)
    if (query.id == null) {
      setError("No audit ID provided")
      console.log("the audit trail id is missing from the query string")
      setIsLoading(false)
    }
    fetchHistoryDetails(query.id).then(setData).catch(setError)
  }, [fetchHistoryDetails, query.id])

  return {
    ...result,
    dataFilterAction,
    isLoading,
  }
}

const useAuditHistory = () => {
  const history = useHistory()

  const [result, setResult] = useState({
    records: [],
    error: null,
  })

  const [filters, setFilters] = useState(defaultFilters)
  const [options, setOptions] = useState({
    filterCourse: [],
    filterActor: dataFilterWho,
    filterAction: dataFilterAction,
  })
  const [searchInput, setSearchInput] = useState("")
  const [isFiltered, setIsFiltered] = useState(false)
  const [courseInput, setCourseInput] = useState("")
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [isLoadingHistory, setIsLoadingHistory] = useState(false)
  const [backParams, setBackParams] = useState({})

  // API call to backend to retrieve History records, based on the currently applied filters in the state
  const fetchHistory = useCallback(
    (filters) =>
      api.getHistory(filters).then((res) => {
        return res.data
      }),
    []
  )
  const setData = (records) => setResult((result) => ({ ...result, records }))
  const setError = (error) => setResult((result) => ({ ...result, error }))

  const updateIsFiltered = () => {
    const currentFilters = {}
    for (const key in defaultFilters) {
      currentFilters[key] = filters[key]
    }

    setIsFiltered(isEqual(currentFilters, defaultFilters))
  }

  const handleFilter = (newFilters, checkChanged = true) => {
    let updatedFilter
    if (newFilters != null) {
      updatedFilter = {
        ...filters,
        ...newFilters,
      }
    } else {
      updatedFilter = { ...defaultFilters, limit: filters.limit }
    }

    if (!checkChanged || !isEqual(updatedFilter, filters)) {
      // update the current URL with the most recently applied filters as query parameters

      const allParams = { ...updatedFilter, ...backParams }
      history.replace(`?${convertObjectToQuery(allParams, false)}`)
      setFilters(updatedFilter)
    }
  }

  const queryTimeoutRef = useRef(null)

  useEffect(() => {
    queryTimeoutRef.current = null
    return () => clearQueryTimeout()
  }, [])

  const clearQueryTimeout = () => {
    if (queryTimeoutRef.current != null) {
      clearTimeout(queryTimeoutRef.current)
      queryTimeoutRef.current = null
    }
  }

  const onSearchInputChange = (query) => {
    clearQueryTimeout()
    queryTimeoutRef.current = setTimeout(() => {
      queryTimeoutRef.current = null
      handleFilter({
        page: 0,
        search: query,
      })
    }, SEARCH_DEBOUNCE_TIME)
  }

  const onFilterChange = (filter, checkChanged = true) => {
    clearQueryTimeout()
    handleFilter(filter, checkChanged)
  }

  //initialize component and get list of available courses
  useEffect(() => {
    const initOptions = async () => {
      // get the list of courses for the options
      // do this before we parse the url filters so the
      // course list option is initialized

      let filterCourses = []
      try {
        let { data } = await api.getCourses()
        if (data.items) {
          filterCourses = data.items.map((courseInfo) => {
            return { id: courseInfo["id"], title: courseInfo["title"] }
          })
        }
      } catch (e) {
        // use an empty list of courses - network type errors will be
        // reported when trying to get the history records
        console.warn("Problem getting courses", e)
      }

      setOptions({
        ...options,
        filterCourse: filterCourses,
      })

      setIsLoadingOptions(false)
      setIsLoadingHistory(true)

      // create filters and search text based on the url
      const urlFilters = makeFiltersFromUrl(filterCourses)

      if (urlFilters["search"] != null) {
        setSearchInput(urlFilters["search"])
      }

      if (urlFilters["filterCourse"] != null) {
        setCourseInput(urlFilters["filterCourse"].title)
      }

      setFilters((filters) => ({
        ...filters,
        ...urlFilters,
      }))
    }

    setBackParams(getBackParamsFromUrl())

    setIsLoadingOptions(true)
    initOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBackParamsFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const bacKParams = {
      back: urlParams.get("back"),
    }

    if (urlParams.get("courseId") != null) {
      bacKParams.courseId = urlParams.get("courseId")
      bacKParams.title = urlParams.get("title") ?? ""
    }

    return bacKParams
  }

  // Function to render only records within interval defined by page and limit properties
  const filterRecords = () => {
    if (filters.page != null && filters.limit != null) {
      // The index of the first record to render is calculated by the number per page * what page we are on
      const startIndex = filters.page * filters.limit

      // The index of the last record is simply the number per page added to the first index
      const endIndex = startIndex + filters.limit

      // Return an array of only this interval
      return result.records.slice(startIndex, endIndex)
    } else {
      // If the page and limit filters are unavailable, don't render anything
      return []
    }
  }

  /**
   * Extract all of the query parameters of the current URL and store them in the state
   * as filter properties
   */
  const makeFiltersFromUrl = (courseList) => {
    const urlParams = new URLSearchParams(window.location.search)

    let urlFilters = { ...defaultFilters }

    // loop through all the query parameters and add them to the filter in the state
    for (const [field, value] of urlParams.entries()) {
      let payload = null
      switch (field) {
        case "page":
        case "limit":
          payload = parseInt(value)
          break
        case "filterBefore":
        case "filterSince":
        case "search":
          payload = value
          break
        case "filterCourse":
          payload = courseList.find((course) => course.id === value)
          break
        case "filterActor":
        case "filterAction":
          payload = options[field].find((option) => option.id === value)
          break
        default:
          break
      }

      urlFilters[field] = payload
    }

    return urlFilters
  }

  const requestFilters = useRef(null)

  /**
   * Fetch all of the history results based on current filters in the state
   * every time one of the filter properties changes
   */
  useEffect(() => {
    // don't do a query at the start, filters wil be replaced
    // shortly using params from the the URL
    if (filters !== defaultFilters) {
      updateIsFiltered()

      setIsLoadingHistory(true)
      requestFilters.current = filters

      fetchHistory(filters)
        .then((data) => {
          if (requestFilters.current === filters) {
            setData(data)
            setIsLoadingHistory(false)
          }
        })
        .catch((err) => {
          setIsLoadingHistory(false)
          setError(err)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return {
    ...result,
    filters,
    isLoadingHistory,
    isLoadingOptions,
    backParams,
    options,
    searchInput,
    setSearchInput,
    courseInput,
    setCourseInput,
    onSearchInputChange,
    onFilterChange,
    isFiltered,
    filterRecords,
  }
}

export default useAuditHistory
